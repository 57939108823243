import { makeStyles } from '@mui/styles';

const HelpCentreStyles = makeStyles(() => ({
  container: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignContent: 'center',
  },
  content: {
    display: 'flex',
    flexDirection: 'column',

    backgroundColor: 'white',
    width: '90%',
    maxHeight: '90%',
    borderRadius: 5,
    padding: 30,
  },
  title: {
    fontSize: '1.8rem',
    fontWeight: 'normal',
  },
  noEntriesFound: {
    color: '#0056C8',
  },
  contactUs: {
    alignSelf: 'flex-end',
    fontSize: '1.3rem',
    paddingTop: 15,
    cursor: 'pointer',
  },
}));

export default HelpCentreStyles;
