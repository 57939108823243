/*
This component contains source code that will display a list of L0/Executive summary page tiles and tabs in the RSM Home page.
*/
import React, { useContext, useEffect, useState } from 'react';

import { useAppInsightsContext } from '@microsoft/applicationinsights-react-js';
import { useParams, useSearchParams, useLocation } from 'react-router-dom';

import AuthContext from '../context/AuthContext';
import NationalViewDashboardSection from '../organisms/NationalViewDashboardSection';

const NationalViewDetails = () => {
  const { id, kpiId } = useParams();
  const [searchParams] = useSearchParams();
  const appInsights = useAppInsightsContext();
  const { user } = useContext(AuthContext);
  const { state } = useLocation();
  const [favorite, setFavorite] = useState('');
  useEffect(() => {
    if (state) {
      setFavorite(state.favorite);
    }
  }, []);
  // Callback function for tracking the page
  useEffect(() => {
    appInsights.trackPageView({
      uri: window.location.href,
      refUri: window.location.href,
      properties: {
        personaName: user.persona.name,
      },
    });
  }, []);

  return (
    <NationalViewDashboardSection
      tileId={id}
      innerKpiId={kpiId}
      byView={searchParams.get('view')}
      FavoritePageButton={favorite}
    />
  );
};

export default NationalViewDetails;
