/*
This component contains source code that defines an table with all the details in the View More section of the RSM persona Home page.
It takes props like KPI id, filters data to return KPI details locationwise , ranking and sorting table that can be displayed on the screen.
*/
import React from 'react';

import { KeyboardArrowRight } from '@mui/icons-material';
import { TableCell, TableRow } from '@mui/material';
import PropTypes from 'prop-types';

import LineChart from '../atom/LineChart';
import NationalViewTableStyles from '../styles/NationalViewTableStyles';
const NationalViewTableRow = (props) => {
  const { row, onRowClick, size, rankingFilter } = props;
  const classes = NationalViewTableStyles();
  return (
    <TableRow className={classes.tableRow} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
      <TableCell size={size} align='left' className={classes.tableCell}>
        {row.ranking}
      </TableCell>
      {rankingFilter === 'site' && (
        <TableCell size={size} align='left' className={classes.tableCell}>
          {row.site}
        </TableCell>
      )}
      <TableCell size={size} align='left' className={classes.tableCell}>
        {row.region}
      </TableCell>
      <TableCell size={size} align='left' className={classes.tableCell}>
        <span style={{ color: row.value_color !== 'null' ? row.last_week_color : '' }}>
          {row.last_week >= 0.0 ? `${row.last_week.toFixed(2)}${row.last_week_unit}` : '(Blank)'}
        </span>
      </TableCell>
      <TableCell size={size} align='left' className={classes.tableCell}>
        <span style={{ color: row.value_color !== 'null' ? row.last_4_week_color : '' }}>
          {row.last_4_week >= 0.0 ? `${row.last_4_week.toFixed(2)}${row.last_4_week_unit}` : '(Blank)'}
        </span>
      </TableCell>
      <TableCell size={size} align='left' className={classes.tableCell}>
        <span style={{ color: row.value_color !== 'null' ? row.year_to_date_color : '' }}>
          {row.year_to_date >= 0.0 ? `${row.year_to_date.toFixed(2)}${row.year_to_date_unit}` : '(Blank)'}
        </span>
      </TableCell>
      <TableCell
        size={size}
        align='center'
        style={{ display: 'flex', justifyContent: 'center' }}
        className={classes.tableCell}
      >
        <div className={classes.agChartWrapper}>
          <LineChart lineChartData={row.trendline} />
        </div>
      </TableCell>
      <TableCell size={size} align='left' className={classes.tableCell}>
        {row.location_type !== 'BIN' ? (
          <KeyboardArrowRight
            sx={{ color: '#63666A', fontSize: 38 }}
            onClick={onRowClick}
            className={classes.iconClick}
          />
        ) : (
          ''
        )}
      </TableCell>
    </TableRow>
  );
};

NationalViewTableRow.propTypes = {
  row: PropTypes.object,
};

NationalViewTableRow.defaultProps = {
  row: {},
};

export default NationalViewTableRow;
