import React, { useEffect, useState, useContext } from 'react';
import { useParams, useLocation } from 'react-router-dom';
import { useAppInsightsContext } from '@microsoft/applicationinsights-react-js';

import AuthContext from '../context/AuthContext';
import HistoricalViewDashboard from '../organisms/HistoricalViewDashboard';
import HistoricalViewContext from '../context/HistoricalViewContext';

const HistoricalView = (props) => {
  const { accessToken, user } = useContext(AuthContext);
  const { historicalViewData } = useContext(HistoricalViewContext);
  const { siteName, regionName } = useParams();
  const { state } = useLocation();
  const [kpiName, setKpiName] = useState('');
  const appInsights = useAppInsightsContext();
  const [tableReports, setTableReports] = useState([]);

  useEffect(() => {
    if (state) {
      setKpiName(state.kpiName);
    }
  }, []);
  // Tabular KPI's records
  useEffect(() => {
    setTableReports(historicalViewData);
  }, [historicalViewData]);

  useEffect(() => {
    appInsights.trackPageView({
      uri: window.location.href,
      refUri: window.location.href,
      properties: {
        personaName: user.persona.name,
      },
    });
  }, []);

  return (
    <HistoricalViewDashboard
      tableReports={tableReports}
      accessToken={accessToken}
      personaId={user.personaId}
      regionName={regionName}
      siteName={siteName}
      innerPagekpiName={kpiName}
      {...props}
    />
  );
};
export default HistoricalView;
