import React, { useState, useEffect, useContext } from 'react';

import { Grid, Link, Table, TableBody, TableContainer } from '@mui/material';
import { isEmpty } from 'lodash';
import { useNavigate } from 'react-router-dom';
import Papa from 'papaparse';
import { utils, writeFile } from 'xlsx';

import leftOutlined from '../assets/leftOutlined.svg';
import EnhancedTableHead from '../atom/EnhancedTableHead';
import Loader from '../atom/Loader';
import AuthContext from '../context/AuthContext';
import FilterContext from '../context/FilterContext';
import HistoricalViewContext from '../context/HistoricalViewContext';
import Filters from '../molecules/Filters';
import LayoutStyles from '../styles/LayoutStyles';
import NationalViewDetailsStyles from '../styles/NationalViewDetailsStyles';
import RSMHistoricalTableRow from '../molecules/RSMHistoricalTableRow';
import { getExportDetailList } from '../../actions/home';

const RSMHistoricalSection = (props) => {
  const { tileId, byView, FavoritePageButton, innerKpiId } = props;
  const { user, unSetReportDetail } = useContext(AuthContext);
  const filterContext = useContext(FilterContext);
  const { getHistoricalDetails, historicalViewDetails } = useContext(HistoricalViewContext);
  const { locationTypeData, regionData } = filterContext;
  const classes = NationalViewDetailsStyles();
  const [rowData, setRowData] = useState([]);
  const [kpiName, setKpiName] = useState('');
  const [kpiId, setKpiId] = useState('');
  const [headCells, setHeadCells] = useState([]);
  const [newHeading, setNewHeadCells] = useState([]);
  const layoutClasses = LayoutStyles();

  const [region, setRegion] = useState([]);
  const [multipleLocationType, setMultipleLocationType] = useState([]);
  const [locationTypeAllSelected, setLocationTypeAllSelected] = useState(true);
  const [rankingFilter, setRankingFilter] = useState('site');
  const [loading, setLoading] = useState(false);
  const [exportStatus, setExportStatus] = useState(false);
  const navigate = useNavigate();

  const [order, setOrder] = useState('asc');
  const [orderBy, setOrderBy] = useState(null);
  const [defaultOrderBy, setDefaultOrderBy] = useState('');

  // Callback functions

  // Callback Function for reset the report list
  useEffect(() => {
    return () => {
      unSetReportDetail();
    };
  }, []);

  // Callback function for set PBI filters based on multiple location or location data
  useEffect(() => {
    if (user.id && region && !isEmpty(multipleLocationType)) {
      setLoading(true);
      if (rankingFilter === 'site') {
        let headers = [
          {
            id: 'ranking',
            numeric: true,
            disablePadding: false,
            label: 'Ranking',
            sortable: true,
            align: 'left',
          },
          {
            id: 'site',
            numeric: false,
            disablePadding: false,
            label: 'Site Name',
            sortable: true,
            align: 'left',
          },
          {
            id: 'region',
            numeric: false,
            disablePadding: false,
            label: 'Region',
            sortable: true,
            align: 'left',
          },
          {
            id: 'last_week',
            numeric: true,
            disablePadding: false,
            label: 'LW',
            sortable: true,
            align: 'left',
          },
          {
            id: 'last_4_week',
            numeric: true,
            disablePadding: false,
            label: 'L4W',
            sortable: true,
            align: 'left',
          },
          {
            id: 'year_to_date',
            numeric: true,
            disablePadding: false,
            label: 'YTD',
            sortable: true,
            align: 'left',
          },
          {
            id: 'trendline',
            disablePadding: false,
            label: 'Trendline (Last 13 Weeks)',
            align: 'center',
          },
        ];
        setHeadCells(headers);
      }
      setRowData([]);
      getHistoricalDetails(
        user,
        tileId,
        region,
        locationTypeAllSelected ? (user.persona.name === '3PL DC Regional Manager' ? 'DC' : 'ALL') : '',
        multipleLocationType,
        rankingFilter,
      );
    }
  }, [multipleLocationType, rankingFilter]);

  // Callback function for set the data for the tables based on location

  useEffect(() => {
    let orderByLabel = 'LW';
    let orderById = 'last_week';
    if (!isEmpty(historicalViewDetails)) {
      let data = [];
      setKpiName(historicalViewDetails.kpiDetails?.name);
      setKpiId(historicalViewDetails.kpiDetails.kpi_id);
      historicalViewDetails.historicalDetails?.historical_view_details.map((item, index) => {
        data.push({
          ranking: index + 1,
          location_type: item.location_type ? item.location_type.trim() : item.location_type,
          site: item.location_name,
          region: item.region,
          last_week: +item.last_week,
          last_week_unit: item.last_week_unit,
          last_week_color: item.last_week_color,
          last_4_week: +item.last_4_week,
          last_4_week_unit: item.last_4_week_unit,
          last_4_week_color: item.last_4_week_color,
          year_to_date: +item.year_to_date,
          year_to_date_unit: item.year_to_date_unit,
          year_to_date_color: item.year_to_date_color,
          trendline: item.chartData,
          id: item.id,
        });
      });
      setLoading(false);
      // sort the Data and add ranking based on the index
      let sortedData = handleSort(data, orderById);
      data = sortedData.map((item, index) => {
        return {
          ranking: index + 1,
          ...item,
        };
      });
      setRowData(data);
    } else {
      setRowData([]);
    }
    setDefaultOrderBy(orderByLabel);
  }, [historicalViewDetails]);

  // Functions

  // Function for sorting by asc of table data
  const descendingComparator = (a, b, orderBy) => {
    const A_Unit = a[`${orderBy}_Unit`] || a[`${orderBy}Unit`];
    const B_Unit = b[`${orderBy}_Unit`] || b[`${orderBy}Unit`];
    const A =
      typeof a[orderBy] === 'string' && a[orderBy].endsWith('K')
        ? parseFloat(a[orderBy].replace('K', '')) * 1000
        : A_Unit === 'K'
        ? a[orderBy] * 1000
        : a[orderBy];
    const B =
      typeof b[orderBy] === 'string' && b[orderBy].endsWith('K')
        ? parseFloat(b[orderBy].replace('K', '')) * 1000
        : B_Unit === 'K'
        ? b[orderBy] * 1000
        : b[orderBy];
    if (B < A) {
      return -1;
    }
    if (B > A) {
      return 1;
    }
    return 0;
  };

  // Function for sorting by desc of table data
  const getComparator = (order, orderBy) => {
    return order === 'desc'
      ? (a, b) => descendingComparator(a, b, orderBy)
      : (a, b) => -descendingComparator(a, b, orderBy);
  };

  // Function for sorting
  const stableSort = (array, comparator) => {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
      const order = comparator(a[0], b[0]);

      if (order !== 0) {
        return order;
      }
      return a[1] - b[1];
    });

    return stabilizedThis.map((el) => el[0]);
  };
  // Request for sorting
  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    let orderType = isAsc ? 'desc' : 'asc';
    setOrder(orderType);
    setOrderBy(property);
    let result = stableSort(rowData, getComparator(orderType, property));
    setRowData(result);
  };

  // Sorting before setting the data in the table and also export the data
  const handleSort = (data, orderBy) => {
    return stableSort(data, getComparator('desc', orderBy));
  };

  // Function for navigate to other page
  const navigateTo = (page) => (e) => {
    e.preventDefault();
    navigate(page);
    return false;
  };

  // Function for set region to local state
  const getRegion = (region) => {
    setRegion(region);
  };

  // Function for set multiple location type to local state
  const getMultipleLocationType = (locationType, locationTypeAll) => {
    setMultipleLocationType(locationType);
    setLocationTypeAllSelected(locationTypeAll);
  };

  // Filter the export table with the selected location
  const filterExportData = (data, filterContext) => {
    let result = data.filter(
      (item) =>
        filterContext.multipleLocationType.includes(item['Location Type']) &&
        filterContext.region.includes(item['Region']) &&
        filterContext.country.includes(item['Country']),
    );
    return result;
  };

  // Function to export the view ranking table
  const onHandleExport = async () => {
    const data = await getExportDetailList(kpiId, 'Weekly');
    Papa.parse(data.blobData, {
      header: true,
      skipEmptyLines: true,
      complete: function (results) {
        let locationBasedData = results.data;
        // Convert the values to their appropriate types
        let RankingData = locationBasedData.map((row) => {
          let convertedRow = {};
          Object.keys(row).forEach((key) => {
            let value = row[key];
            if (!isNaN(value) && value.trim() !== '') {
              convertedRow[key] = Number(value);
            } else {
              convertedRow[key] = value;
            }
          });
          delete convertedRow.CREATE_DATE;
          return convertedRow;
        });
        let FilterContext = {
          multipleLocationType: multipleLocationType,
          region: region,
          country: [user.country.trim()],
        };
        let tempExport = filterExportData(RankingData, FilterContext);

        let sortColumn = '';
        if (tempExport.length > 0) {
          sortColumn = Object.keys(tempExport[0]).find(
            (key) => key.replace(/\s+/g, '').toLowerCase() === defaultOrderBy.replace(/\s+/g, '').toLowerCase(),
          );
        }
        tempExport = handleSort(tempExport, sortColumn);
        // iterate over tempExport and add ranking based on the index
        const exportResult = tempExport.map((item, index) => {
          return {
            Ranking: index + 1,
            ...item,
          };
        });
        const wb = utils.book_new();
        let ws_data = [
          ['Persona Name: ', user.persona.name],
          ['KPI Name', kpiName],
          ['Country: ', user.country],
          ['Region', ...region],
          ['LocationType', ...multipleLocationType],
        ];
        ws_data.push([]);
        let arraylocal = [];
        for (let key in exportResult[0]) {
          if (exportResult[0].hasOwnProperty(key)) {
            arraylocal.push([key]);
          }
        }
        ws_data.push(arraylocal);
        exportResult.map((item) => {
          let arraylocal = [];
          for (let key in item) {
            if (item.hasOwnProperty(key)) {
              arraylocal.push([item[key]]);
            }
          }
          ws_data.push(arraylocal);
        });
        const ws = utils.aoa_to_sheet(ws_data);
        utils.book_append_sheet(wb, ws);
        let fileName = `Historical_Ranking_view_data`;
        writeFile(wb, `${fileName}.xlsx`);
      },
    });
  };

  return (
    <Grid container>
      <Grid item container className={layoutClasses.leftContainer}>
        <Filters
          user={user}
          regionMultipleDropDown={true}
          locationTypeMultipleDropDown={true}
          hqSectionLocationTypeMultipleDropDown={true}
          countryLabel={true}
          getRegion={getRegion}
          getMultipleLocationType={getMultipleLocationType}
          rsmDailyViewLocationTypeData={locationTypeData}
          nationalViewRegionData={regionData}
          innerKpiId={innerKpiId}
        />
      </Grid>
      <Grid item container className={layoutClasses.rsmRightContainer}>
        <Grid
          xs={12}
          item
          spacing={0.5}
          container
          flexDirection='column'
          alignContent='space-around'
          className={classes.layoutMainContainer}
        >
          <Grid xs={12} item container className={classes.layoutContainer}>
            <Grid item xs={6} md={6} xl={6} className={classes.backButton}>
              {FavoritePageButton ? (
                <Link
                  href='/favorites/historicalView'
                  key='home'
                  underline='none'
                  sx={{ mr: 2 }}
                  onClick={navigateTo('/favorites/historicalView')}
                >
                  <img src={leftOutlined} alt='close-icon' />
                  {kpiName}
                </Link>
              ) : (
                <Link
                  href='/historicalView'
                  key='home'
                  underline='none'
                  sx={{ mr: 2 }}
                  onClick={navigateTo('/historicalView')}
                >
                  <img src={leftOutlined} alt='close-icon' />
                  {kpiName}
                </Link>
              )}
            </Grid>
            <Grid item xl={12} md={12} xs={12} container className={classes.widgetContainer}>
              <Grid item xl={12} md={12} xs={12} container className={classes.widgetContainerTable}>
                <Grid item xl={12} md={12} xs={12} container>
                  <Grid item xl={12} md={12} xs={12} className={classes.tableContainer}>
                    <TableContainer
                      className={classes.table}
                      sx={{
                        minHeight: 300,
                      }}
                    >
                      <Table stickyHeader>
                        <EnhancedTableHead
                          headCells={headCells}
                          order={order}
                          orderBy={orderBy}
                          onRequestSort={handleRequestSort}
                          onHandleExport={onHandleExport}
                          size='small'
                          backgroundcolor='#fff'
                        />
                        <TableBody>
                          {rowData.map((row, index) => (
                            <RSMHistoricalTableRow
                              key={row.id}
                              size='small'
                              newHeading={newHeading}
                              rankingFilter={rankingFilter}
                              row={row}
                            />
                          ))}
                          <Loader loadingStatus={loading} />
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default RSMHistoricalSection;
