import React, { useContext, useEffect } from 'react';

import { ReactPlugin, AppInsightsContext } from '@microsoft/applicationinsights-react-js';
import { ApplicationInsights } from '@microsoft/applicationinsights-web';
import { ThemeProvider } from '@mui/material';
import * as Msal from 'msal';
import { Route, Routes } from 'react-router-dom';

import AuthContext from './components/context/AuthContext';
import FavoritesStates from './components/context/FavoritesStates';
import FilterStates from './components/context/filterStates';
import RSMTileStates from './components/context/RSMTileStates';
import HelpItemStates from './components/context/HelpItemStates';
import HistoricalViewStates from './components/context/HistoricalViewStates';
import NotificationSettingStates from './components/context/notificationSettingStates';
import Layout from './components/organisms/Layout';
import LayoutHistorical from './components/organisms/LayoutHistorical';
import AlertDetails from './components/pages/AlertDetails';
import Alerts from './components/pages/Alerts';
import AppHelp from './components/pages/AppHelp';
import AppStatistics from './components/pages/AppStatistics';
import Favorites from './components/pages/Favorites';
import HelpCentre from './components/pages/HelpCentre';
import HistoricalFavorites from './components/pages/HistoricalFavorites';
import HistoricalView from './components/pages/HistoricalView';
import Home from './components/pages/Home';
import RsmAlertDetails from './components/pages/RsmAlertDetails';
import RSMDashboardDetails from './components/pages/RSMDashboardDetails';
import SwitchUser from './components/pages/SwitchUser';
import config from './config';
import theme from './theme';
import NationalHistoricalView from './components/pages/NationalHistoricalView';
import NationalViewFavorites from './components/pages/NationalViewFavorites';
import NationalViewDetails from './components/pages/NationalViewDetails';
import RpsmAlertDetails from './components/pages/RpsmAlertDetails';
import RSMHistoricalDetails from './components/pages/RSMHistoricalDetails';

// Config setting for msal (Microsoft authentication library)
const { clientId, authority, redirectUri, connectionString } = config;
const msalConfig = {
  auth: {
    clientId: clientId,
    authority: authority,
    redirectUri: redirectUri,
  },
};
// Create instance for login authentication
const msalInstance = new Msal.UserAgentApplication(msalConfig);

function App(props) {
  const { showAlert } = props;
  const { user, init, getUserLogin, setInit } = useContext(AuthContext);

  // Applcation Insights configuration
  var reactPlugin = new ReactPlugin();
  var appInsights = new ApplicationInsights({
    config: {
      connectionString: connectionString,
      instrumentationKey: connectionString,
      extensions: [reactPlugin],
    },
  });
  // End

  useEffect(() => {
    if (!user && !init) {
      setInit();
      const loginRequest = {
        scopes: ['https://analysis.windows.net/powerbi/api/Report.Read.All'],
      };
      const authenticate = () => {
        const successCallback = async (response) => {
          if (response.tokenType === 'id_token') {
            authenticate();
          } else if (response.tokenType === 'access_token') {
            getUserLogin(response);
            return;
          }
        };

        const failCallBack = (error) => {};
        // check if there is a cached user
        if (msalInstance.getAccount()) {
          msalInstance
            .acquireTokenSilent(loginRequest)
            .then(successCallback)
            .catch((err) => {
              if (err.name === 'InteractionRequiredAuthError') {
                msalInstance.acquireTokenRedirect(loginRequest);
              }
            });
        } else {
          // Show the login popup
          msalInstance.loginPopup(loginRequest).then(successCallback).catch(failCallBack);
        }
      };
      authenticate();
      return;
    }
  }, [user, init, setInit, getUserLogin]);

  // Load App Insights and set User authentication for App Insights.
  user && appInsights.loadAppInsights();
  user && appInsights.setAuthenticatedUserContext(user.email, null, true);

  // Check code
  return (
    user && (
      <AppInsightsContext.Provider value={reactPlugin}>
        <RSMTileStates>
          <FilterStates>
            <NotificationSettingStates>
              <FavoritesStates>
                <HistoricalViewStates>
                  <HelpItemStates>
                    <ThemeProvider theme={theme}>
                      <Routes>
                        <Route element={<SwitchUser showAlert={showAlert} />} path='/' />
                        <Route element={<SwitchUser showAlert={showAlert} />} path='/editProfile' />
                        <Route element={<Layout />}>
                          <Route element={<Alerts showAlert={showAlert} />} path='/alerts' />
                          <Route path='/alertDetails/:alertId'>
                            <Route path=':message/:location' element={<AlertDetails showAlert={showAlert} />} />
                            <Route path=':location' element={<AlertDetails showAlert={showAlert} />} />
                            <Route path='' element={<AlertDetails showAlert={showAlert} />} />
                          </Route>
                          <Route path='/rsmAlertDetails/:alertId'>
                            <Route path=':message' element={<RsmAlertDetails showAlert={showAlert} />} />
                            <Route path='' element={<RsmAlertDetails showAlert={showAlert} />} />
                          </Route>
                          <Route path='/rpsmAlertDetails/:alertId'>
                            <Route path=':message' element={<RpsmAlertDetails showAlert={showAlert} />} />
                          </Route>
                          <Route element={<Alerts showAlert={showAlert} />} path='/archiveAlerts' />
                          <Route element={<HelpCentre showAlert={showAlert} />} path='/helpCentre' />
                          <Route element={<AppStatistics showAlert={showAlert} />} path='/appStatistics' />
                          {user.personaId !== null && user.persona.layoutId !== 3 && (
                            <>
                              <Route element={<Home showAlert={showAlert} />} path='/home' />
                              <Route element={<Favorites showAlert={showAlert} />} path='/favorites/dailyView' />
                              <Route element={<RSMDashboardDetails showAlert={showAlert} />} path='/rsmDetails/:id' />
                              <Route
                                element={<RSMHistoricalDetails showAlert={showAlert} />}
                                path='/rsmHistoricalDetails/:id'
                              />
                            </>
                          )}
                        </Route>
                        {user.personaId !== null && user.persona.layoutId !== 3 && (
                          <Route element={<LayoutHistorical />}>
                            <Route element={<HistoricalView showAlert={showAlert} />} path='/historicalView' />
                            <Route
                              element={<HistoricalFavorites showAlert={showAlert} />}
                              path='/favorites/historicalView'
                            />
                          </Route>
                        )}
                        {user.personaId !== null && user.persona.layoutId === 3 && (
                          <Route element={<LayoutHistorical />}>
                            <Route
                              element={<NationalHistoricalView showAlert={showAlert} />}
                              path='/nationalHistoricalView'
                            />
                            <Route
                              element={<NationalViewFavorites showAlert={showAlert} />}
                              path='/favorites/nationalView'
                            />
                            <Route
                              element={<NationalViewDetails showAlert={showAlert} />}
                              path='/hqDetails/:id/:kpiId'
                            />
                            <Route
                              element={<HistoricalView showAlert={showAlert} />}
                              path='/historicalView/region/:regionName'
                            />
                            <Route
                              element={<HistoricalView showAlert={showAlert} />}
                              path='/historicalView/site/:siteName'
                            />
                          </Route>
                        )}
                      </Routes>
                    </ThemeProvider>
                  </HelpItemStates>
                </HistoricalViewStates>
              </FavoritesStates>
            </NotificationSettingStates>
          </FilterStates>
        </RSMTileStates>
      </AppInsightsContext.Provider>
    )
  );
}

export default App;
