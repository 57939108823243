import { makeStyles } from '@mui/styles';
const AlertDetailsStyle = makeStyles(() => ({
  alertLayoutContainer: {
    padding: '12px',
    display: 'block',
    marginTop: 24,
  },
  tableHeading: {
    fontSize: '1.28rem',
    color: '#3D4043',
    opacity: 0.8,
    padding: 12,
    textTransform: 'uppercase',
  },
  tableCell: {
    fontSize: '1.2rem',
    color: '#011F36',
    padding: 12,
  },
  backButton: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    fontSize: '1.4rem',
    cursor: 'pointer',
    fontWeight: 'bold',
    '& img': {
      width: 10,
      paddingRight: 5,
    },
  },
  alertContainer: {
    backgroundColor: '#ffffff',
    borderRadius: 4,
    alignContent: 'flex-start',
    padding: 4,
  },
  noData: {
    padding: 12,
    fontSize: '1.6rem',
    color: '#545C63',
    display: 'flex',
    justifyContent: 'center',
    opacity: 0.5,
  },
  exportButton: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  alertHeader: {
    marginBottom: 20,
  },
}));

export default AlertDetailsStyle;
