/*
This component contains source code that defines an Modal popup.
It takes boolean value as input and returns Modal pop up that can be displayed on the screen.
*/
import React from 'react';

import { Modal as MaterialModal } from '@mui/material';

const Modal = (props) => {
  const { open, handleClose, sx, children } = props;
  return (
    <div>
      <MaterialModal
        sx={sx}
        open={open}
        onClose={handleClose}
        aria-labelledby='modal-modal-title'
        aria-describedby='modal-modal-description'
      >
        {children}
      </MaterialModal>
    </div>
  );
};

Modal.propTypes = {};

export default Modal;
