/*
This component contains source code that creates a tile structure that displays KPI information/details.
It is used to KPI information/details in the L1/Executive summary section on the Home, Favorites pages in Non-RSM personas.
*/
import React from 'react';
import { Grid } from '@mui/material';
import moment from 'moment';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import DragIndicatorIcon from '@mui/icons-material/DragIndicator';

import TileStyles from '../styles/TileStyle';
import RatingTag from './RatingTag';
import LineChart from '../atom/LineChart';
import '../styles/CustomTooltip.css';

const Tile = (props) => {
  const { kpiName, reportDetail, ratingPoint, onToggleRowStarClick, onTileClick, user, KpiId, dragKpi } = props;
  let refresh_date = reportDetail.refresh_date ? moment(reportDetail.refresh_date).format('MM/DD') : '';
  const classes = TileStyles();
  return (
    <Grid item container spacing={0} xl={12} xs={12} md={12} className={classes.tileContainer} onClick={onTileClick}>
      <Grid item container spacing={0} xl={12} xs={12} md={12} className={classes.firstRow}>
        <span>{kpiName}</span>
        <div className={classes.overlayRating} onClick={onToggleRowStarClick}>
          <RatingTag ratingScale={1} ratingPoint={ratingPoint} />
        </div>
      </Grid>
      <Grid item container spacing={0} xl={12} xs={12} md={12} className={classes.secondRow}>
        <Grid item xl={6} xs={6} md={6} className={classes.last24Hrs} style={{ color: reportDetail.value_color }}>
          {reportDetail.last24Hrs ? `${reportDetail.last24Hrs}${reportDetail.last24HrsUnit}` : '(Blank)'}
        </Grid>
        <Grid item xl={6} xs={6} md={6} className={classes.rating}>
          <div className={classes.agChartWrapper}>
            <LineChart lineChartData={reportDetail.chart} />
          </div>
        </Grid>
      </Grid>
      <Grid item container spacing={0} xl={12} xs={12} md={12} className={classes.thirdRow}>
        <span
          className={classes.diffrence}
          style={{ color: reportDetail.difference_color, marginRight: reportDetail.difference ? '16px' : '0px' }}
        >
          {reportDetail.difference
            ? `${
                reportDetail.difference_arrowDirection
                  ? reportDetail.difference_arrowDirection === 'up'
                    ? '▲'
                    : '▼'
                  : ''
              } ${reportDetail.difference}${reportDetail.differenceUnit}`
            : ''}
        </span>
        <span className={classes.fromDays}>{reportDetail.days_desc}</span>
      </Grid>
      <Grid item container spacing={0} xl={12} xs={12} md={12} className={classes.fourthRow}>
        <Grid item xl={9} xs={9} md={9}>
          <span className={classes.targetKey}>Target :</span>
          <span className={classes.targetValue}>{`${
            reportDetail.target === null ? 'N/A' : `${reportDetail.target}${reportDetail.targetUnit}`
          }`}</span>
        </Grid>
        <Grid item xl={3} xs={3} md={3}>
          <span className={classes.refreshDate}>{refresh_date}</span>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default Tile;
