/*
This component contains source code that defines an line chart/ line series
It takes chartdata as an array input and returns trend line chart that can be displayed on the screen.
*/
import React from 'react';
import { AgChartsReact } from 'ag-charts-react';

const LineChart = (props) => {
  const { lineChartData, tableLineChart } = props;
  // Convert the line chart data string to array of object
  const chartData = lineChartData?.split(',').map((el, i) => {
    return { x: i + 1, y: parseFloat(el) };
  });
  // Set the marker size
  const size = tableLineChart ? 7 : 5;
  // Set the width of line
  const strokeWidth = tableLineChart ? '4.2' : '3';
  // Configuration of graph
  const options = {
    data: chartData,
    series: [
      {
        xKey: 'x',
        yKey: 'y',
        stroke: '#004D8A',
        strokeWidth: strokeWidth,
        marker: {
          shape: 'circle',
          size: size,
          fill: '#004D8A',
          stroke: '#004D8A',
        },
      },
    ],
    axes: [
      {
        type: 'number',
        position: 'bottom',
        tick: {
          count: 0,
        },
        line: {
          width: '0px',
        },
      },
      {
        type: 'number',
        position: 'left',
        tick: {
          count: 0,
        },
        line: {
          width: '1px',
        },
      },
    ],
    legend: {
      enabled: false,
    },
    tooltip: {
      enabled: false,
    },
  };
  return <AgChartsReact options={options} />;
};

LineChart.propTypes = {};

export default LineChart;
