/* 
This file creates a reducer function for Resource library page used in the application.
It will have initial state and resource library page types used in the application.
*/
import React, { useReducer } from 'react';

import ResourceLibraryContext from './ResourceLibraryContext';
import { getResourceLibrary } from '../../actions/resourceLibrary';
// add the details for the Resource Library
export const SET_RESOURCE_LIBRARY = 'SET_RESOURCE_LIBRARY';

// Reducer
const ResourceLibraryReducer = (state, action) => {
  const { type, payload } = action;
  switch (type) {
    case SET_RESOURCE_LIBRARY:
      return {
        ...state,
        resourceLibraryList: payload,
      };
    default:
      return state;
  }
};
const ResourceLibraryStates = ({ children }) => {
  // Initial state
  const initialState = {
    resourceLibraryList: [],
  };

  const [state, dispatch] = useReducer(ResourceLibraryReducer, initialState);

  // Function to get and set resource library data
  const getResourceLibraryData = async () => {
    const data = await getResourceLibrary();
    dispatch({
      type: SET_RESOURCE_LIBRARY,
      payload: data,
    });
  };

  return (
    <ResourceLibraryContext.Provider
      value={{
        resourceLibraryList: state.resourceLibraryList,
        getResourceLibraryData,
      }}
    >
      {children}
    </ResourceLibraryContext.Provider>
  );
};

export default ResourceLibraryStates;
