import { makeStyles } from '@mui/styles';

const SearchStyles = makeStyles(() => ({
  container: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',

    borderColor: '#EDEDED',
    borderStyle: 'solid',
    borderWidth: 0,
    borderBottomRadius: 10,
    borderBottomWidth: 3,
  },
  input: {
    width: '100%',
  },
}));

export default SearchStyles;
