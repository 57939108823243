/*
This component contains source code that defines an Multiselect drop down.
It takes array/ json data as input and returns Multiselect drop down that can be displayed on the screen.
*/
/* eslint-disable no-use-before-define */
import React, { useState } from 'react';
import { CheckBoxOutlineBlank, CheckBox as CheckBoxIcon } from '@mui/icons-material';
import {
  Autocomplete,
  createFilterOptions,
  TextField,
  Typography,
  Paper,
  Checkbox,
  Box,
  Chip,
  Popper,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import { useEffect } from 'react';

const icon = <CheckBoxOutlineBlank fontSize='small' />;
const checkedIcon = <CheckBoxIcon fontSize='small' />;

// Function to comtomize the list of dropdown as styles
const CustomPaper = (props) => {
  return <Paper style={{ backgroundColor: 'rgb(243, 248, 255)', border: '1px solid #eaeaea' }} {...props} />;
};

const CustomPopper = function (props) {
  const classes = useStyles({});
  return <Popper {...props} className={classes.root} />;
};

// Function to apply css
const useStyles = makeStyles((theme) => ({
  autoComplete: {
    marginTop: '-10px',
    width: 250,
    color: '#004D8A',
    font: 'normal normal normal 12px Segoe UI',
    '& .MuiOutlinedInput-root': {
      color: '#525A63',
      backgroundColor: 'rgb(243, 248, 255)',
      border: '1px solid #eaeaea',
      '& fieldset': {
        border: 'none',
        borderColor: 'rgba(0, 0, 0, 0.23)',
      },
    },
    '& .MuiAutocomplete-hasClearIcon': {
      fontSize: '20px',
    },
  },
  labelStyle: {
    [theme.breakpoints.down('sm')]: {
      font: 'normal normal normal 18px Segoe UI',
    },
    color: (props) => (props.labelDisabled ? '#BBBCBC' : '#1C1C1C'),
    font: 'normal normal normal 20px Segoe UI',
    marginBottom: 10,
    display: 'inline-block',
  },
  textField: {
    fontSizeAdjust: '',
    '& .MuiAutocomplete-input': {
      [theme.breakpoints.down('sm')]: {
        fontSize: '16px',
        height: '12px',
      },
      fontSize: '20px',
      height: '14px',
    },
  },
  smallFont: {
    fontSize: '1rem',
  },
  chip: {
    '& span': {
      paddingLeft: 8,
      paddingRight: 8,
    },
    margin: 0,
    marginRight: 1,
    height: 23,
    display: 'inline-flex',
    alignItems: 'center',
    maxWidth: 156,
  },
  chipExportFile: {
    '& span': {
      fontSize: '1rem',
      display: 'inline-flex',
      alignItems: 'center',
    },
    margin: 0,
    marginRight: 1,
  },
  root: {
    zIndex: '9999 !important',
    transform: 'none !important',
    inset: 'unset !important',
    width: '100%',
  },
}));

const MultiSelect = (props) => {
  const {
    items,
    label,
    selectAllLabel,
    limitTags,
    onSelectChange,
    size,
    disabled,
    defaultData,
    defaultAllSelected,
    chipExportFile,
  } = props;
  const classes = useStyles({ labelDisabled: disabled });
  const [selectedOptions, setSelectedOptions] = useState([]);
  const allSelected = items.length === selectedOptions.length;
  const handleToggleOption = (selectedOptions) => setSelectedOptions(selectedOptions);
  const handleClearOptions = () => setSelectedOptions([]);

  // Function to get option lable
  const getOptionLabel = (option) => {
    return `${option.label}`;
  };

  // Callback function to set default selected option
  useEffect(() => {
    handleToggleOption(defaultData);
  }, [defaultData]);

  // By default All options Selected
  useEffect(() => {
    if (defaultAllSelected && items.length > 0) {
      let result = [];
      result = items.filter((el) => el.value !== 'select-all');
      onSelectChange(result, defaultAllSelected);
      return handleToggleSelectAll();
    } else {
      onSelectChange(defaultData, allSelected);
    }
  }, [defaultAllSelected, items]);

  // Function to select all options
  const handleSelectAll = (isSelected) => {
    if (isSelected) {
      setSelectedOptions(items);
    } else {
      handleClearOptions();
    }
  };

  // Function to toggle select all options
  const handleToggleSelectAll = () => {
    handleSelectAll && handleSelectAll(!allSelected);
  };

  // Function to handle select/unselect option
  const handleChange = (event, selectedOptions, reason, details) => {
    if (reason === 'selectOption' || reason === 'removeOption') {
      if (selectedOptions.find((option) => option.value === 'select-all')) {
        let result = [];
        result = items.filter((el) => el.value !== 'select-all');
        onSelectChange(!allSelected ? result : [], !allSelected);
        return handleToggleSelectAll();
      } else {
        handleToggleOption && handleToggleOption(selectedOptions);
        return onSelectChange(selectedOptions, items.length === selectedOptions.length);
      }
    } else if (reason === 'clear') {
      handleClearOptions && handleClearOptions();
    }
  };

  // Function to customize the options
  const optionRenderer = (props, option, { selected }) => {
    const selectAllProps =
      option.value === 'select-all' // To control the state of 'select-all' checkbox
        ? { checked: allSelected }
        : {};
    return (
      <>
        <li {...props}>
          <Checkbox
            icon={icon}
            checkedIcon={checkedIcon}
            style={{ marginRight: 8 }}
            checked={selected}
            {...selectAllProps}
          />
          {getOptionLabel(option)}
        </li>
      </>
    );
  };
  const inputRenderer = (params) => <TextField className={classes.textField} {...params} />;
  const filter = createFilterOptions();

  return (
    <>
      {label && (
        <Typography variant='body1' component='label' className={classes.labelStyle}>
          {label}
        </Typography>
      )}
      <Autocomplete
        multiple
        disableClearable
        size={size}
        limitTags={limitTags}
        options={items}
        value={selectedOptions}
        disableCloseOnSelect
        PaperComponent={CustomPaper}
        getOptionLabel={getOptionLabel}
        isOptionEqualToValue={(option, value) => option.value === value.value}
        filterOptions={(options, params) => {
          const filtered = filter(options, params);
          return [{ label: selectAllLabel, value: 'select-all' }, ...filtered];
        }}
        onChange={handleChange}
        renderOption={optionRenderer}
        renderInput={inputRenderer}
        className={(classes.autoComplete, props.className)}
        classes={{ option: classes.smallFont }}
        disabled={disabled}
        disablePortal={true}
        PopperComponent={(e) => CustomPopper(e)}
        renderTags={() => (
          <>
            <Box
              mt={0}
              sx={{
                '& > :not(:last-child)': { mr: 1 },
                '& > *': { mr: 1 },
                display: 'flex',
                alignItems: 'center',
              }}
            >
              {selectedOptions.length > 0 && (
                <>
                  <Chip
                    className={`${chipExportFile ? classes.chipExportFile : classes.chip}`}
                    key={selectedOptions[0].label}
                    label={selectedOptions[0].label}
                  />
                  {chipExportFile && selectedOptions.length > 1 ? (
                    <>
                      <Chip
                        className={classes.chipExportFile}
                        key={selectedOptions[1].label}
                        label={selectedOptions[1].label}
                      />
                      {selectedOptions.length > 2 && `+${selectedOptions.length - 2}`}
                    </>
                  ) : (
                    <>{selectedOptions.length > 1 && `+${selectedOptions.length - 1}`}</>
                  )}
                </>
              )}
            </Box>
          </>
        )}
      />
    </>
  );
};
MultiSelect.defaultProps = {
  label: '',
  limitTags: 1,
  items: [],
  selectedValues: [],
  getOptionLabel: (value) => value,
  defaultAllSelected: false,
  chipExportFile: false,
};

export default MultiSelect;
