/*
This component contains source code that defines an alert message.
It takes props columnName, rsmAlert, alertId of KPI and returns a alert row that can be displayed on the screen.
*/
import React from 'react';
import { TableCell, TableRow } from '@mui/material';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import AlertDetailsStyle from '../styles/AlertDetailsStyle';

const AlertDetailRow = (props) => {
  const { row, columnName, rsmAlert, alertId, message } = props;
  const classes = AlertDetailsStyle();
  const navigate = useNavigate();

  // Function to navigate to alert details page
  const navigatoTo = (e) => {
    e.preventDefault();
    const filter = row.hasOwnProperty('Location Description')
      ? row['Location Description']
      : row.hasOwnProperty('Mixer Location Description')
      ? row['Mixer Location Description']
      : row['Department'];
    if (message === 1 || message === 2) {
      navigate(`/alertDetails/${alertId}/${message}/${filter}`);
    } else {
      navigate(`/alertDetails/${alertId}/${filter}`);
    }
    return false;
  };

  return (
    <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
      {columnName.map((key) => (
        <TableCell key={row[key]} align='left' className={classes.tableCell}>
          {row[key]}
        </TableCell>
      ))}
      {rsmAlert && (
        <TableCell align='right' className={classes.tableCell}>
          <KeyboardArrowRightIcon
            onClick={navigatoTo}
            style={{ color: '#0056C8', cursor: 'pointer', fontSize: '1.8rem' }}
          />
        </TableCell>
      )}
    </TableRow>
  );
};

AlertDetailRow.propTypes = {
  row: PropTypes.object,
  columnName: PropTypes.array,
  rsmAlert: PropTypes.bool,
};

AlertDetailRow.defaultProps = {
  row: {},
  columnName: [],
  rsmAlert: false,
};

export default AlertDetailRow;
