/*
This component contains source code that will display a list of L0/Executive summary page tiles and tabs in the RSM Home page.
*/
import React, { useContext, useEffect, useState } from 'react';

import { useAppInsightsContext } from '@microsoft/applicationinsights-react-js';
import { useParams, useLocation } from 'react-router-dom';

import AuthContext from '../context/AuthContext';
import RSMDashboardSection from '../organisms/RSMDashboardSection';

const RSMDashboardDetails = () => {
  const { id } = useParams();
  const appInsights = useAppInsightsContext();
  const { user } = useContext(AuthContext);
  const { state } = useLocation();
  const [favorite, setFavorite] = useState('');
  const [innerKpiId, setInnerKpiId] = useState('');
  //code to set Favorite state for favorite page
  useEffect(() => {
    if (state.favorite) {
      setFavorite(state.favorite);
    }
  }, []);
  // code to set the ID for RSM
  useEffect(() => {
    if (state.innerKpiId) {
      setInnerKpiId(state.innerKpiId);
    }
  }, []);
  // Callback function for tracking the page
  useEffect(() => {
    appInsights.trackPageView({
      uri: window.location.href,
      refUri: window.location.href,
      properties: {
        personaName: user.persona.name,
      },
    });
  }, []);

  return <RSMDashboardSection kpiId={id} FavoritePageButton={favorite} innerKpiId={innerKpiId} />;
};

export default RSMDashboardDetails;
