import { makeStyles } from '@mui/styles';

const HeaderStyles = makeStyles(() => ({
  header: {
    boxShadow: 'none',
    backgroundColor: '#004D8A',
    position: 'fixed',
    zIndex: 999,
  },
  headerContainer: {
    paddingLeft: '0px !important',
  },
  landPageHeader: {
    boxShadow: 'none',
    backgroundColor: '#ffffff',
  },
  logoContainer: {
    background: '#054476',
    paddingTop: 8,
    paddingLeft: 3,
    paddingBottom: 6,
    width: '14.16%',
    maxWidth: '14.16%',
    flexBasis: '14.16%',
  },
  logoInnerContainer: {
    paddingLeft: 10,
  },
  logImage: {
    height: 36,
    width: 200,
    left: 10,
  },
  badgeCont: {
    '& span': {
      background: '#F7921D',
      right: '-15px',
      width: '30px',
      height: '30px',
      fontSize: '1.5rem',
      borderRadius: 15,
    },
  },
  navLink: {
    padding: 15,
    fontSize: '1.5rem',
    textTransform: 'none',
  },
  menuItem: {
    padding: 5,
    fontSize: '1.2rem',
  },
  activeNavLink: {
    padding: 15,
    borderBottom: '3px solid #F9C124',
    fontWeight: 'bold',
    fontSize: '1.5rem',
    textTransform: 'none',
  },
  navLinkContainer: {
    padding: '10px 0px 0px 10px',
  },
  favoriteStar: {
    paddingTop: 32,
  },
  favorite: {
    paddingTop: 34,
    fontSize: '1.5rem',
  },
  ratingEmptyIcon: {
    '& .MuiRating-iconEmpty': {
      color: 'white',
    },
  },
  favoriteLink: {
    display: 'flex',
    alignItems: 'center',
  },
  favText: {
    borderRight: '1px solid white',
    paddingRight: '35px',
  },
}));

export default HeaderStyles;
