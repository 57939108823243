/*
This component contains source code that defines an table with all the details in the View More section of the RSM persona Home page.
It takes props like KPI id, filters data to return KPI details locationwise , ranking and sorting table that can be displayed on the screen.
*/
import React from 'react';

import { KeyboardArrowRight } from '@mui/icons-material';
import { TableCell, TableRow } from '@mui/material';
import PropTypes from 'prop-types';

import LineChart from '../atom/LineChart';
import RSMDashboardDetailsStyle from '../styles/RSMDashboardDetailsStyle';
const RSMTableRow = (props) => {
  const { innerKpiId, row, onRowClick, size, designFlag, newHeading, layoutId } = props;
  const classes = RSMDashboardDetailsStyle();
  return (
    <TableRow className={classes.tableRow} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
      <TableCell size={size} align='left' className={classes.tableCell}>
        {row.ranking}
      </TableCell>
      <TableCell size={size} align='left' className={classes.tableCell}>
        {row.site}
      </TableCell>
      {designFlag === 2 ? (
        <>
          {newHeading.map((data) => (
            <TableCell
              key={`${row.id}_${data}_Unit_${row[`${data}_Unit`]}`}
              size={size}
              align='left'
              className={classes.tableCell}
            >
              <span>
                {row[data]
                  ? row[`${data}_Unit`] === '$'
                    ? `${row[`${data}_Unit`]}${row[data]}`
                    : `${row[data]}${row[`${data}_Unit`]}`
                  : '(Blank)'}
              </span>
            </TableCell>
          ))}
        </>
      ) : (
        <>
          <TableCell size={size} align='left' className={classes.tableCell}>
            <span style={{ color: row.value_color !== 'null' ? row.value_color : '' }}>
              {row.last24Hrs ? `${row.last24Hrs}${row.last24HrsUnit}` : '(Blank)'}
            </span>
          </TableCell>
          {innerKpiId == 'WAR008' || innerKpiId == 'MFG003' ? (
            ''
          ) : (
            <TableCell size={size} align='left' className={classes.tableCell}>
              <span style={{ color: row.value_color !== 'null' ? row.value_color : '' }}>
                {row.wtd ? `${row.wtd}${row.wtdUnit}` : '(Blank)'}
              </span>
            </TableCell>
          )}
          {layoutId === 3 ? (
            <>
              <TableCell size={size} align='left' className={classes.tableCell}>
                <div className={classes.diffrenceSection}>
                  <span
                    className={classes.diffrence}
                    style={{ color: row.difference_color !== null ? row.difference_color : '' }}
                  >
                    {row.difference && row.difference !== 'null' ? `${row.difference}${row.differenceUnit}` : ''}
                  </span>
                </div>
              </TableCell>
              <TableCell size={size} align='left' className={classes.tableCell}>
                <div className={classes.agChartWrapper}>
                  <LineChart lineChartData={row.last7days} />
                </div>
              </TableCell>
            </>
          ) : (
            <>
              <TableCell size={size} align='left' className={classes.tableCell}>
                <div className={classes.agChartWrapper}>
                  <LineChart lineChartData={row.last7days} />
                </div>
              </TableCell>
              {innerKpiId == 'WAR008' || innerKpiId == 'MFG003' ? (
                ''
              ) : (
                <TableCell size={size} align='left' className={classes.tableCell}>
                  <div className={classes.diffrenceSection}>
                    <span
                      className={classes.diffrence}
                      style={{ color: row.difference_color !== null ? row.difference_color : '' }}
                    >
                      {row.difference && row.difference !== 'null'
                        ? `${
                            row.difference_arrowDirection ? (row.difference_arrowDirection === 'up' ? '▲' : '▼') : ''
                          } ${row.difference}${row.differenceUnit}`
                        : ''}
                    </span>
                    <span className={classes.fromDays}>{row.days_desc}</span>
                  </div>
                </TableCell>
              )}
            </>
          )}
        </>
      )}
      <TableCell size={size} align='left' className={classes.tableCell}>
        <KeyboardArrowRight
          sx={{ color: '#63666A', fontSize: 38 }}
          onClick={onRowClick}
          className={classes.iconClick}
        />
      </TableCell>
    </TableRow>
  );
};

RSMTableRow.propTypes = {
  row: PropTypes.object,
};

RSMTableRow.defaultProps = {
  row: {},
};

export default RSMTableRow;
