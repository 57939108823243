import { makeStyles } from '@mui/styles';

const NotificationSettingCustomMenuStyles = makeStyles(() => ({
  menu: {
    '& .MuiPaper-root': {
      minWidth: 300,
      boxShadow:
        '0px 10px 13px 9px rgb(0 0 0 / 20%), 0px 20px 31px 8px rgb(0 0 0 / 14%), 0px 8px 20px 15px rgb(0 0 0 / 12%)',
    },
    '& ul': {
      padding: 0,
    },
  },
  title: {
    color: '#011F36',
    fontSize: '1.2rem',
  },
  weeklyContainer: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  weekly: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    fontSize: '1.2rem',
    color: '#707070',
    border: '1px solid #707070',
    borderRadius: 50,
    width: 30,
    height: 30,
    cursor: 'pointer',
  },
  daySelected: {
    backgroundColor: '#0056C8',
    border: '1px solid #0056C8',
    color: '#FFFFFF',
  },
  action: {
    display: 'flex',
    justifyContent: 'flex-end',
    '& button': {
      textTransform: 'capitalize',
      fontSize: '1.2rem',
    },
  },
  doneAction: {
    color: '#0056C8',
  },
  cancelAction: {
    color: '#011F36',
  },
}));
export default NotificationSettingCustomMenuStyles;
