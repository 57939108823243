/*
This component contains source code that defines an input box with readonly property.
It takes label, and value as input and returns input box with data populated that can be displayed on the screen.
*/

import React from 'react';

import { Input, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import PropTypes from 'prop-types';

// Function to apply css
const useStyles = makeStyles(() => ({
  labelStyle: {
    color: '#004D8A',
    font: 'normal normal normal 1rem Segoe UI',
    marginBottom: '300px',
    fontWeight: 'bolder',
  },
  input: {
    border: '1px solid #eaeaea',
    marginTop: '4px',
    width: '100%',
    '& input': {
      padding: '3px',
      fontSize: '1rem',
      color: '#004D8A',
      backgroundColor: 'rgb(243, 248, 255)',
    },
    '&:after': {
      content: 'none',
    },
    '&:before': {
      content: 'none',
    },
  },
}));

const InputBox = (props) => {
  const { label, onChange, readOnly, value } = props;
  const classes = useStyles();
  return (
    <div>
      {label && (
        <Typography variant='body1' component='label' className={classes.labelStyle}>
          {label}
        </Typography>
      )}
      {value && <Input readOnly={readOnly} value={value} className={classes.input} onChange={onChange} {...props} />}
    </div>
  );
};

InputBox.propTypes = {
  value: PropTypes.string,
  label: PropTypes.string,
  onChange: PropTypes.func,
  readOnly: PropTypes.bool,
};
export default InputBox;
