import React, { useContext, useEffect } from 'react';

import { Grid } from '@mui/material';
import { Outlet } from 'react-router-dom';
import PropTypes from 'prop-types';

import AuthContext from '../context/AuthContext';
import LayoutStyles from '../styles/LayoutStyles';

import Header from './Header';
import setBodyColor from '../atom/SetBodyColor';

const Layout = (props) => {
  const classes = LayoutStyles();
  const { user } = useContext(AuthContext);

  // Callback function for set body color
  useEffect(() => {
    setBodyColor({ color: '#f3f8ff' });
  }, []);

  return (
    <>
      <div class='main-container'>
        <Header />
        <Grid
          item
          container
          xl={12}
          xs={12}
          md={12}
          className={user.persona.layoutId === 1 ? classes.layoutRoot : classes.rsmLayoutRoot}
        >
          <Outlet
            context={{
              user: user,
            }}
          />
        </Grid>
      </div>
    </>
  );
};

Layout.propTypes = {};
Layout.defaultProps = {};

export default Layout;
