import React, { memo, useState, useEffect } from 'react';
import { useContext } from 'react';

import { Grid, Link } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { isEmpty } from 'lodash';

import closeIcon from '../assets/close-icon.svg';
import leftOutlined from '../assets/leftOutlined.svg';
import AuthContext from '../context/AuthContext';
import AlertsRow from '../molecules/AlertsRow';
import AlertsStyles from '../styles/AlertsStyle';
import NotificationSettingEnableModal from '../atom/NotificationSettingEnableModal';
import NotificationSettingContext from '../context/NotificationSettingContext';
import LayoutStyles from '../styles/LayoutStyles';

const AlertsSection = (props) => {
  const { todayAlerts, yesterdayAlerts, archiveData, alerts, archiveAlerts, showAlert } = props;
  const { resolveAlert, user, alertsCount, resolveMsg } = useContext(AuthContext);
  const { disableNotificationKPI, disableAlertEmailNotificationData } = useContext(NotificationSettingContext);
  const [archiveTimeOut, setArchiveTimeOut] = useState();
  const [resolveTimeOut, setResolveTimeOut] = useState();
  const [handleCloseNotificationEnableModal, setHandleCloseNotificationEnableModal] = useState(false);
  const [notificationSettingData, setNotificationSettingData] = useState(null);
  const [archiveKpiData, setArchiveKpiData] = useState(null);
  const [alertLocation, setAlertLocation] = useState(null);

  const classes = AlertsStyles();
  const layoutClasses = LayoutStyles();

  const navigate = useNavigate();
  const [resolvedMsg, setResolvedMsg] = useState({
    message: '',
    id: null,
  });
  const [archiveMsg, setArchiveMsg] = useState({
    message: '',
    id: null,
    kpi_id: '',
    action: '',
  });

  // Function for hide alert notification message
  const hideAlertMessage = (id) => {
    if (resolvedMsg.id === id) {
      undoAction();
    }
  };
  // Function for hide archive alert notification message
  const hideArchiveMessage = (id) => {
    if (archiveMsg.id === id) {
      undoArchiveAction();
    }
  };

  // Function to update the alert location based on persona
  useEffect(() => {
    if (user.persona.layoutId == 1) {
      setAlertLocation(user.location);
    } else if (user.persona.layoutId == 2) {
      setAlertLocation(user.region);
    } else {
      setAlertLocation(user.country);
    }
  }, [user]);

  // Set the Resolved/Archived/Unresolved/Unarchived status
  const updateStatus = (data) => {
    resolveAlert(data, user, alertsCount);
  };

  // Callback function for display notification message and resolved message
  useEffect(() => {
    if (resolveMsg) {
      if (resolveMsg.conflict === 'no') {
        let message_data = resolveMsg.message.replaceAll(`"tooltip"`, `"tooltip yesterday"`);
        setResolvedMsg({
          message: message_data,
          id: resolveMsg.id,
        });
      } else {
        showAlert({
          alertMessage: resolveMsg.message,
          alertType: 'error',
        });
      }
    }
  }, [resolveMsg]);

  // Update the status of Archive/Unarchive
  const doArchiveAlert = (data) => {
    if (
      data.notificationSetting !== null &&
      (data.notificationSetting?.alert_email_subscription === true ||
        data.notificationSetting?.kpi_email_subscription === true)
    ) {
      setHandleCloseNotificationEnableModal(true);
      setNotificationSettingData(data.notificationSetting);
      setArchiveKpiData(data);
    } else {
      setArchiveKpiData(null);
      resolveAlert(data, user, alertsCount);
      if (data.action === 'archive') {
        let message_data = data.message.replaceAll(`"tooltip"`, `"tooltip yesterday"`);
        setArchiveMsg({
          message: message_data,
          id: data.id,
          kpi_id: data.kpi_id,
          action: data.action,
        });
      }
    }
  };

  // Set timer for Archive action
  useEffect(() => {
    if (archiveMsg.action === 'archive') {
      clearTimeout(archiveTimeOut);
      const timeout = setTimeout(hideArchiveMessage, 10000, archiveMsg.id);
      setArchiveTimeOut(timeout);
    }
  }, [archiveMsg]);

  // Set timer for Resolve action
  useEffect(() => {
    if (resolvedMsg.message) {
      clearTimeout(resolveTimeOut);
      const timeout = setTimeout(hideAlertMessage, 10000, resolvedMsg.id);
      setResolveTimeOut(timeout);
    }
  }, [resolvedMsg]);

  // Close the popup and reset timer for Resolve action
  const undoAction = () => {
    setResolvedMsg({ message: '', id: null });
    clearTimeout(resolveTimeOut);
  };

  // Close the popup and reset timer for Archive action
  const undoArchiveAction = () => {
    setArchiveMsg({ message: '', id: null, kpi_id: '', action: '' });
    clearTimeout(archiveTimeOut);
  };

  // Callback function for navigae to other page and reset local state
  const navigatoTo = (page) => (e) => {
    e.preventDefault();
    undoAction();
    undoArchiveAction();
    navigate(page);
    return false;
  };
  // Functions
  // Convert text to html
  const createMarkup = (text) => {
    return { __html: text };
  };

  // Function for disable email alert and kpi subscription based on KPI
  const handleDisableNotificationKpi = () => {
    disableNotificationKPI({ userId: notificationSettingData.userId, kpi_id: notificationSettingData.kpi_id });
  };

  // Callback function for closed modal and alert move into the archive section
  useEffect(() => {
    if (!isEmpty(disableAlertEmailNotificationData)) {
      setHandleCloseNotificationEnableModal(false);
      setNotificationSettingData(null);
      archiveKpiData.notificationSetting = null;
      doArchiveAlert(archiveKpiData);
    }
  }, [disableAlertEmailNotificationData]);

  return (
    <>
      <NotificationSettingEnableModal
        open={handleCloseNotificationEnableModal}
        handleClose={() => setHandleCloseNotificationEnableModal(false)}
        handleDisableNotificationKpi={() => handleDisableNotificationKpi()}
      />
      <Grid
        xs={12}
        item
        spacing={0.5}
        container
        flexDirection='column'
        alignContent='space-around'
        className={`${user.persona.layoutId === 1 ? layoutClasses.mainContainer : layoutClasses.rsmMainContainer}`}
      >
        <Grid xs={10} item container className={classes.alertLayoutContainer}>
          {resolvedMsg.message && (
            <Grid item xs={12} className={classes.alertWarningMsgContainer}>
              <Grid item xs={10} className={classes.alertWarningMsg}>
                <div dangerouslySetInnerHTML={createMarkup(`${resolvedMsg.message} has been resolved.`)} />
                <Link
                  underline='none'
                  className={classes.action}
                  onClick={() => [updateStatus({ id: resolvedMsg.id, action: 'unResolved' }), undoAction()]}
                >
                  Undo
                </Link>
              </Grid>
              <Grid item xs={2} className={classes.alertWarningCloseIcon}>
                <Link underline='none' className={classes.action} onClick={undoAction}>
                  <img src={closeIcon} alt='close-icon' />
                </Link>
              </Grid>
            </Grid>
          )}
          {archiveMsg.message && archiveMsg.action === 'archive' && (
            <Grid item xs={12} className={classes.alertWarningMsgContainer}>
              <Grid item xs={10} className={classes.alertWarningMsg}>
                <div
                  dangerouslySetInnerHTML={createMarkup(
                    `"${archiveMsg.message}" alert has been sent to archived alerts.`,
                  )}
                />
                <Link
                  underline='none'
                  className={classes.action}
                  onClick={() => [
                    doArchiveAlert({ id: archiveMsg.id, kpi_id: archiveMsg.kpi_id, action: 'unArchive' }),
                    undoArchiveAction(),
                  ]}
                >
                  Undo
                </Link>
              </Grid>
              <Grid item xs={2} className={classes.alertWarningCloseIcon}>
                <Link underline='none' className={classes.action} onClick={undoArchiveAction}>
                  <img src={closeIcon} alt='close-icon' />
                </Link>
              </Grid>
            </Grid>
          )}
          {alerts && (
            <>
              <Grid item xs={12} container className={classes.alertContainer}>
                <Grid item xs={12} className={classes.todayContainer}>
                  <div className={classes.day}>
                    TODAY
                    <span style={{ color: '#06abe8', fontSize: '19px', marginLeft: '10px' }}>({alertLocation})</span>
                  </div>
                  <div className={classes.archiveContainer}>
                    <Link
                      href='/archiveAlerts'
                      key='archive'
                      underline='none'
                      sx={{ mr: 2 }}
                      onClick={navigatoTo('/archiveAlerts')}
                    >
                      Archived Alerts
                    </Link>
                  </div>
                </Grid>
                {todayAlerts.length !== 0 ? (
                  <>
                    <Grid item xs={12}>
                      {todayAlerts.map((alertdata) => (
                        <AlertsRow
                          key={alertdata.id}
                          todayAlert
                          alerts
                          persona_flag={alertdata.persona_flag}
                          createdAt={alertdata.createdAt}
                          updatedAt={alertdata.updatedAt}
                          alert_details={alertdata.alert_details}
                          id={alertdata.id}
                          kpi_id={alertdata.kpi_id}
                          location={alertdata.location}
                          resolved_at={alertdata.resolved_at}
                          resolved_by={alertdata.user}
                          resolved_status={alertdata.resolved_status}
                          updateStatus={updateStatus}
                          doArchiveAlert={doArchiveAlert}
                          kpi_alert_message={alertdata.kpi_alert_message}
                          notificationSetting={alertdata.notification_setting}
                        />
                      ))}
                    </Grid>
                  </>
                ) : (
                  <Grid item xs={12} container className={classes.noAlertData}>
                    No Alerts for Today
                  </Grid>
                )}
                <Grid item xs={12} style={{ marginTop: 30 }}>
                  <div className={classes.day}>
                    YESTERDAY
                    <span style={{ color: '#06abe8', fontSize: '19px', marginLeft: '10px' }}>({alertLocation})</span>
                  </div>
                </Grid>
                {yesterdayAlerts.length === 0 ? (
                  <Grid item xs={12} container className={classes.noAlertData}>
                    No Alerts for Yesterday
                  </Grid>
                ) : (
                  <>
                    <Grid item xs={12}>
                      {yesterdayAlerts?.map((alertdata) => (
                        <AlertsRow
                          key={alertdata.id}
                          {...alertdata}
                          yesterdayAlert
                          alerts
                          persona_flag={alertdata.persona_flag}
                          createdAt={alertdata.createdAt}
                          updatedAt={alertdata.updatedAt}
                          alert_details={alertdata.alert_details}
                          id={alertdata.id}
                          kpi_id={alertdata.kpi_id}
                          location={alertdata.location}
                          resolved_at={alertdata.resolved_at}
                          resolved_by={alertdata.user}
                          resolved_status={alertdata.resolved_status}
                          updateStatus={updateStatus}
                          doArchiveAlert={doArchiveAlert}
                          kpi_alert_message={alertdata.kpi_alert_message}
                        />
                      ))}
                    </Grid>
                  </>
                )}
              </Grid>
            </>
          )}
          {archiveAlerts && (
            <>
              <Grid item xs={12} className={classes.archiveBack}>
                <Link href='/alerts' key='archive' underline='none' sx={{ mr: 2 }} onClick={navigatoTo('/alerts')}>
                  <img src={leftOutlined} alt='close-icon' />
                  Archived Alerts
                </Link>
              </Grid>

              <Grid item xs={12} className={classes.alertContainer}>
                <Grid item xs={12}>
                  <div className={classes.day}>
                    TODAY
                    <span style={{ color: '#06abe8', fontSize: '19px', marginLeft: '10px' }}>({alertLocation})</span>
                  </div>
                </Grid>
                {archiveData?.map((alertdata) => (
                  <AlertsRow
                    key={alertdata.id}
                    todayAlert
                    archiveAlerts
                    persona_flag={alertdata.persona_flag}
                    createdAt={alertdata.createdAt}
                    updatedAt={alertdata.updatedAt}
                    alert_details={alertdata.alert_details}
                    id={alertdata.id}
                    kpi_id={alertdata.kpi_id}
                    location={alertdata.location}
                    resolved_at={alertdata.resolved_at}
                    resolved_by={alertdata.user}
                    resolved_status={alertdata.resolved_status}
                    updateStatus={updateStatus}
                    doArchiveAlert={doArchiveAlert}
                    kpi_alert_message={alertdata.kpi_alert_message}
                  />
                ))}
                {archiveData?.length === 0 && (
                  <Grid item xs={12} container className={classes.noAlertData}>
                    No Archived Alerts
                  </Grid>
                )}
              </Grid>
            </>
          )}
        </Grid>
      </Grid>
    </>
  );
};

export default memo(AlertsSection);
