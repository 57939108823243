/*
This component contains source code that defines page which will display Product details of an alert on click of Internal Hyperlink on the alerts.
*/
import React, { useState, useEffect, useContext } from 'react';
import Papa from 'papaparse';
import { useAppInsightsContext } from '@microsoft/applicationinsights-react-js';
import { useParams } from 'react-router-dom';
import { getAlertById } from '../../actions/alert';
import AuthContext from '../context/AuthContext';
import AlertDetailTabular from '../organisms/AlertDetailTabular';

const AlertDetails = (props) => {
  const { user } = useContext(AuthContext);
  const appInsights = useAppInsightsContext();

  const [rowData, setRowData] = useState();
  const [kpiName, setKpiName] = useState('');
  const [loading, setLoading] = useState(true);
  const [personaFlag, setPersonaFlag] = useState();
  const [messageFlag, setMessageFlag] = useState();
  const { showAlert } = props;
  const { alertId, location, message } = useParams();

  // Callback function for tracking the page
  useEffect(() => {
    if (user) {
      appInsights.trackPageView({
        uri: window.location.href,
        refUri: window.location.href,
        properties: {
          personaName: user.persona.name,
        },
      });
    }
  }, [user]);

  useEffect(() => {
    if (message === '1') {
      setMessageFlag(1);
    }
    if (message === '2') {
      setMessageFlag(2);
    } else {
      setMessageFlag(0);
    }
  }, [message]);

  // Callback function for get alert details and set into the local state
  useEffect(() => {
    if (user && alertId && messageFlag !== '' && messageFlag !== undefined) {
      const getAlertsData = async () => {
        const data = await getAlertById({ id: alertId, persona_flag: 1, message: messageFlag });
        if (data.error) {
          showAlert({
            alertMessage: data.message,
            alertType: 'error',
          });
          setLoading(false);
        } else {
          setKpiName(data.alert.kpi_mapping.KPI_GEN_NAME);
          Papa.parse(data.blobData, {
            header: true,
            skipEmptyLines: true,
            complete: function (results) {
              let locationBasedData = results.data.filter((data) => {
                const locationDescription = data.hasOwnProperty('Location Description')
                  ? data['Location Description']
                  : data.hasOwnProperty('Mixer Location Description')
                  ? data['Mixer Location Description']
                  : data['Department_Description'];
                const desiredLocation = location !== undefined ? location : user.location;
                return locationDescription === desiredLocation;
              });

              locationBasedData = locationBasedData.filter((data) =>
                data.hasOwnProperty('Country') && data.hasOwnProperty('Region')
                  ? data['Country'] == user.country && data['Region'] == user.region
                  : data,
              );

              let alertDetails = locationBasedData.map((v) => {
                delete v.CREATE_DATE;
                return v;
              });
              setRowData(alertDetails);
              setLoading(false);
              setPersonaFlag(data.alert.persona_flag);
            },
          });
        }
      };
      getAlertsData();
    }
  }, [user, alertId, messageFlag]);

  return (
    <>
      {loading === false && (
        <AlertDetailTabular
          personaFlag={personaFlag}
          alertPage={2}
          alertId={alertId}
          alertData={rowData}
          kpiName={kpiName}
          message={message}
        />
      )}
    </>
  );
};

export default AlertDetails;
