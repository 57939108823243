/*
This component contains source code that defines a rating or favoriting element.
It allows user to select/deselect KPI for marking it as their favorite KPI.
*/
import React, { memo } from 'react';

import { Rating } from '@mui/material';
import PropTypes from 'prop-types';

const RatingTag = (props) => {
  const { ratingPoint, ratingScale, tableStar, ratingClassName } = props;
  return tableStar ? (
    <Rating style={{ fontSize: '3.2rem' }} value={ratingPoint === true ? 1 : 0} max={ratingScale} readOnly></Rating>
  ) : (
    <Rating
      className={ratingClassName}
      style={{ fontSize: '2.2rem' }}
      value={ratingPoint === true ? 1 : 0}
      max={ratingScale}
      readOnly
    ></Rating>
  );
};

RatingTag.propTypes = {
  ratingPoint: PropTypes.bool,
  ratingScale: PropTypes.number,
  ratingClassName: PropTypes.string,
};

RatingTag.defaultProps = {
  ratingPoint: false,
  ratingScale: 1,
  ratingClassName: '',
};

export default memo(RatingTag);
