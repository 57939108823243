import React, { useState, useEffect, useContext } from 'react';

import { Grid, Link, Table, TableBody, Box, TableContainer, Typography } from '@mui/material';
import { isEmpty } from 'lodash';
import Papa from 'papaparse';
import { useNavigate } from 'react-router-dom';
import { utils, writeFile } from 'xlsx';

import config from '../../config';
import leftOutlined from '../assets/leftOutlined.svg';
import EnhancedTableHead from '../atom/EnhancedTableHead';
import Modal from '../atom/Modal';
import PowerBiReport from '../atom/PowerBiReport';
import ToolLinks from '../atom/ToolLinks';
import AuthContext from '../context/AuthContext';
import FilterContext from '../context/FilterContext';
import Filters from '../molecules/Filters';
import RSMPowerWidget from '../molecules/RSMPowerBiWidget';
import RSMTableRow from '../molecules/RSMTableRow';
import DashboardStyles from '../styles/DashboardStyles';
import LayoutStyles from '../styles/LayoutStyles';
import RSMDashboardDetailsStyle from '../styles/RSMDashboardDetailsStyle';
import { getExportDetailList } from '../../actions/home';

const RSMDashboardSection = (props) => {
  const { kpiId, FavoritePageButton, innerKpiId } = props;
  const { user, accessToken, getReportDetail, reportDetailList, unSetReportDetail, refreshTime, kpiToolLinks } =
    useContext(AuthContext);
  const filterContext = useContext(FilterContext);
  const { locationTypeData } = filterContext;
  const { reportUrl, centralId, newCountry, newLocation, newRegion, newLocationType } = config;
  const embedUrl = `${reportUrl}${centralId}`;
  const classes = RSMDashboardDetailsStyle();
  const [rowData, setRowData] = useState([]);
  const [tableList, setTableList] = useState([]);
  const [modalOpen, setModalOpen] = useState(false);
  const [tilePageName, setTilePageName] = useState(null);
  const [modalEmbedUrl, setModalEmbedUrl] = useState(null);
  const [kpiName, setKpiName] = useState('');
  const [headCells, setHeadCells] = useState([]);
  const [newHeading, setNewHeadCells] = useState([]);
  const [designFlag, setDesignFlag] = useState(1);
  const modalClasses = DashboardStyles();
  const layoutClasses = LayoutStyles();

  const [graphEmbedUrl, setGraphEmbedUrl] = useState('');
  const [graphPageName, setGraphPageName] = useState(null);
  const [cardPageName, setCardPageName] = useState(null);
  const [changedFilterState, setChangedFilterState] = useState({
    location: [],
    multipleLocationType: [],
    region: [],
    country: [],
  });
  const [region, setRegion] = useState([]);
  const [multipleLocationType, setMultipleLocationType] = useState([]);
  const [location, setLocation] = useState([]);
  const [selectedLocation, setSelectedLocation] = useState([]);
  const [defaultOrderBy, setDefaultOrderBy] = useState('');

  // Generate the PBI filter URL
  const createFilterURL = (locationFilter) => {
    let filters = '';
    let regionData = region.map((x) => `'${x}'`).join(',');
    let LocationTypeData = multipleLocationType.map((x) => `'${x}'`).join(',');
    filters = `&filter=${newCountry}country%20eq%20'${user?.country.trim()}'%20and%20${newRegion}region%20in%20(${regionData})%20and%20${newLocation}location_descp%20in%20(${locationFilter})%20and%20${newLocationType}location_type_description%20in%20(${LocationTypeData})`;
    return filters;
  };

  // Open the modal of PBI graph
  const onRowClick = (site) => (e) => {
    e.preventDefault();
    let filters = createFilterURL(`'${site}'`);
    let pbUrl = `${embedUrl}${filters}`;
    setModalEmbedUrl(pbUrl);
    setModalOpen(true);
  };

  // Close the modal of PBI graph
  const handleClose = () => {
    setModalOpen(false);
    setModalEmbedUrl(null);
  };

  const navigate = useNavigate();

  const [order, setOrder] = useState('asc');
  const [orderBy, setOrderBy] = useState(null);

  // Callback functions

  // Callback Function for reset the report list
  useEffect(() => {
    return () => {
      unSetReportDetail();
    };
  }, []);

  // Callback function for set PBI filters based on multiple location or location data
  useEffect(() => {
    if (user.id && !isEmpty(location) && !isEmpty(multipleLocationType) && kpiId) {
      setChangedFilterState({
        location: location,
        multipleLocationType: multipleLocationType,
        region: region,
        country: [user.country.trim()],
      });
      let pbUrl = `${embedUrl}`;
      setGraphEmbedUrl(pbUrl);
      getReportDetail(user.id, multipleLocationType, kpiId, location, region);
    }
  }, [multipleLocationType, location]);

  // Callback function for set the data for the tables based on location
  useEffect(() => {
    let orderByLabel = '';
    let orderById = '';
    if (!isEmpty(reportDetailList) && !isEmpty(reportDetailList.reportList)) {
      if (reportDetailList.reportList[0].Design_Flag === 2) {
        setDesignFlag(2);
        let kpi_data = JSON.parse(reportDetailList.reportList[0].kpi_details);
        const headings = kpi_data['Headings'].split(',');
        let headers = [
          {
            id: 'ranking',
            numeric: true,
            disablePadding: false,
            label: 'Ranking',
            sortable: true,
            align: 'left',
          },
          {
            id: 'site',
            numeric: false,
            disablePadding: false,
            label: 'Site',
            sortable: true,
            align: 'left',
          },
        ];
        let newHeadingStore = [];
        headings.map((val) => {
          newHeadingStore.push(val);
          headers.push({
            id: val,
            numeric: true,
            disablePadding: false,
            label: val.replaceAll('_', ' '),
            sortable: true,
            align: 'left',
          });
        });
        if (innerKpiId == 'MFG002') {
          headers = [
            {
              id: 'ranking',
              numeric: true,
              disablePadding: false,
              label: 'Ranking',
              sortable: true,
              align: 'left',
            },
            {
              id: 'site',
              numeric: false,
              disablePadding: false,
              label: 'Site',
              sortable: true,
              align: 'left',
            },
            {
              id: 'Current_Week_SB_Mix',
              numeric: true,
              disablePadding: false,
              label: 'Current Week SB Mix',
              sortable: true,
              align: 'left',
            },
          ];
        }
        setNewHeadCells(innerKpiId == 'MFG002' ? ['Current_Week_SB_Mix'] : newHeadingStore);
        setHeadCells(headers);
        orderByLabel = headers[2].label;
        orderById = headers[2].id;
      } else {
        let headers = [];
        if (innerKpiId == 'WAR008') {
          headers = [
            {
              id: 'ranking',
              numeric: true,
              disablePadding: false,
              label: 'Ranking',
              sortable: true,
              align: 'left',
            },
            {
              id: 'site',
              numeric: false,
              disablePadding: false,
              label: 'Site',
              sortable: true,
              align: 'left',
            },
            {
              id: 'last24Hrs',
              numeric: true,
              disablePadding: false,
              label: 'Current Day',
              sortable: true,
              align: 'left',
            },
            {
              id: 'last7days',
              numeric: true,
              disablePadding: false,
              label: 'Next 7 days',
              sortable: false,
              align: 'left',
            },
          ];
        } else if (innerKpiId == 'MFG003') {
          headers = [
            {
              id: 'ranking',
              numeric: true,
              disablePadding: false,
              label: 'Ranking',
              sortable: true,
              align: 'left',
            },
            {
              id: 'site',
              numeric: false,
              disablePadding: false,
              label: 'Site',
              sortable: true,
              align: 'left',
            },
            {
              id: 'last24Hrs',
              numeric: true,
              disablePadding: false,
              label: 'Last week',
              sortable: true,
              align: 'left',
            },
            {
              id: 'last7days',
              numeric: true,
              disablePadding: false,
              label: 'Last 7 weeks',
              sortable: false,
              align: 'left',
            },
          ];
        } else {
          headers = [
            {
              id: 'ranking',
              numeric: true,
              disablePadding: false,
              label: 'Ranking',
              sortable: true,
              align: 'left',
            },
            {
              id: 'site',
              numeric: false,
              disablePadding: false,
              label: 'Site',
              sortable: true,
              align: 'left',
            },
            {
              id: 'last24Hrs',
              numeric: true,
              disablePadding: false,
              label: 'Last 24hrs',
              sortable: true,
              align: 'left',
            },
            {
              id: 'wtd',
              numeric: true,
              disablePadding: false,
              label: 'WTD',
              sortable: true,
              align: 'left',
            },
            {
              id: 'last7days',
              numeric: true,
              disablePadding: false,
              label: 'Last 7 days',
              sortable: false,
              align: 'left',
            },
            {
              id: 'difference',
              numeric: true,
              disablePadding: false,
              label: '% Changes from WTD',
              sortable: true,
              align: 'left',
            },
          ];
        }
        setHeadCells(headers);
        orderByLabel = headers[2].label;
        orderById = headers[2].id;
      }
      let data = [];
      setKpiName(reportDetailList.kpiDetail.kpiName);
      const { pageName, graphPageName, cardPageName } = reportDetailList.kpiDetail.linked;
      setGraphPageName(graphPageName);
      setTilePageName(pageName);
      setCardPageName(cardPageName);
      if (reportDetailList.reportList[0].Design_Flag === 2) {
        let kpi_data = JSON.parse(reportDetailList.reportList[0].kpi_details);
        const headings = kpi_data['Headings'].split(',');
        reportDetailList.reportList.map((item, index) => {
          let kpi_data_value = JSON.parse(item.kpi_details);
          let newDesign = {};
          headings.map((val) => {
            const unit = kpi_data_value[`${val}_Unit`].value.includes('|')
              ? kpi_data_value[`${val}_Unit`].value.split('|')[0]
              : kpi_data_value[`${val}_Unit`].value;
            const k = kpi_data_value[`${val}_Unit`].value.includes('|K') && 'K';

            Object.assign(newDesign, { [val]: +kpi_data_value[val].value + k });
            Object.assign(newDesign, { [`${val}_Unit`]: unit });
          });
          data.push({
            site: item.location,
            ...newDesign,
          });
        });
      } else {
        reportDetailList.reportList.map((item, index) => {
          data.push({
            site: item.location,
            last24Hrs: +item.last24Hrs,
            last24HrsUnit: item.last24HrsUnit,
            wtd: +item.wtd,
            wtdUnit: item.wtdUnit,
            last7days: item.chart,
            days_desc: item.days_desc,
            difference: +item.difference,
            differenceUnit: item.differenceUnit,
            difference_arrowDirection: item.difference_arrowDirection,
            difference_color: item.difference_color,
            value_color: item.value_color,
            id: item.id,
          });
        });

        // sort the Data and add ranking based on the index
        let sortedData = handleSort(data, orderById);
        data = sortedData.map((item, index) => {
          return {
            ranking: index + 1,
            ...item,
          };
        });
        setTableList(data);
      }
      // sort the Data and add ranking based on the index
      let sortedData = handleSort(data, orderById);
      data = sortedData.map((item, index) => {
        return {
          ranking: index + 1,
          ...item,
        };
      });
      setRowData(data);
    } else {
      setRowData([]);
    }
    setDefaultOrderBy(orderByLabel);
  }, [reportDetailList]);

  // Functions

  // Function for sorting by asc of table data
  const descendingComparator = (a, b, orderBy) => {
    const A_Unit = a[`${orderBy}_Unit`] || a[`${orderBy}Unit`];
    const B_Unit = b[`${orderBy}_Unit`] || b[`${orderBy}Unit`];
    const A =
      typeof a[orderBy] === 'string' && a[orderBy].endsWith('K')
        ? parseFloat(a[orderBy].replace('K', '')) * 1000
        : A_Unit === 'K'
        ? a[orderBy] * 1000
        : a[orderBy];
    const B =
      typeof b[orderBy] === 'string' && b[orderBy].endsWith('K')
        ? parseFloat(b[orderBy].replace('K', '')) * 1000
        : B_Unit === 'K'
        ? b[orderBy] * 1000
        : b[orderBy];
    if (B < A) {
      return -1;
    }
    if (B > A) {
      return 1;
    }
    return 0;
  };

  // Function for sorting by desc of table data
  const getComparator = (order, orderBy) => {
    return order === 'desc'
      ? (a, b) => descendingComparator(a, b, orderBy)
      : (a, b) => -descendingComparator(a, b, orderBy);
  };

  // Function for sorting
  const stableSort = (array, comparator) => {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
      const order = comparator(a[0], b[0]);

      if (order !== 0) {
        return order;
      }
      return a[1] - b[1];
    });

    return stabilizedThis.map((el) => el[0]);
  };
  // Request for sorting
  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    let orderType = isAsc ? 'desc' : 'asc';

    setOrder(orderType);
    setOrderBy(property);
    let result = stableSort(rowData, getComparator(orderType, property));
    setRowData(result);
  };

  // Sorting before setting the data in the table and also export the data
  const handleSort = (data, orderBy) => {
    return stableSort(data, getComparator('desc', orderBy));
  };

  // Function for navigate to other page
  const navigateTo = (page) => (e) => {
    e.preventDefault();
    navigate(page);
    return false;
  };

  // Function for set region to local state
  const getRegion = (region) => {
    setRegion(region);
  };

  // Function for set multiple location type to local state
  const getMultipleLocationType = (locationType) => {
    setMultipleLocationType(locationType);
  };

  // Function for set location to local state
  const getLocation = (location) => {
    setLocation(location);
  };

  // Filter the export table with the selected location
  const filterExportData = (data, filterContext) => {
    let result = data.filter(
      (item) =>
        filterContext.location.includes(item['Site']) &&
        filterContext.multipleLocationType.includes(item['Location Type']) &&
        filterContext.region.includes(item['Region']) &&
        filterContext.country.includes(item['Country']),
    );
    return result;
  };

  // Function to export the view ranking table
  const onHandleExport = async () => {
    const data = await getExportDetailList(innerKpiId, 'Daily');
    Papa.parse(data.blobData, {
      header: true,
      skipEmptyLines: true,
      complete: function (results) {
        let locationBasedData = results.data;
        // Convert the values to their appropriate types
        let RankingData = locationBasedData.map((row) => {
          let convertedRow = {};
          Object.keys(row).forEach((key) => {
            let value = row[key];
            if (!isNaN(value) && value.trim() !== '') {
              convertedRow[key] = Number(value);
            } else {
              convertedRow[key] = value;
            }
          });
          delete convertedRow.CREATE_DATE;
          return convertedRow;
        });
        let tempExport = filterExportData(RankingData, changedFilterState);
        let sortColumn = '';
        if (tempExport.length > 0) {
          sortColumn = Object.keys(tempExport[0]).find(
            (key) => key.replace(/\s+/g, '').toLowerCase() === defaultOrderBy.replace(/\s+/g, '').toLowerCase(),
          );
        }
        tempExport = handleSort(tempExport, sortColumn);
        // iterate over tempExport and add ranking based on the index
        const exportResult = tempExport.map((item, index) => {
          return {
            Ranking: index + 1,
            ...item,
          };
        });
        const wb = utils.book_new();
        let ws_data = [
          ['Persona Name: ', user.persona.name],
          ['KPI Name', kpiName],
          ['Country: ', user.country],
          ['Region', ...region],
          ['LocationType', ...multipleLocationType],
          ['Location', ...location],
        ];
        ws_data.push([]);
        let arraylocal = [];
        for (let key in exportResult[0]) {
          if (exportResult[0].hasOwnProperty(key)) {
            arraylocal.push([key]);
          }
        }
        ws_data.push(arraylocal);
        exportResult.map((item) => {
          let arraylocal = [];
          for (let key in item) {
            if (item.hasOwnProperty(key)) {
              arraylocal.push([item[key]]);
            }
          }
          ws_data.push(arraylocal);
        });
        const ws = utils.aoa_to_sheet(ws_data);
        utils.book_append_sheet(wb, ws);
        let fileName = `Ranking_view_data`;
        writeFile(wb, `${fileName}.xlsx`);
      },
    });
  };

  const setLocationFilter = (location) => {
    setSelectedLocation(location);
  };

  useEffect(() => {
    if (tableList.length > 0) {
      let Result = tableList.filter((item) => selectedLocation.includes(item.site));
      setRowData(Result);
    }
    setChangedFilterState({
      location: selectedLocation,
      multipleLocationType: multipleLocationType,
      region: region,
      country: [user.country.trim()],
    });
  }, [selectedLocation]);

  return (
    <Grid container>
      <Grid item container className={layoutClasses.leftContainer}>
        <Filters
          user={user}
          countryLabel={true}
          regionMultipleDropDown={true}
          locationTypeMultipleDropDown={true}
          locationMultipleDropDown={true}
          getRegion={getRegion}
          getMultipleLocationType={getMultipleLocationType}
          getLocation={getLocation}
          setLocationFilter={setLocationFilter}
          rsmDailyViewLocationTypeData={locationTypeData}
          dailyViewRefreshTime={true}
          refreshTime={refreshTime}
          reportId={kpiId}
          innerKpiId={innerKpiId}
        />
      </Grid>
      <Grid item container className={layoutClasses.rsmRightContainer}>
        <Grid
          xs={12}
          item
          spacing={0.5}
          container
          flexDirection='column'
          alignContent='space-around'
          className={classes.layoutMainContainer}
        >
          <Grid xs={12} item container className={classes.layoutContainer}>
            <Grid item xs={12} className={classes.backButton}>
              {FavoritePageButton ? (
                <Link
                  href='/favorites/dailyView'
                  key='favoritepage'
                  underline='none'
                  sx={{ mr: 2 }}
                  onClick={navigateTo('/favorites/dailyView')}
                >
                  <img src={leftOutlined} alt='close-icon' />
                  {kpiName}
                </Link>
              ) : (
                <Link href='/home' key='home' underline='none' sx={{ mr: 2 }} onClick={navigateTo('/home')}>
                  <img src={leftOutlined} alt='close-icon' />
                  {kpiName}
                </Link>
              )}
            </Grid>
            <Grid item xs={12} container spacing={3} className={classes.widgetLayout}>
              <Grid item xl={2} md={2} xs={12} className={classes.widgetLayoutFirst}>
                {cardPageName && (
                  <RSMPowerWidget
                    accessToken={accessToken}
                    tileId={centralId}
                    KpiId={innerKpiId}
                    embedUrl={graphEmbedUrl}
                    pageName={cardPageName}
                    country={changedFilterState.country}
                    region={changedFilterState.region}
                    location={changedFilterState.location}
                    multipleLocationType={changedFilterState.multipleLocationType}
                  />
                )}
              </Grid>
              <Grid item xl={10} md={10} xs={12} className={classes.widgetLayoutSecond}>
                {graphPageName && (
                  <RSMPowerWidget
                    accessToken={accessToken}
                    tileId={centralId}
                    embedUrl={graphEmbedUrl}
                    KpiId={innerKpiId}
                    pageName={graphPageName}
                    country={changedFilterState.country}
                    region={changedFilterState.region}
                    location={changedFilterState.location}
                    multipleLocationType={changedFilterState.multipleLocationType}
                  />
                )}
              </Grid>

              <Grid item xl={12} md={12} xs={12} container>
                <Grid item xl={12} md={12} xs={12} className={classes.tableContainer}>
                  <TableContainer className={classes.table} sx={{ maxHeight: 446 }}>
                    <Table stickyHeader>
                      <EnhancedTableHead
                        headCells={headCells}
                        onHandleExport={onHandleExport}
                        order={order}
                        orderBy={orderBy}
                        onRequestSort={handleRequestSort}
                        size='small'
                      />
                      <TableBody>
                        {rowData.map((row, index) => (
                          <RSMTableRow
                            key={row.id}
                            size='small'
                            innerKpiId={innerKpiId}
                            designFlag={designFlag}
                            newHeading={newHeading}
                            row={row}
                            onRowClick={onRowClick(row.site)}
                          />
                        ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </Grid>
                <Grid item xl={12} md={12} xs={12} container>
                  <Grid item xl={12} md={12} xs={12}>
                    <ToolLinks kpiToolLinks={kpiToolLinks} />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>

        <Modal open={modalOpen} handleClose={handleClose}>
          <Box className={modalClasses.boxStyle}>
            <PowerBiReport
              accessToken={accessToken}
              id={centralId}
              KpiId={innerKpiId}
              embedUrl={modalEmbedUrl}
              pageName={tilePageName}
              country={changedFilterState.country}
              region={changedFilterState.region}
              location={changedFilterState.location}
              multipleLocationType={changedFilterState.multipleLocationType}
            />
          </Box>
        </Modal>
      </Grid>
    </Grid>
  );
};

export default RSMDashboardSection;
