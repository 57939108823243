import { makeStyles } from '@mui/styles';
const AlertsStyles = makeStyles(() => ({
  alertLayoutContainer: {
    padding: '12px',
    display: 'block',
    marginTop: 24,
  },
  alertWarningMsgContainer: {
    display: 'flex',
    fontSize: 12,
    marginBottom: 20,
    color: '#007BFF',
    marginTop: -32,
    padding: '12px 20px',
    backgroundColor: '#fff4e6',
    boxShadow: '0px 1px 6px 0px rgb(0 0 0 / 50%)',
  },
  alertWarningMsg: {
    fontSize: '1rem',
    color: '#111112',
    fontWeight: 'bold',
    display: 'flex',
    gap: 6,
    '& div': {
      '& a': {
        pointerEvents: 'none',
        color: '#111112',
        textDecoration: 'none',
      },
    },
  },
  alertRowDisplay: {
    display: 'none',
  },
  alertPage: {
    display: 'block',
  },
  alertWarningCloseIcon: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  todayContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  archiveContainer: {
    fontSize: '1.4rem',
    color: '#007BFF',
  },

  archiveBack: {
    display: 'flex',
    justifyContent: 'flex-start',
    fontSize: '1.5rem',
    marginBottom: 20,
    fontWeight: 'bold',
    '& a': {
      display: 'flex',
      color: '#011F36',
      gap: 10,
      '& img': {
        width: 7,
      },
    },
  },
  alertContainer: {
    backgroundColor: '#ffffff',
    borderRadius: 4,
    alignContent: 'flex-start',
    padding: 4,
    gap: 20,
  },
  day: {
    padding: 10,
    fontSize: '1.4rem',
    color: '#545C63',
  },
  alertRow: {
    borderBottom: '1px solid #E9ECEF',
    padding: 12,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  alertMessageGroup: {
    gap: 20,
    display: 'flex',
    alignItems: 'center',
  },
  noAlertData: {
    padding: 12,
    fontSize: '1.6rem',
    color: '#545C63',
    display: 'flex',
    justifyContent: 'center',
    opacity: 0.5,
  },
  alertMessage: {
    display: 'flex',
    gap: 10,
  },
  alertMessagePrimary: {
    fontSize: '1.4rem',
    color: (props) => (props.day ? '#535353' : '#111112'),
    '& div': {
      '& a': {
        color: '#1976d2',
        cursor: 'pointer',
      },
    },
  },
  alertMessagePrimaryYesterday: {
    fontSize: '1.4rem',
    '& div': {
      '& a': {
        pointerEvents: 'none',
        color: 'rgb(83, 83, 83)',
        textDecoration: 'none',
      },
    },
  },
  alertAction: {
    display: 'flex',
    justifyContent: 'flex-end',
    fontSize: '1.2rem',
    gap: 18,
    '& .MuiLink-root': {
      color: (props) => (props.day ? '#444444' : '#007BFF'),
      opacity: (props) => (props.day ? 0.5 : 1),
      pointerEvents: (props) => (props.day ? 'none' : 'inherit'),
    },
    '& span': {
      color: '#444444',
      opacity: 0.5,
    },
  },
  alertMessageSecondary: {
    color: '#111112',
    opacity: 0.5,
    fontSize: '1.2rem',
  },
  actionDevider: {
    borderColor: '#c3c3c3',
    margin: 0,
  },
  iconChange: {
    width: 46,
    height: 46,
  },
  action: {
    cursor: 'pointer',
    height: '0.8rem',
  },
  hideCloseIcon: {
    visibility: 'hidden',
  },
  boxStyle: {
    position: 'absolute',
    top: '55%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    display: 'flex',
    height: 'calc(100vh - 60px)',
    width: 'calc(100vw - 170px)',
    background: '#FAFAFA',
    border: '2px solid #000',
    // overflow: 'scroll',
    '@media screen and (min-width:1200px)': {
      zoom: '144%',
    },
    '@media screen and (min-width:600px)': {
      zoom: '184%',
    },
    '@media screen and (min-width:700px)': {
      zoom: '184%',
    },
    '@media screen and (min-width:900px)': {
      zoom: '166%',
    },
    '@media screen and (min-width:1300px)': {
      zoom: '129%',
    },
    '@media screen and (min-width:1500px)': {
      zoom: '125%',
    },
    '@media screen and (min-width:1600px)': {
      zoom: '122%',
    },
    '@media screen and (min-width:1700px)': {
      zoom: '120%',
    },
    '@media screen and (min-width:1800px)': {
      zoom: '97%',
    },
  },
}));

export default AlertsStyles;
