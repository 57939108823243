import { makeStyles } from '@mui/styles';
const TabularTabStyle = makeStyles(() => ({
  tableRows: {
    height: 120,
    borderBottom: '1px solid rgba(224, 224, 224, 1)',
    '& .MuiTableCell-root:first-child': {
      paddingLeft: 50,
    },
    '& td': {
      paddingTop: 40,
      paddingBottom: 10,
      height: 'inherit',
    },
    '&:hover': {
      borderBottom: '1px solid rgb(38, 105, 169)',
    },
    '&.MuiTableRow-hover': {
      '&:hover': {
        '& .MuiTableCell-root': {
          borderBottom: '1px solid rgb(38, 105, 169)',
        },
        backgroundColor: 'transparent',
        cursor: 'pointer',
      },
    },
  },
  kpiNameSection: {
    position: 'relative',
    height: 'inherit',
    display: 'flex',
    flexDirection: 'column',
    align: 'center',
    justifyContent: 'space-between',
    fontSize: '1.4rem',
  },

  kpiName: {
    fontSize: '1.8rem',
    fontWeight: 500,
  },

  daily: {
    fontStyle: 'italic',
    fontSize: '1.6rem',
  },

  number: {
    fontWeight: 'bold',
    fontSize: '1.8rem',
    justifyContent: 'space-between',
  },

  wtd: {
    height: 'inherit',
    display: 'flex',
    flexDirection: 'column',
    align: 'center',
    justifyContent: 'space-between',
    fontSize: '1.4rem',
  },
}));

export default TabularTabStyle;
