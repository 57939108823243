import React, { useState, useEffect, useRef } from 'react';
import { AgChartsReact } from 'ag-charts-react';

const LineChartHistoricalView = (props) => {
  const { lineChartData, kpiUnit, activeTab, kpiName, getLineChartWidth } = props;
  const [options, setOptions] = useState({});
  const windowSize = useRef(window.innerWidth);
  // Set the line chart data for the graph

  const calcWidth = (firstTab, secondTab) => {
    getLineChartWidth(firstTab);
    return (activeTab === 0 ? firstTab : secondTab) * (lineChartData.length > 18 ? lineChartData.length : 18);
  };
  useEffect(() => {
    let width = calcWidth(68, 64);
    let height = 196;
    let axisLableFontSize = 10;
    let lablePointFontSize = 11;
    let paddingRight = 40;
    let paddingBottom = 10;
    switch (true) {
      case windowSize.current >= 1920:
        width = calcWidth(90, 90);
        height = 300;
        axisLableFontSize = 11;
        lablePointFontSize = 12;
        break;
      case windowSize.current >= 1680:
        width = calcWidth(78, 78);
        height = 270;
        axisLableFontSize = 11;
        lablePointFontSize = 12;
        break;
      case windowSize.current >= 1600:
        width = calcWidth(74, 74);
        height = 240;
        axisLableFontSize = 10;
        lablePointFontSize = 12;
        break;
      case windowSize.current >= 1440:
        width = calcWidth(68, 68);
        height = 250;
        axisLableFontSize = 9;
        lablePointFontSize = 10;
        break;
      case windowSize.current >= 1400:
        width = calcWidth(66, 66);
        height = 255;
        axisLableFontSize = 9;
        lablePointFontSize = 12;
        break;
      case windowSize.current >= 1366:
        width = calcWidth(64, 64);
        height = 185;
        axisLableFontSize = 9;
        lablePointFontSize = 11;
        break;
      case windowSize.current >= 1360:
        width = calcWidth(60, 64);
        height = 185;
        axisLableFontSize = 9;
        lablePointFontSize = 11;
        break;
      case windowSize.current >= 1280:
        width = calcWidth(60, 60);
        height = 195;
        axisLableFontSize = 9;
        lablePointFontSize = 11;
        break;
      case windowSize.current >= 1152:
        width = calcWidth(60, 54);
        height = 245;
        axisLableFontSize = 9;
        lablePointFontSize = 11;
        break;
      case windowSize.current >= 1024:
        width = calcWidth(60, 48);
        height = 195;
        axisLableFontSize = 9;
        lablePointFontSize = 11;
        break;
      case windowSize.current >= 800:
        width = calcWidth(60, 37);
        height = 165;
        axisLableFontSize = 8;
        lablePointFontSize = 9;
        break;
    }
    const tooltipRenderer = (params) => {
      return (
        '<div class="ag-chart-tooltip-title" style="background-color:' +
        params.color +
        '">' +
        params.title +
        '</div>' +
        '<div class="ag-chart-tooltip-content">' +
        params.xValue +
        ':  ' +
        params.yValue +
        kpiUnit +
        '</div>'
      );
    };
    setOptions({
      width: width,
      height: height,
      padding: {
        right: paddingRight,
        top: 20,
        bottom: paddingBottom,
      },
      data: lineChartData,
      series: [
        {
          xKey: 'time_series',
          yKey: 'kpi_value',
          yName: kpiName,
          stroke: '#003668',
          tooltip: { renderer: tooltipRenderer },
          label: {
            formatter: (params) => {
              return params.value + kpiUnit;
            },
            fontWeight: 'bold',
            color: '#004D8A',
            fontSize: lablePointFontSize,
          },
          marker: {
            fill: '#004D8A',
            stroke: '#003668',
          },
        },
        {
          xKey: 'time_series',
          yKey: 'national_avg',
          yName: 'National Avg',
          stroke: '#003668',
          tooltip: { renderer: tooltipRenderer },
          lineDash: [1, 3],
          marker: {
            fill: '#F7921D',
            stroke: '#003668',
          },
        },
        {
          xKey: 'time_series',
          yKey: 'target',
          yName: 'Target',
          stroke: '#F7921D',
          tooltip: { renderer: tooltipRenderer },
          lineDash: [1, 3],
          marker: {
            fill: '#F7921D',
            stroke: '#F7921D',
          },
        },
      ],

      axes: [
        {
          type: 'category',
          position: 'bottom',
          tick: {
            minSpacing: 10,
          },
          label: {
            formatter: (params) => {
              return params.value;
            },
            fontSize: axisLableFontSize,
            fontWeight: '500',
          },
        },
        {
          type: 'number',
          position: 'left',
          tick: {
            count: 4,
          },
          label: {
            formatter: (params) => {
              return params.value + kpiUnit;
            },
            fontSize: axisLableFontSize,
          },
        },
      ],
      legend: {
        enabled: false,
      },
      tooltip: { enabled: true },
    });
  }, [lineChartData]);

  return (
    <div style={{ width: '0px' }}>
      <AgChartsReact options={options} />
    </div>
  );
};

LineChartHistoricalView.propTypes = {};

export default LineChartHistoricalView;
