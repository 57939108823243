/*
This component contains source code that creates a search element.
It allows user to search by text and search text will be hightlighting with some color in the page.
*/
import React from 'react';
import { useEffect } from 'react';

import SearchIcon from '@mui/icons-material/Search';
import { InputBase } from '@mui/material';

import SearchStyles from '../styles/SearchStyles';

const Search = (props) => {
  const classes = SearchStyles();
  const { key, onChange, onClose } = props;

  // Callback function for close the search
  useEffect(() => {
    return () => onClose && onClose();
  }, []);

  return (
    <div key={key} className={classes.container}>
      <SearchIcon color='disabled' fontSize='inherit' />
      <InputBase className={classes.input} onChange={onChange} placeholder='Search'></InputBase>
    </div>
  );
};

export default Search;
