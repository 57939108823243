import React from 'react';
import HighlightXpathStyles from '../styles/HighlightXpathStyles';

const HighlightXpath = (props) => {
  const classes = HighlightXpathStyles();
  /**
   * This creates the regex to find the wanted `quote`.
   * If you want to highlight all the occurances of a `quote`, not
   * only the first occurance, add 'g' as the second parameter:
   * ex: const regex = new RegExp(`(${props.quote})`);
   * If you want to highlight multiple quotes from an array
   * you could do
   * const regex = new RegExp(`(${props.quotes.join('|')})`);
   */
  //   const regex = new RegExp(`(${props.quote})`);
  const regex = new RegExp(`(${props.quote})`);
  /**
   * In `content` we wrap `quote`'s occurance(s) in `em`s
   * with a class `highlighed`. Please note that this will
   * be rendered as html, not React, so `class` is used instead
   * of `className`.
   */
  const highlightedHtml = props.content.replace(regex, `<span class=${classes.highlighted}>$1</span>`);

  return <div dangerouslySetInnerHTML={{ __html: highlightedHtml }} />;
};

export default HighlightXpath;
