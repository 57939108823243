import { makeStyles } from '@mui/styles';

const ExportDrawerStyles = makeStyles(() => ({
  headerContainer: {
    backgroundColor: '#F8F8F8',
    padding: 26,
  },
  closeButton: {
    cursor: 'pointer',
    background: '#E0E0E0',
    opacity: 1,
    borderRadius: '50%',
    padding: '8px 8px 5px 8px',
    marginTop: '0px',
  },
  dropdown: {
    marginTop: 2,
    '& .MuiOutlinedInput-root .MuiAutocomplete-tag': {
      margin: 0,
      marginRight: 2,
      height: 22,
      display: 'inline-flex',
      alignItems: 'center',
    },
    '& .MuiOutlinedInput-root': {
      color: '#E3E3E3',
      border: '1px solid #E3E3E3',
      paddingLeft: 4,
      borderRadius: '5px',
      '& .MuiAutocomplete-input': {
        fontSize: '1.2rem',
        color: ' #3D4043',
        margin: '2px',
      },

      '& fieldset': {
        fontSize: '1rem',
        border: 'none',
        borderColor: 'rgba(0, 0, 0, 0.23)',
      },
    },
    '& .MuiAutocomplete-hasClearIcon': {
      fontSize: '20px',
    },
    '& .MuiInput-root:after': {
      borderBottom: '1px solid inherit',
    },
    '& .MuiInput-root::hover:not(.Mui-disabled):before': {
      borderBottom: '1px solid inherit',
    },
  },
  printFile: {
    font: '600 normal normal 28px Segoe UI',
    color: '#3D4043',
  },
  rightIconGrid: {
    display: 'flex',
    justifyContent: 'right',
  },
  kpiSelectContainerDiv: {
    paddingTop: 26,
  },
  kpiSelectContainer: {
    padding: '0px 26px 0px 26px',
  },
  buttonContainer: {
    display: 'flex',
    justifyContent: 'center',
    paddingTop: '220px',
  },
  saveButton: {
    textTransform: 'capitalize',
    color: '#fff',
    fontSize: '1.2rem',
    margin: '0px 26px 0px 26px',
  },
  SwitchIconGrid: {
    padding: '0px 24px 18px 26px',
  },
  secondHeading: {
    font: 'normal normal normal 21px Segoe UI',
    letterSpacing: '0px',
    marginBottom: '10px',
    color: '#3D4043',
    opacity: 1,
  },
  thirdHeading: {
    color: 'rgb(25, 118, 210)',
    marginTop: '5px',
  },
  typeofFileButtonGroup: {
    padding: '0px 26px 0px 26px',
  },
  activeButton: {
    display: 'flex',
    justifyContent: 'center',
    cursor: 'pointer',
    backgroundColor: 'E5E5FF',
    border: '1px solid rgb(25, 118, 210)',
  },
  nonactiveButton: {
    display: 'flex',
    justifyContent: 'center',
    cursor: 'pointer',
    border: '1px solid #E3E3E3',
  },
  activeText: {
    font: 'normal normal normal 21px Segoe UI',
    letterSpacing: '0px',
    color: 'rgb(25, 118, 210)',
    opacity: 1,
    padding: 5,
  },
  nonactiveText: {
    font: 'normal normal normal 21px Segoe UI',
    letterSpacing: '0px',
    marginBottom: '10px',
    color: '#3D4043',
    opacity: 1,
    padding: 5,
  },
}));

export default ExportDrawerStyles;
