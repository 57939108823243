import { makeStyles } from '@mui/styles';

const ResourceLibraryStyles = makeStyles(() => ({
  modal: {
    color: 'white',
  },
  container: {
    position: 'absolute',
    top: '10%',
    left: '10%',
    width: '80%',
    height: '75%',
  },
  closeIcon: {
    width: '30px',
    height: '30px',
    borderRadius: 5,
    backgroundColor: '#EDEDED',
    position: 'absolute',
    left: '96%',
    marginLeft: '30px',
    cursor: 'pointer',
  },
  tabs: {
    backgroundColor: 'white',
    color: 'black',

    borderTopLeftRadius: 5,
    borderTopRightRadius: 5,
  },
  tab: {
    fontSize: '20px',
    paddingTop: 15,
    paddingBottom: 15,
    borderBottomWidth: 5,
    borderBottomColor: '#EDEDED',
    borderBottomStyle: 'solid',
  },
  swipableViews: {
    backgroundColor: 'white',
    height: '100%',

    borderBottomLeftRadius: 5,
    borderBottomRightRadius: 5,
  },
  tabPanelContainer: {
    margin: 'auto',
    width: '95%',
  },
  dashboardList: {
    display: 'flex',
    flexDirection: 'column',
  },
  title: {
    fontSize: 18,
    color: '#004C97',
    marginTop: 18,
    marginBottom: 18,
    fontWeight: 'normal',
  },
  contentLinks: {
    fontSize: '15px',
    marginTop: '3px',
    marginBottom: '8px',
  },
  contentHeading: {
    color: '#004C97',
    fontSize: '16px',
    paddingRight: '5px',
    margin: '3px 0px 8px -5px',
  },
}));
export default ResourceLibraryStyles;
