/*
This component contains source code that defines an Drop down element.
It takes json data and returns an drop element with values in the drop down element that can be displayed on the screen.
*/

import React, { useState } from 'react';

import { Autocomplete, TextField, Typography, Paper, Popper } from '@mui/material';
import { makeStyles } from '@mui/styles';
import PropTypes from 'prop-types';

// Function to comtomize the list of dropdown as styles
const CustomPaper = (props) => {
  return <Paper style={{ backgroundColor: 'rgb(243, 248, 255)', border: '1px solid #eaeaea' }} {...props} />;
};

// Function to apply css
const useStyles = makeStyles((theme) => ({
  autoComplete: {
    marginTop: '-10px',
    width: 250,
    color: '#004D8A',
    font: 'normal normal normal 12px Segoe UI',
    '& .MuiOutlinedInput-root': {
      color: '#525A63',
      backgroundColor: 'rgb(243, 248, 255)',
      border: '1px solid #eaeaea',
      '& fieldset': {
        border: 'none',
        borderColor: 'rgba(0, 0, 0, 0.23)',
      },
    },
    '& .MuiAutocomplete-hasClearIcon': {
      fontSize: '20px',
    },
  },
  labelStyle: {
    [theme.breakpoints.down('sm')]: {
      font: 'normal normal normal 18px Segoe UI',
    },
    color: (props) => (props.labelDisabled ? '#BBBCBC' : '#1C1C1C'),
    font: 'normal normal normal 20px Segoe UI',
    marginBottom: 10,
    display: 'inline-block',
  },
  textField: {
    fontSizeAdjust: '',
    '& .MuiAutocomplete-input': {
      [theme.breakpoints.down('sm')]: {
        fontSize: '16px',
        height: '12px',
      },
      fontSize: '20px',
      height: '14px',
    },
  },
  smallFont: {
    fontSize: '20px',
  },
  root: {
    top: (props) => `${props.popperbottom}px !important`,
    zIndex: '9999 !important',
    transform: 'none !important',
  },
}));

// Function to reset placing of open dropdown list into bottom
const CustomPopper = function (props, popperbottom) {
  const classes = useStyles({ popperbottom: popperbottom });
  return <Popper {...props} className={classes.root} />;
};
const Dropdown = (props) => {
  const { label, options, onChange, disabled } = props;
  const classes = useStyles({ labelDisabled: disabled });
  const [open, setOpen] = useState(false);
  // Function to open/close dropndown
  const handleOpen = () => {
    setOpen(!open);
  };

  return (
    <div>
      {label && (
        <Typography variant='body1' component='label' className={classes.labelStyle}>
          {label}
        </Typography>
      )}
      <div style={{ position: 'relative' }}>
        <Autocomplete
          id='auto-complete'
          options={options}
          open={open}
          onOpen={handleOpen}
          onClose={handleOpen}
          className={classes.autoComplete}
          onChange={onChange}
          disableClearable
          PaperComponent={CustomPaper}
          disablePortal={true}
          renderInput={(params) => <TextField className={classes.textField} {...params} />}
          classes={{ option: classes.smallFont }}
          {...props}
          PopperComponent={(e) => CustomPopper(e, props.popperbottom)}
        />
      </div>
    </div>
  );
};

Dropdown.propTypes = {
  options: PropTypes.arrayOf(PropTypes.object),
  label: PropTypes.string,
  onChange: PropTypes.func,
};
export default Dropdown;
