/*
This component contains source code that defines a contact us form in the Help page.
*/
import React, { useContext, useEffect, useState } from 'react';

import AttachFileIcon from '@mui/icons-material/AttachFile';
import CancelIcon from '@mui/icons-material/Cancel';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CloseIcon from '@mui/icons-material/Close';
import { Button, CircularProgress, TextField, Typography, Zoom } from '@mui/material';
import { Box } from '@mui/system';
import { v4 as uuid } from 'uuid';
import isEmail from 'validator/lib/isEmail';

import FormAttachment from '../atom/FormAttachment';
import Modal from '../atom/Modal';
import AuthContext from '../context/AuthContext';
import ContactUsModalStyles from '../styles/ContactUsModalStyles';

import request from '../../helper/apiRequest';

const ContactUsModal = (props) => {
  const { open, handleClose } = props;
  const classes = ContactUsModalStyles();
  const { user } = useContext(AuthContext);

  const [name, setName] = useState('');
  const [nameHelperText, setNameHelperText] = useState('');
  const [email, setEmail] = useState('');
  const [emailHelperText, setEmailHelperText] = useState('');
  const [issue, setIssue] = useState('');
  const [issueHelperText, setIssueHelperText] = useState('');
  const [attachments, setAttachments] = useState([]);
  const [loadingIcon, setLoadingIcon] = useState(false);
  const [successIcon, setSuccessIcon] = useState(false);
  const [failureIcon, setFailureIcon] = useState(false);

  // Function for reset local state
  const resetFields = () => {
    setName(user?.fullName);
    setNameHelperText('');
    setEmail(user?.email);
    setEmailHelperText('');
    setIssue('');
    setIssueHelperText('');
    setAttachments([]);
    setLoadingIcon(false);
    setSuccessIcon(false);
    setFailureIcon(false);
  };

  // Callback function for set local state of name and email
  useEffect(() => {
    setName(user?.fullName);
    setEmail(user?.email);
  }, [user]);

  // Callback function for remove the faliure icon
  useEffect(() => {
    failureIcon && setFailureIcon(false); // Removing the failure icon whenever there is a change in the files to not confure the user.
  }, [attachments]);

  // Function for add files to attachment
  const handleAttachmentsChange = async (event) => {
    const { files } = event.target;

    if (files?.length !== 0) {
      const newAttachments = Object.keys(files).map((index) => {
        const newAttachment = files[index];
        newAttachment.uuid = uuid();

        return newAttachment;
      });

      setAttachments([...attachments, ...newAttachments]);
    }
  };

  // Function for validation
  const formFieldsAreValid = () => {
    let fieldsAreValid = true;

    if (name.length === 0 || name === undefined) {
      setNameHelperText('A name is required');
      fieldsAreValid = false;
    }

    if (email.length === 0 || email === undefined) {
      setEmailHelperText('Your email is required');
      fieldsAreValid = false;
    } else if (!isEmail(email)) {
      setEmailHelperText('Email is not valid');
      fieldsAreValid = false;
    }

    if (issue.length === 0 || issue === undefined) {
      setIssueHelperText('Describe your issue');
      fieldsAreValid = false;
    }

    return fieldsAreValid;
  };

  // Function for display success icon
  const handleSuccessEmailCall = () => {
    setTimeout(() => {
      setLoadingIcon(false);
      setSuccessIcon(true);
      setTimeout(() => {
        onClose();
      }, 2000);
    }, 2000);
  };
  // Function for display failure icon
  const handleFailureEmailCall = () => {
    setTimeout(() => {
      setLoadingIcon(false);
      setFailureIcon(true);
    }, 2000);
  };

  // Function for append form data and send to api
  const handleSendData = async () => {
    // Setting failureIcon to false in case the user tries to end data several times
    failureIcon && setFailureIcon(false);

    if (formFieldsAreValid()) {
      setLoadingIcon(true);
      let fileData = new FormData();
      attachments.map((item) => {
        fileData.append('file', item);
      });
      fileData.append('name', name);
      fileData.append('email', email);
      fileData.append('issueDetails', issue);
      fileData.append('userId', user.id);

      // Calling  API endpoint
      try {
        const data = await request.post('/help/saveHelpRequest', {}, fileData);
        // return data;
        handleSuccessEmailCall();
      } catch (e) {
        handleFailureEmailCall();
      }
    }
  };

  // Function for remove attachment
  const removeAttachment = (uuid) => {
    const newAttachments = attachments.filter((attachment) => attachment.uuid !== uuid);
    setAttachments(newAttachments);
  };
  // Function for close contact us modal and reset field
  const onClose = () => {
    resetFields();
    handleClose();
  };

  // Function for render html of status icon
  const StatusIcons = () => (
    <div>
      {loadingIcon && (
        <Zoom in={loadingIcon}>
          <Box sx={{ display: 'flex', margin: 3 }}>
            <CircularProgress size={80} />
          </Box>
        </Zoom>
      )}
      {successIcon && (
        <Zoom in={successIcon}>
          <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', margin: 3 }}>
            <CheckCircleIcon
              sx={{
                fontSize: 90,
                color: '#34C759',
              }}
            />
            <Typography className={classes.success}>Success!</Typography>
          </Box>
        </Zoom>
      )}
      {failureIcon && (
        <Zoom in={failureIcon}>
          <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', margin: 3 }}>
            <CancelIcon
              sx={{
                fontSize: 90,
                color: '#FF3B30',
              }}
            />
            <Typography className={classes.failure}>Something went wrong. Try again later.</Typography>
          </Box>
        </Zoom>
      )}
    </div>
  );

  return (
    <Modal
      open={open}
      handleClose={onClose}
      sx={{
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
        outline: 'none',
      }}
    >
      <Box className={classes.content}>
        <div className={classes.closeButton} onClick={onClose}>
          <CloseIcon />
        </div>
        <Typography className={classes.title}>Get in touch</Typography>

        <div className={classes.fieldContainer}>
          <Typography className={classes.fieldTitle}>Enter your name</Typography>
          <TextField
            label='Required'
            value={name}
            onChange={(event) => {
              setName(event.target.value);
              if (nameHelperText.length !== 0) {
                setNameHelperText('');
              }
            }}
            className={classes.input}
            required
            error={nameHelperText.length !== 0}
            helperText={nameHelperText}
            inputProps={{
              readOnly: true,
            }}
          />
        </div>

        <div className={classes.fieldContainer}>
          <Typography className={classes.fieldTitle}>Enter your email</Typography>
          <TextField
            label='Required'
            value={email}
            onChange={(event) => {
              setEmail(event.target.value);
              if (emailHelperText.length !== 0) {
                setEmailHelperText('');
              }
            }}
            className={classes.input}
            required
            error={emailHelperText.length !== 0}
            helperText={emailHelperText}
            inputProps={{
              readOnly: true,
            }}
          ></TextField>
        </div>

        <div className={classes.fieldContainer}>
          <Typography className={classes.fieldTitle}>Describe your issue</Typography>
          <TextField
            label='Required'
            multiline
            maxRows={6}
            required
            className={classes.input}
            onChange={(event) => {
              setIssue(event.target.value);
              if (issueHelperText.length !== 0) {
                setIssueHelperText('');
              }
            }}
            error={issueHelperText.length !== 0}
            helperText={issueHelperText}
          />
        </div>

        <div className={classes.fieldContainer}>
          <Typography className={classes.fieldTitle}>
            Attachment(s) <AttachFileIcon className={classes.attachmentIcon} fontSize='inherit' />{' '}
          </Typography>
          {attachments.map((attachment) => {
            return (
              <div key={attachment.uuid}>
                <FormAttachment name={attachment.name} onClick={() => removeAttachment(attachment.uuid)} />
              </div>
            );
          })}
          <Button className={classes.uploadFileButton} variant='contained' component='label'>
            Upload file(s)
            <input
              type='file'
              hidden
              multiple
              onChange={(event) => {
                handleAttachmentsChange(event);
                event.target.value = null; // Setting to null to allow uploading the same file, in case the user removes it prior to uploading it again.
              }}
            />
          </Button>
        </div>

        <StatusIcons />

        <Button
          className={classes.sendButton}
          variant='contained'
          component='label'
          onClick={handleSendData}
          disabled={loadingIcon || successIcon}
        >
          Send
        </Button>
      </Box>
    </Modal>
  );
};

export default ContactUsModal;
