import React, { useState, useEffect, useContext } from 'react';

import { Grid, Table, TableBody, TableCell, TableContainer, Button, TableHead, TableRow } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';

import { utils, writeFile } from 'xlsx';
import leftOutlined from '../assets/leftOutlined.svg';
import AlertDetailRow from '../molecules/AlertDetailRow';
import AlertDetailsStyle from '../styles/AlertDetailsStyle';
import AuthContext from '../context/AuthContext';
import LayoutStyles from '../styles/LayoutStyles';

const AlertDetailTabular = (props) => {
  const { kpiName, alertData, rsmAlert, personaFlag, alertId, alertPage, message } = props;
  const { user } = useContext(AuthContext);
  const layoutClasses = LayoutStyles();
  const classes = AlertDetailsStyle();
  const [rowData, setRowData] = useState([]);

  const [columnName, setColumnName] = useState([]);
  const navigate = useNavigate();

  // Callback function for set column name and row data to local state
  useEffect(() => {
    if (alertData) {
      if (alertData.length > 0) {
        let keys = Object.keys(alertData[0]);
        setColumnName(keys);
        setRowData(alertData);
      } else {
        setRowData([]);
        setColumnName([]);
      }
    }
  }, [alertData]);

  // Function for navigate to other page
  const handleNavigation = (alertId) => {
    if ((personaFlag === 2 || personaFlag === 5) && alertPage === 2) {
      navigate(`/rsmAlertDetails/${alertId}`);
    } else if (personaFlag === 6 && alertPage === 2) {
      navigate(`/rsmAlertDetails/${alertId}/${message}`);
    } else {
      navigate(`/alerts`);
    }
  };

  // Function for generate excel file. All data push into the excel.
  const getData = () => {
    const wb = utils.book_new();
    const ws = utils.json_to_sheet(rowData);
    const kpi_name = kpiName.replace(/ /g, '_');
    utils.book_append_sheet(wb, ws, kpiName);
    writeFile(wb, `${kpi_name}_alerts_report.xlsx`);
  };

  return (
    <Grid
      xs={12}
      item
      spacing={0.5}
      container
      flexDirection='column'
      alignContent='space-around'
      className={`${user.persona.layoutId === 1 ? layoutClasses.mainContainer : layoutClasses.rsmMainContainer}`}
    >
      <Grid xs={11} item container className={classes.alertLayoutContainer}>
        <Grid xs={12} item container className={classes.alertHeader}>
          <Grid item xs={6} className={classes.backButton}>
            <div key='alertDetails' className={classes.backButton} onClick={() => handleNavigation(alertId)}>
              <img src={leftOutlined} alt='close-icon' />
              {kpiName}
            </div>
          </Grid>
          <Grid item xs={6} className={classes.exportButton}>
            <Button variant='outlined' disabled={rowData.length === 0} onClick={getData}>
              Export
            </Button>
          </Grid>
        </Grid>
        <Grid item xs={12} container className={classes.alertContainer}>
          {rowData.length > 0 ? (
            <TableContainer>
              <Table aria-label='table'>
                <TableHead>
                  <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                    {columnName.map((key, index) => (
                      <TableCell key={key + index} align='left' className={classes.tableHeading}>
                        {key}
                      </TableCell>
                    ))}
                    {rsmAlert && <TableCell align='right' className={classes.tableHeading}></TableCell>}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {rowData.map((row, index) => (
                    <AlertDetailRow
                      key={index}
                      row={row}
                      columnName={columnName}
                      rsmAlert={rsmAlert}
                      alertId={alertId}
                      message={message}
                    />
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          ) : (
            <Grid item xs={12} container className={classes.noData}>
              No Data
            </Grid>
          )}
        </Grid>
      </Grid>
    </Grid>
  );
};
AlertDetailTabular.propTypes = {
  rsmAlert: PropTypes.bool,
  kpiName: PropTypes.string,
  alertData: PropTypes.array,
  personaFlag: PropTypes.number,
  alertId: PropTypes.number,
  alertPage: PropTypes.number,
};

AlertDetailTabular.defaultProps = {
  rsmAlert: false,
  kpiName: '',
  alertData: [],
  personaFlag: 1,
  alertPage: 1,
  alertId: 0,
};

export default AlertDetailTabular;
