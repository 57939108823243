import React, { useState, useContext } from 'react';

import { KeyboardArrowDown, KeyboardArrowUp } from '@mui/icons-material';
import { AppBar, Badge, Container, Link, Toolbar, Typography, Menu, MenuItem } from '@mui/material';
import { styled } from '@mui/material/styles';
import { Box } from '@mui/system';
import { useLocation, useNavigate } from 'react-router-dom';

import { pages, HQpages } from '../../config/headerRoute';
import PepsicoLogo from '../assets/PepsiCo_logo_home_page.svg';
import RatingTag from '../atom/RatingTag';
import AuthContext from '../context/AuthContext';
import ResourceLibraryStates from '../context/ResourceLibraryStates';
import HeaderStyles from '../styles/HeaderStyles';

import NotificationSetting from './NotificationSetting';
import ResourceLibrary from './ResourceLibrary';

const Header = (props) => {
  const navigate = useNavigate();
  const { userProfile, user, alertsCount } = useContext(AuthContext);
  const classes = HeaderStyles();
  const location = useLocation();
  const [resourceLibraryVisible, setResourceLibraryVisible] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [notificationSettingVisible, setNotificationSettingVisible] = useState(false);

  const open = Boolean(anchorEl);

  // Function for display menu list to click left side icon
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  // Function for close the left side list
  const handleClose = () => {
    setAnchorEl(null);
  };
  // Function for navigate to other page
  const navigatoTo = (page) => (e) => {
    e.preventDefault();
    setAnchorEl(null);
    navigate(page.to);
    return false;
  };

  // CSS
  const sxStyles = {
    menuItemsContainer: {
      backgroundColor: 'white',
      borderRadius: '5px',
      width: '11vw',
      padding: '10px',
      marginTop: '15px',
      boxShadow: '0px 0px 3px #ccc',
    },
    menuItem: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'flex-start',
    },
  };

  // Styled css
  const StyledMenu = styled((props) => (
    <Menu
      elevation={0}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'right',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      {...props}
    />
  ))(({ theme }) => ({
    '& .MuiPaper-root': {
      borderRadius: 4,
      marginTop: theme.spacing(2),
      right: 30,
      left: 'unset !important',
      minWidth: 200,
      boxShadow:
        'rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px',
      '& .MuiMenu-list': {
        padding: '4px 8px',
      },
      '& .MuiMenuItem-root': {
        backgroundColor: 'transparent',
        '&:active': {
          backgroundColor: 'transparent',
        },
        '&:hover': {
          backgroundColor: 'transparent',
        },
      },
    },
  }));
  return (
    <>
      <ResourceLibraryStates>
        <ResourceLibrary visible={resourceLibraryVisible} onClose={() => setResourceLibraryVisible(false)} />
      </ResourceLibraryStates>
      <NotificationSetting visible={notificationSettingVisible} onClose={() => setNotificationSettingVisible(false)} />
      <AppBar position='static' color='primary' className={classes.header}>
        <Container maxWidth='' className={classes.headerContainer}>
          <Toolbar disableGutters>
            <Box className={classes.logoContainer}>
              <div className={classes.logoInnerContainer}>
                <img src={PepsicoLogo} alt='Pepsico Logo' className={classes.logImage} />
                <Typography
                  noWrap
                  component='span'
                  sx={{ mr: 2, display: { xs: 'none', md: 'flex' } }}
                  style={{ fontSize: '1.2rem' }}
                >
                  Customer Service Hub
                </Typography>
              </div>
            </Box>
            <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' } }} className={classes.navLinkContainer}>
              {user.persona.layoutId === 3
                ? HQpages().map((page, key) => (
                    <Link
                      key={key}
                      underline='none'
                      sx={{ mr: 2, pb: 2, display: { xs: 'none', md: 'flex' } }}
                      href={page.to}
                      color='inherit'
                      onClick={navigatoTo(page)}
                      className={location.pathname === page.to ? classes.activeNavLink : classes.navLink}
                    >
                      {page.name === 'Alerts' ? (
                        <Badge className={classes.badgeCont} showZero badgeContent={alertsCount}>
                          {page.name}
                        </Badge>
                      ) : (
                        page.name
                      )}
                    </Link>
                  ))
                : pages().map((page, key) => (
                    <Link
                      key={key}
                      underline='none'
                      sx={{ mr: 2, pb: 2, display: { xs: 'none', md: 'flex' } }}
                      href={page.to}
                      color='inherit'
                      onClick={navigatoTo(page)}
                      className={
                        location.pathname === page.to ||
                        (page.to === '/home' && /^\/rsmDetails\/\d+$/.test(location.pathname)) ||
                        (page.to === '/historicalView' && /^\/rsmHistoricalDetails\/\d+$/.test(location.pathname))
                          ? classes.activeNavLink
                          : classes.navLink
                      }
                    >
                      {page.name === 'Alerts' ? (
                        <Badge className={classes.badgeCont} showZero badgeContent={alertsCount}>
                          {page.name}
                        </Badge>
                      ) : (
                        page.name
                      )}
                    </Link>
                  ))}
            </Box>
            <Box sx={{ flexGrow: 0, display: { md: 'flex' } }}>
              <Box sx={{ mr: 6, pb: 2, display: { xs: 'none', md: 'flex' } }} className={classes.favorite}>
                <Link
                  key={user.persona.layoutId === 3 ? '/favorites/nationalView' : '/favorites/dailyView'}
                  underline='none'
                  href={user.persona.layoutId === 3 ? '/favorites/nationalView' : '/favorites/dailyView'}
                  color='inherit'
                  onClick={navigatoTo({
                    to: user.persona.layoutId === 3 ? '/favorites/nationalView' : '/favorites/dailyView',
                  })}
                  className={classes.favoriteLink}
                >
                  <RatingTag
                    ratingClassName={classes.ratingEmptyIcon}
                    ratingScale={1}
                    ratingPoint={
                      location.pathname === '/favorites/dailyView' ||
                      location.pathname === '/favorites/historicalView' ||
                      location.pathname === '/favorites/nationalView'
                        ? true
                        : false
                    }
                  />
                  <Box className={user.persona.layoutId === 3 ? classes.favText : ''}>Favorites</Box>
                </Link>
              </Box>
              <Box
                sx={{ flexGrow: 0.1, display: { xs: 'none', md: 'flex' }, marginTop: 0.6 }}
                style={{
                  flexDirection: 'column',
                  fontSize: '1.4rem',
                }}
              >
                <Typography variant='body' component='h4'>
                  Hello {userProfile.given_name}
                </Typography>
                <Typography
                  variant='subtitle2'
                  style={{ fontSize: '0.9rem', textTransform: 'uppercase' }}
                  component='div'
                >
                  {user.persona.name}
                </Typography>
                <Typography variant='subtitle3' style={{ fontSize: '0.9rem' }} component='div'>
                  {user.persona.layoutId === 1 && `${user.location}, ${user.country}`}
                  {user.persona.layoutId === 2 && `${user.region}, ${user.country}`}
                  {user.persona.layoutId === 3 && `${user.country}`}
                </Typography>
              </Box>
              <div onClick={handleClick} style={{ marginTop: '25px', paddingLeft: '10px', cursor: 'pointer' }}>
                {!open ? <KeyboardArrowDown /> : <KeyboardArrowUp />}
              </div>
              <StyledMenu
                id='demo-customized-menu'
                MenuListProps={{
                  'aria-labelledby': 'demo-customized-button',
                }}
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
              >
                <MenuItem
                  onClick={(handleClose, navigatoTo({ name: 'Edit Profile', to: '/editProfile' }))}
                  className={classes.menuItem}
                  sx={sxStyles.menuItem}
                  key={`editProfile`}
                >
                  Edit Profile
                </MenuItem>
                <MenuItem
                  key={'notification-setting-link'}
                  onClick={() => (handleClose(), setNotificationSettingVisible(true))}
                  className={classes.menuItem}
                  sx={sxStyles.menuItem}
                  disableRipple
                >
                  Manage Email Subscriptions
                </MenuItem>
                <MenuItem
                  key={'resource-library-link'}
                  onClick={() => (handleClose(), setResourceLibraryVisible(true))}
                  className={classes.menuItem}
                  sx={sxStyles.menuItem}
                  disableRipple
                >
                  Resource Library
                </MenuItem>
                <MenuItem
                  onClick={(handleClose, navigatoTo({ name: 'Help', to: '/helpCentre' }))}
                  className={classes.menuItem}
                  sx={sxStyles.menuItem}
                  key={`helpCentre`}
                >
                  Help
                </MenuItem>
                {user.admin?.isSuperAdmin === 1 && (
                  <MenuItem
                    onClick={(handleClose, navigatoTo({ name: 'Usage Statistics', to: '/appStatistics' }))}
                    className={classes.menuItem}
                    sx={sxStyles.menuItem}
                    key={`appStatistics`}
                  >
                    Usage Statistics
                  </MenuItem>
                )}
              </StyledMenu>
            </Box>
          </Toolbar>
        </Container>
      </AppBar>
    </>
  );
};

export default Header;
