import { makeStyles } from '@mui/styles';

const ToolLinksStyles = makeStyles(() => ({
  toolLinkHeader: {
    backgroundColor: '#004D8F',
    boxShadow: 'none',
    display: 'flex',
    height: '2rem',
    padding: '2px',
  },
  dashboardLinksContainer: {
    display: 'flex',
    alignItems: 'center',
    padding: '2px 2px 2px 2px',
    gap: 8,
  },
  toolLinkButton: {
    display: 'flex',
    alignItems: 'center',
    lineHeight: 1.15,
    border: '1px solid #FFFFFF',
    '&:hover': {
      border: '1px solid #FFFFFF',
    },
  },
  dashboardLinkText: {
    fontSize: '1rem',
    marginRight: 20,
  },
}));

export default ToolLinksStyles;
