/*
This component contains source code that defines page which will display Product details of an alert on click of Internal Hyperlink on the alerts.
*/
import React, { useState, useEffect, useContext } from 'react';
import Papa from 'papaparse';
import { useAppInsightsContext } from '@microsoft/applicationinsights-react-js';
import { useParams } from 'react-router-dom';
import { getAlertById } from '../../actions/alert';
import AuthContext from '../context/AuthContext';
import AlertDetailTabular from '../organisms/AlertDetailTabular';

const RpsmAlertDetails = (props) => {
  const { user } = useContext(AuthContext);
  const appInsights = useAppInsightsContext();

  const [rowData, setRowData] = useState();
  const [kpiName, setKpiName] = useState('');
  const [loading, setLoading] = useState(true);
  const { showAlert } = props;
  const { alertId, message } = useParams();
  const [messageFlag, setMessageFlag] = useState();

  useEffect(() => {
    if (message === '1') {
      setMessageFlag(1);
    } else if (message === '2') {
      setMessageFlag(2);
    } else {
      setMessageFlag(1);
    }
  }, [message]);

  // Callback function for tracking the page
  useEffect(() => {
    if (user) {
      appInsights.trackPageView({
        uri: window.location.href,
        refUri: window.location.href,
        properties: {
          personaName: user.persona.name,
        },
      });
    }
  }, [user]);

  // Callback function for get alert details and set into the local state
  useEffect(() => {
    if (user && alertId && messageFlag !== '' && messageFlag !== undefined) {
      const getAlertsData = async () => {
        const data = await getAlertById({ id: alertId, persona_flag: messageFlag });
        if (data.error) {
          showAlert({
            alertMessage: data.message,
            alertType: 'error',
          });
          setLoading(false);
        } else {
          setKpiName(data.alert.kpi_mapping.KPI_GEN_NAME);
          Papa.parse(data.blobData, {
            header: true,
            skipEmptyLines: true,
            complete: function (results) {
              let locationBasedData = results.data.filter(
                (data) => data['Country'] === user.country && data['Region'] === user.region,
              );

              let alertDetails = locationBasedData.map((v) => {
                delete v.CREATE_DATE;
                return v;
              });
              setRowData(alertDetails);
              setLoading(false);
            },
          });
        }
      };
      getAlertsData();
    }
  }, [user, alertId, messageFlag]);

  return <>{loading === false && <AlertDetailTabular alertId={alertId} alertData={rowData} kpiName={kpiName} />}</>;
};

export default RpsmAlertDetails;
