/*
This component contains source code that defines an PowerBI report.
It takes props like access token, embedurl, pageName and other to return PowerBu report that can be displayed on the screen.
*/
import React from 'react';

import { Box } from '@mui/material';

import RSMDashboardDetailsStyle from '../styles/RSMDashboardDetailsStyle';
import PowerBiReport from '../atom/PowerBiReport';

const RSMPowerWidget = (props) => {
  const { accessToken, KpiId, tileId, embedUrl, pageName, country, region, location, multipleLocationType } = props;

  const classes = RSMDashboardDetailsStyle();
  return (
    <Box xs={12} className={classes.powerBiWidget}>
      <PowerBiReport
        accessToken={accessToken}
        id={tileId}
        KpiId={KpiId}
        embedUrl={embedUrl}
        pageName={pageName}
        country={country}
        region={region}
        location={location}
        multipleLocationType={multipleLocationType}
      />
    </Box>
  );
};

RSMPowerWidget.defaultProps = {
  accessToken: '',
  tileId: '',
  embedUrl: '',
  pageName: '',
  KpiId: '',
};

export default RSMPowerWidget;
