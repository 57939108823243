/*
This component contains source code that creates a tabular structure that displays KPI information/details.
It is used to KPI information/details in the L1 section or functional categories on the Home, Favorites pages in RSM personas.
*/
import React from 'react';
import { TableCell } from '@mui/material';
import moment from 'moment';
import RatingTag from './RatingTag';
import DragIndicatorIcon from '@mui/icons-material/DragIndicator';

import TabularStyles from '../styles/TabularStyle';
import LineChart from '../atom/LineChart';

function Tabular(props) {
  const { kpiName, reportDetail, toggleRowStar, report, onToggleRowStarClick, onTileClick, dragKpi } = props;
  const classes = TabularStyles();
  let refresh_date = reportDetail.refresh_date ? moment(reportDetail.refresh_date).format('MM/DD') : '';

  const onCellClick = onTileClick;

  return (
    <>
      <TableCell onClick={onCellClick}>
        <div className={classes.kpiNameSection}>
          <div className={classes.kpiName}>
            {' '}
            {dragKpi && <DragIndicatorIcon></DragIndicatorIcon>}
            <span>{kpiName}</span>
          </div>
          <div className={classes.daily}>{refresh_date}</div>
        </div>
      </TableCell>
      <TableCell className={classes.last24Hrs} style={{ color: reportDetail.value_color }} onClick={onCellClick}>
        {reportDetail.last24Hrs ? `${reportDetail.last24Hrs}${reportDetail.last24HrsUnit}` : '(Blank)'}
      </TableCell>
      <TableCell onClick={onCellClick}>
        <div className={classes.wtd}>
          <span style={{ color: reportDetail.value_color }}>
            {reportDetail.wtd ? `${reportDetail.wtd}${reportDetail.wtdUnit}` : '(Blank)'}
          </span>
          <span className={classes.wtdSymbol} style={{ color: reportDetail.difference_color }}>
            {reportDetail.difference
              ? `${
                  reportDetail.difference_arrowDirection
                    ? reportDetail.difference_arrowDirection === 'up'
                      ? '▲'
                      : '▼'
                    : ''
                }`
              : ''}
          </span>
        </div>
      </TableCell>
      <TableCell onClick={onCellClick}>
        <div className={classes.diffrenceSection}>
          <span className={classes.diffrence} style={{ color: reportDetail.difference_color }}>
            {reportDetail.difference
              ? `${
                  reportDetail.difference_arrowDirection
                    ? reportDetail.difference_arrowDirection === 'up'
                      ? '▲'
                      : '▼'
                    : ''
                } ${reportDetail.difference}${reportDetail.differenceUnit}`
              : ''}
          </span>
          <span className={classes.fromDays}>{reportDetail.days_desc}</span>
          <span className={classes.targetKey}>
            Target :
            <span className={classes.targetValue}>{`${
              reportDetail.target === null ? 'N/A' : `${reportDetail.target}${reportDetail.targetUnit}`
            }`}</span>
          </span>
        </div>
      </TableCell>
      <TableCell onClick={onCellClick}>
        <div className={classes.chart}>
          <div className={classes.agChartWrapper}>
            <LineChart lineChartData={reportDetail.chart} tableLineChart />
          </div>
        </div>
      </TableCell>
      <TableCell
        key={`div-${report.id}`}
        className={`${classes.overlayRatingTable}`}
        onClick={onToggleRowStarClick(
          'table',
          report.id,
          toggleRowStar['table'][report.reportDetail.kpi_id],
          report.reportDetail.kpi_id,
        )}
      >
        <RatingTag
          key={`rating-${report.id}`}
          ratingScale={1}
          ratingPoint={toggleRowStar['table'][report.reportDetail.kpi_id]}
          tableStar
        />
      </TableCell>
    </>
  );
}
export default Tabular;
