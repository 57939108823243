/*
This component contains source code that defines creates a text editor that allows admin to create templates to be added into Help feature.
It allows user create templates , edit templates and more edit options.
*/
import React from 'react';

import ImageResize from 'quill-image-resize-module-react';
import ReactQuill, { Quill } from 'react-quill';
import 'react-quill/dist/quill.snow.css';

Quill.register('modules/imageResize', ImageResize);

const RichTextEditor = (props) => {
  const { value, onChange, className } = props;

  // Configuration for rich text editor
  const modules = {
    toolbar: [
      // [{ font: [] }],
      [{ header: [1, 2, 3, 4, 5, 6, false] }],
      [{ size: ['small', false, 'large', 'huge'] }], // custom dropdown
      ['bold', 'italic', 'underline', 'strike'], // toggled buttons
      ['blockquote', 'code-block'],
      [{ list: 'ordered' }, { list: 'bullet' }],
      [{ script: 'sub' }, { script: 'super' }], // superscript/subscript
      [{ indent: '-1' }, { indent: '+1' }], // outdent/indent
      [{ align: [] }],
      [{ color: [] }, { background: [] }], // dropdown with defaults from theme
      ['link', 'image', 'video'],
      ['clean'], // remove formatting button
    ],
    imageResize: {
      parchment: Quill.import('parchment'),
      modules: ['Resize', 'DisplaySize', 'Toolbar'],
    },
    clipboard: {
      matchVisual: false,
    },
  };

  return <ReactQuill theme='snow' className={className} value={value} onChange={onChange} modules={modules} />;
};

export default RichTextEditor;
