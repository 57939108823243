/*
This component contains source code that will display a list of tiles and tabs in the Favorites page.
*/
import React, { useEffect, useState, useContext } from 'react';

import AuthContext from '../context/AuthContext';
import FavoritesDashboard from '../organisms/FavoritesDashboard';
import FavoritesRSMDashboard from '../organisms/FavoritesRSMDashboard';
import FavoritesContext from '../context/FavoritesContext';

const Favorites = (props) => {
  const { accessToken, user } = useContext(AuthContext);
  const { dailyviewFavorites, dailyviewtableFavorites } = useContext(FavoritesContext);
  const [reports, setReports] = useState([]);
  const [tableReports, setTableReports] = useState([]);

  // Tile KPI's records
  useEffect(() => {
    setReports(dailyviewFavorites);
  }, [dailyviewFavorites]);

  // Tabular KPI's records
  useEffect(() => {
    setTableReports(dailyviewtableFavorites);
  }, [dailyviewtableFavorites]);

  return (
    <>
      {user.persona.layoutId === 1 ? (
        <FavoritesDashboard
          reports={reports}
          tableReports={tableReports}
          accessToken={accessToken}
          personaId={user.personaId}
          {...props}
        />
      ) : (
        <FavoritesRSMDashboard
          reports={reports}
          tableReports={tableReports}
          accessToken={accessToken}
          personaId={user.personaId}
          {...props}
        />
      )}
    </>
  );
};

export default Favorites;
