/*
The following code contains source code that defines all the api functions for Home Page.
get tile and tabular KPI details, save favorited item, update favorites, get locations, countries, regions, location Types.
*/
import request from '../helper/apiRequest';
// eslint-disable-next-line react-hooks/rules-of-hooks

// Function to get dashboard report tiles, tabular and favourite data  input = userId and locationType
export const getCustomTilesData = async (userId, locationType, location) => {
  try {
    let locationQuery = '';
    if (locationType) {
      locationQuery = `&locationType=` + encodeURIComponent(locationType);
    }
    if (location) {
      locationQuery = `&location=` + encodeURIComponent(location);
    }
    const data = await request.get(`/reports/customTiles?userId=${userId}${locationQuery}`);
    return data;
  } catch (e) {
    return {
      error: true,
      message:
        'Something went wrong, please try reloading the page. If the problem persists, check your internet connection.',
      data: null,
    };
  }
};

//Function to update tiles and tabular Order based on KPI
export const updateKPIOrderData = async (userId, personaId, view, tab, list) => {
  try {
    const data = await request.post(
      '/reports/updateKPIOrderData',
      {},
      {
        userId: userId,
        personaId: personaId,
        view: view,
        tab: tab,
        data: list,
      },
    );
    return data;
  } catch (e) {
    return {
      error: true,
      message:
        'Something went wrong, please try reloading the page. If the problem persists, check your internet connection.',
      data: null,
    };
  }
};

// Function to get dashboard report details based on KPI input = userId, locationType, kpi_id, location, region
export const getReportDetailList = async (userId, locationType, kpi_id, location, region) => {
  try {
    const data = await request.post(
      '/reports/reportDetailList',
      {},
      {
        kpiId: kpi_id,
        locationType: locationType,
        location: location,
        userId: userId,
        region: region,
      },
    );
    return data;
  } catch (e) {
    return {
      error: true,
      message:
        'Something went wrong, please try reloading the page. If the problem persists, check your internet connection.',
      data: null,
    };
  }
};

// Function to get export ranking table details based on KPI input = kpi_id, View type = "Daily/Historical"
export const getExportDetailList = async (kpi_id, view_type) => {
  try {
    const data = await request.get(`/reports/exportDetailList?kpiId=${kpi_id}&view=${view_type}`);
    return data.data;
  } catch (e) {
    return {
      error: true,
      message:
        'Something went wrong, please try reloading the page. If the problem persists, check your internet connection.',
      data: null,
    };
  }
};

//Function to update tiles and tabular as favourite based on KPI
export const setReportToggle = async (props) => {
  try {
    const data = await request.post('/report/starToggle', {}, props);
    return data;
  } catch (e) {
    return {
      error: true,
      message: 'Favorites could not be saved, please try again.',
      data: null,
    };
  }
};
//Function to get list of location based on country, region and location_type
export const getFilterLocation = async (country, region, location_type, personaId, reportId, allSelected) => {
  try {
    if (country) {
      const data = await request.post(
        '/filter/location',
        {},
        {
          country: country,
          region: region,
          location_type: location_type,
          persona_id: personaId,
          reportId: reportId,
          allSelected: allSelected,
        },
      );
      return data;
    }
  } catch (e) {
    return {
      error: true,
      message:
        'Something went wrong, please try reloading the page. If the problem persists, check your internet connection.',
      data: null,
    };
  }
};
//Function to get list of country
export const getFilterCountry = async () => {
  try {
    const { data } = await request.get('/filter/country');
    return data;
  } catch (e) {
    return {
      error: true,
      message:
        'Something went wrong, please try reloading the page. If the problem persists, check your internet connection.',
      data: null,
    };
  }
};
//Function to get list of region based on country
export const getFilterRegion = async (country, personaId) => {
  try {
    if (country && personaId) {
      const { data } = await request.get(`/filter/region?country=${country}&&personaId=${personaId}`);
      return data;
    }
  } catch (e) {
    return {
      error: true,
      message:
        'Something went wrong, please try reloading the page. If the problem persists, check your internet connection.',
      data: null,
    };
  }
};
//Function to get list of location type based on country and region
export const getFilterLocationType = async (country, region, personaId, innerkpiId) => {
  try {
    const data = await request.post(
      '/filter/locationType',
      {},
      { country: country, region: region, persona_id: personaId, innerkpiId: innerkpiId ? innerkpiId : '' },
    );
    return data;
  } catch (e) {
    return {
      error: true,
      message:
        'Something went wrong, please try reloading the page. If the problem persists, check your internet connection.',
      data: null,
    };
  }
};

//Function to get tiles and tabular as favourite based on KPI
export const getDailyViewFavorites = async (userId, locationType, location) => {
  try {
    let locationQuery = '';
    if (locationType) {
      locationQuery = `&locationType=` + encodeURIComponent(locationType);
    }
    if (location) {
      locationQuery = `&location=` + encodeURIComponent(location);
    }
    const data = await request.get(`/reports/dailyViewFavorites?userId=${userId}${locationQuery}`);
    return data;
  } catch (e) {
    return {
      error: true,
      message:
        'Something went wrong, please try reloading the page. If the problem persists, check your internet connection.',
      data: null,
    };
  }
};
