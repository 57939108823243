import { makeStyles } from '@mui/styles';
const NationalViewTableStyles = makeStyles(() => ({
  tableCell: {
    fontSize: '1.2rem',
    color: '#011F36',
    paddingTop: 1,
    paddingBottom: 1,
  },
  tableRow: {
    // '&:nth-of-type(odd)': {
    //   backgroundColor: '#F4F4F4',
    // },
    // hide last border
    '&:last-child td, &:last-child th': {
      border: 0,
    },
  },
  iconClick: {
    cursor: 'pointer',
  },
  agChartWrapper: {
    width: '180px',
    height: '60px',
  },
}));

export default NationalViewTableStyles;
