import { makeStyles } from '@mui/styles';

const NotificationSettingStyles = makeStyles(() => ({
  modal: {
    color: 'white',
  },
  container: {
    position: 'absolute',
    top: '10%',
    left: 0,
    right: 0,
    margin: 'auto',
    width: '50%',
    height: '75%',
    backgroundColor: 'white',
    outline: 'none',
  },
  closeIcon: {
    width: '30px',
    height: '30px',
    borderRadius: 5,
    backgroundColor: '#EDEDED',
    cursor: 'pointer',
  },
  closeIconItem: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  tableHeading: {
    color: '#011F36',
    fontWeight: 'bold',
    fontSize: '1.4rem',
  },
  kpiName: {
    color: '#2F2F2F',
    fontSize: '1.2rem',
  },
  table: {
    maxHeight: 600,
    '&::-webkit-scrollbar': {
      width: 10,
    },

    /* Track */
    '&::-webkit-scrollbar-track': {
      background: '#F4F4F4',
      borderRadius: 10,
    },

    /* Handle */
    '&::-webkit-scrollbar-thumb': {
      background: '#ccc',
      borderRadius: 10,
    },
  },
  tableContainer: {
    alignContent: 'flex-start',
    padding: '6px 20px',
  },
  subscriptionItemLink: {
    gap: 10,
    cursor: 'pointer',
    display: 'flex',
    alignItems: 'center',
  },
  subscriptionItem: {
    display: 'flex',
    alignItems: 'center',
    gap: 10,
    fontSize: '1.2rem',
    color: '#011F36',
  },
  infoIcon: {
    display: 'flex',
    alignItems: 'center',
    gap: 10,
  },
  notAvailable: {
    display: 'flex',
    justifyContent: 'left',
    fontSize: '1.2rem',
    color: '#676767',
  },
}));
export default NotificationSettingStyles;
