/*
This component contains source code that will display a list of L0/Executive summary page tiles and tabs in the RSM Home page.
*/
import React, { useState, useEffect, useContext } from 'react';
import Papa from 'papaparse';
import { useParams } from 'react-router-dom';
import { getAlertById } from '../../actions/alert';
import AuthContext from '../context/AuthContext';
import AlertDetailTabular from '../organisms/AlertDetailTabular';
import { useAppInsightsContext } from '@microsoft/applicationinsights-react-js';

const RsmAlertDetails = (props) => {
  const { user } = useContext(AuthContext);
  const appInsights = useAppInsightsContext();
  const [rowData, setRowData] = useState();
  const [kpiName, setKpiName] = useState('');
  const [loading, setLoading] = useState(true);
  const { showAlert } = props;
  const { alertId, message } = useParams();
  const [messageFlag, setMessageFlag] = useState();

  // Callback function for tracking the page
  useEffect(() => {
    appInsights.trackPageView({
      uri: window.location.href,
      refUri: window.location.href,
      properties: {
        personaName: user.persona.name,
      },
    });
  }, []);

  useEffect(() => {
    if (message === '1') {
      setMessageFlag(1);
    }
    if (message === '2') {
      setMessageFlag(2);
    }
    if (message === undefined) {
      setMessageFlag(0);
    }
  }, [message]);

  // Callback function for get alert data by alert id and parse the blob data into the array of object
  useEffect(() => {
    if (user && alertId && messageFlag !== '' && messageFlag !== undefined) {
      const getAlertsData = async () => {
        const data = await getAlertById({ id: alertId, persona_flag: 2, message: messageFlag });
        if (data.error) {
          showAlert({
            alertMessage: data.message,
            alertType: 'error',
          });
          setLoading(false);
        } else {
          setKpiName(data.alert.kpi_mapping.KPI_GEN_NAME);
          // Parse the Blob data to array of object. Data convert into the key and value
          Papa.parse(data.blobData, {
            header: true,
            skipEmptyLines: true,
            complete: function (results) {
              let locationBasedData = results.data.filter(
                (data) => data['Country'] == user.country && data['Region'] == user.region,
              );
              let alertDetails = locationBasedData.map((v) => {
                delete v.CREATE_DATE;
                return v;
              });
              setRowData(alertDetails);
              setLoading(false);
            },
          });
        }
      };
      getAlertsData();
    }
  }, [user, alertId, messageFlag]);

  return (
    <>
      {loading === false && (
        <AlertDetailTabular
          alertData={rowData}
          kpiName={kpiName}
          rsmAlert={true}
          alertId={alertId}
          message={messageFlag}
        />
      )}
    </>
  );
};

export default RsmAlertDetails;
