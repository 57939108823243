/*
This component contains source code that creates a tile structure that displays KPI information/details.
It is used to KPI information/details in the L1/Executive summary section on the Home, Favorites pages.
*/
import React, { useEffect, useState } from 'react';

import { Grid } from '@mui/material';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import DragIndicatorIcon from '@mui/icons-material/DragIndicator';
import { isEmpty } from 'lodash';
import moment from 'moment';

import TileStyles from '../styles/TileStyle';
import '../styles/CustomTooltip.css';

import RatingTag from './RatingTag';

const TileBlock = (props) => {
  const classes = TileStyles();
  const { kpiName, reportDetail, ratingPoint, onToggleRowStarClick, onTileClick, user, KpiId, dragKpi } = props;
  const [headers, setHeaders] = useState([]);
  const [headerValues, setHeaderValues] = useState([]);
  const [headerValuesUnit, setHeaderValuesUint] = useState([]);
  let refresh_date = reportDetail.refresh_date ? moment(reportDetail.refresh_date).format('MM/DD') : '';

  // Callback function for set the report data into the local state
  useEffect(() => {
    if (reportDetail.kpi_details) {
      let kpi_data = JSON.parse(reportDetail.kpi_details);
      const headings = kpi_data['Headings'].split(',');
      const headers = headings.map((val) => {
        let rep_val = val.replaceAll('_', ' ');
        return rep_val;
      });
      setHeaders(headers);
      let headerValuesData = [];
      let headerValuesUnitData = [];
      headings.map((val) => {
        const unit = kpi_data[`${val}_Unit`].value.includes('|')
          ? kpi_data[`${val}_Unit`].value.split('|')[0]
          : kpi_data[`${val}_Unit`].value;
        const k = kpi_data[`${val}_Unit`].value.includes('|K') && 'K';
        headerValuesData.push(kpi_data[val].value + k);
        headerValuesUnitData.push(unit);
      });
      setHeaderValuesUint(headerValuesUnitData);
      setHeaderValues(headerValuesData);
    }
  }, [reportDetail]);

  return (
    <Grid item container spacing={0} xl={12} xs={12} md={12} className={classes.tileContainer} onClick={onTileClick}>
      <Grid item container spacing={0} xl={12} xs={12} md={12} className={classes.firstRow}>
        <span>{kpiName}</span>
        <div className={classes.overlayRating} onClick={onToggleRowStarClick}>
          <RatingTag ratingScale={1} ratingPoint={ratingPoint} />
        </div>
      </Grid>
      {!isEmpty(headers) ? (
        headers.map((val, index) => {
          return (
            <>
              <Grid
                key={`tile-div-${index}`}
                item
                container
                spacing={0}
                xl={12}
                xs={12}
                md={12}
                className={classes.innerRow}
              >
                <Grid item xl={9} xs={9} md={9} key={`${index}-kpiattr`}>
                  <span className={classes.kpiAttr} key={`${index}-kpiattr-span`}>
                    {val}
                  </span>
                </Grid>
                <Grid item xl={3} xs={3} md={3} className={classes.kpiAttrValue} key={`${index}-kpiattrval`}>
                  <span className={classes.kpiAttrValue} key={`${index}-kpiattrval-span`}>
                    {headerValues[index] !== (null || undefined || '')
                      ? headerValues[index] !== 0.0
                        ? reportDetail.kpi_id === 'WAR012'
                          ? `${headerValuesUnit[`${index}`]}${headerValues[index]}`
                          : `${headerValues[index]}${headerValuesUnit[`${index}`]}`
                        : '0.00'
                      : '(Blank)'}
                  </span>
                </Grid>
              </Grid>
            </>
          );
        })
      ) : (
        <>
          <Grid item container spacing={0} xl={12} xs={12} md={12} className={classes.innerRow}>
            <Grid item xl={9} xs={9} md={9}>
              <span className={classes.kpiAttr}>(Blank)</span>
            </Grid>
            <Grid item xl={3} xs={3} md={3} className={classes.kpiAttrValue}>
              <span className={classes.kpiAttrValue}>(Blank)</span>
            </Grid>
          </Grid>
        </>
      )}
      <Grid item container spacing={0} xl={12} xs={12} md={12} className={classes.thirdRow}>
        <Grid item xl={6} xs={6} md={6}>
          <span className={classes.thirdRowChild}>{reportDetail.kpi_id == 'WAR009' ? '(For Next Day)' : ''}</span>
        </Grid>
        <Grid item xl={6} xs={6} md={6}>
          <span className={classes.daily}>{refresh_date}</span>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default TileBlock;
