/*
The following code contains source code that defines all the api functions for Manage email subscriptions page.
It creates functions for handling Manage email subscription scenarios.
Save user settings, update user settings, fetch user settings and disable user setting for email notifications.
*/
import request from '../helper/apiRequest';

// Function to get notification setting based on personaId and userId
export const getNotificationSetting = async (personaId, userId) => {
  try {
    const { data } = await request.get(
      `/notificationSetting/fetchNotificationSetting?userId=${userId}&personaId=${personaId}`,
    );
    return data;
  } catch (e) {
    return { error: true, message: e.message };
  }
};
// Function to update alet email and kpi notification setting
export const addAlertEmailNotification = async (props) => {
  try {
    const data = await request.post(`/notificationSetting/addAlertEmailNotification`, {}, props);
    return data;
  } catch (e) {
    return { error: true, message: e.message };
  }
};
// Function to disable alet email and kpi notification setting
export const disableAlertEmailNotification = async (props) => {
  try {
    const { data } = await request.put(`/notificationSetting/disableAlertEmailNotification`, {}, props);
    return data;
  } catch (e) {
    return { error: true, message: e.message };
  }
};
