import { makeStyles } from '@mui/styles';
const StatisticsStyles = makeStyles(() => ({
  boxStyle: {
    display: 'flex',
    margin: 'auto',
    height: 'calc(100vh - 100px)',
    width: 'calc(100vw - 40px)',
    '@media screen and (min-width:1200px)': {
      zoom: '144%',
    },
    '@media screen and (min-width:600px)': {
      zoom: '184%',
    },
    '@media screen and (min-width:700px)': {
      zoom: '184%',
    },
    '@media screen and (min-width:900px)': {
      zoom: '166%',
    },
    '@media screen and (min-width:1300px)': {
      zoom: '140%',
    },
    '@media screen and (min-width:1500px)': {
      zoom: '125%',
    },
    '@media screen and (min-width:1600px)': {
      zoom: '122%',
    },
    '@media screen and (min-width:1700px)': {
      zoom: '120%',
    },
    '@media screen and (min-width:1800px)': {
      zoom: '97%',
    },
    '@media screen and (min-width:2500px)': {
      zoom: '74%',
    },
    '@media screen and (min-width:2800px)': {
      zoom: '70%',
    },
    '@media screen and (min-width:3000px)': {
      zoom: '64%',
    },
    '@media screen and (min-width:3400px)': {
      width: 'calc(100vw - 20%)',
      height: 'calc(100vh - 10%)',
      zoom: '54%',
    },
    '@media screen and (min-width:3800px)': {
      width: 'calc(100vw - 14%)',
      height: 'calc(100vh - 20%)',
      zoom: '52%',
    },
    '@media screen and (min-width:5100px)': {
      width: 'calc(100vw - 10%)',
      height: 'calc(100vh - 20%)',
      zoom: '40%',
    },
    '@media screen and (min-width:7680px)': {
      width: 'calc(100vw - 5%)',
      height: 'calc(100vh - 15%)',
      zoom: '24%',
    },
  },
}));

export default StatisticsStyles;
