import { makeStyles } from '@mui/styles';

const NotificationSettingMenuStyles = makeStyles(() => ({
  menuItem: {
    padding: 5,
    fontSize: '1.2rem',
    color: '#011F36',
  },
}));

export default NotificationSettingMenuStyles;
