/*
This component contains source code that will display a User profile or Edit profile page.
*/
import React, { useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import { getAllPersonas } from '../../actions/user';
import AuthContext from '../context/AuthContext';
import FilterContext from '../context/FilterContext';
import Users from '../organisms/Users';
import setBodyColor from '../atom/SetBodyColor';
import { useAppInsightsContext } from '@microsoft/applicationinsights-react-js';

const SwitchUser = (props) => {
  // Variable declaration
  const { showAlert } = props;
  const { user } = useContext(AuthContext);
  const filterContext = useContext(FilterContext);
  const { getCountryData } = filterContext;
  const [personas, setPersonas] = useState([]);
  setBodyColor({ color: '#004C97' });
  const appInsights = useAppInsightsContext();

  // Callback function for get all persona and get country list
  useEffect(() => {
    if (!personas[0]) {
      const getPersonas = async () => {
        const data = await getAllPersonas(); // Call the API for get all persona
        if (data.error) {
          showAlert({
            alertMessage: data.message,
            alertType: 'error',
          });
        } else {
          setPersonas(
            data.map((elem) => {
              return {
                ...elem,
              };
            }),
          );
        }
      };
      getPersonas();
      getCountryData();
    }
  }, [personas]);

  // Callback function for tracking the page.
  useEffect(() => {
    if (user.personaId !== null) {
      // Tracking the page with persona name
      appInsights.trackPageView({
        uri: window.location.href,
        refUri: window.location.href,
        properties: {
          personaName: user.persona.name,
        },
      });
    } else {
      // Tracking the page without persona name
      appInsights.trackPageView({
        uri: window.location.href,
        refUri: window.location.href,
      });
    }
  }, []);

  return (
    user && (
      <div class='main-container'>
        <Users switchUserLinks={personas} user={user} {...props} />
      </div>
    )
  );
};

SwitchUser.propTypes = {
  getCountryData: PropTypes.func,
};

export default SwitchUser;
