/*
This component contains source code that creates a tabular structure that displays KPI information/details.
It is used to KPI information/details in the L1 section or functional categories on the Home, Favorites pages in Non-RSM personas.
*/
import React, { useEffect, useState } from 'react';
import { isEmpty } from 'lodash';
import moment from 'moment';
import RatingTag from './RatingTag';
import DragIndicatorIcon from '@mui/icons-material/DragIndicator';
import { TableCell } from '@mui/material';
import TabularTabStyle from '../styles/TabularTabStyle';

function TabBlock(props) {
  const classes = TabularTabStyle();
  const { kpiName, reportDetail, toggleRowStar, report, onToggleRowStarClick, onTileClick, dragKpi } = props;
  let refresh_date = reportDetail.refresh_date ? moment(reportDetail.refresh_date).format('MM/DD') : '';
  const [headers, setHeaders] = useState([]);
  const [headerValues, setHeaderValues] = useState([]);
  const [headerValuesUnit, setHeaderValuesUint] = useState([]);
  const onCellClick = onTileClick;

  // Callback function for set the report data into the local state
  useEffect(() => {
    if (reportDetail.kpi_details) {
      let kpi_data = JSON.parse(reportDetail.kpi_details);
      const headings = kpi_data['Headings'].split(',');
      const headers = headings.map((val) => {
        let rep_val = val.replaceAll('_', ' ');
        return rep_val;
      });
      setHeaders(reportDetail.kpi_id == 'MFG002' ? [headers[0]] : headers);

      let headerValuesData = [];
      let headerValuesUnitData = [];
      headings.map((val) => {
        const unit = kpi_data[`${val}_Unit`].value.includes('|')
          ? kpi_data[`${val}_Unit`].value.split('|')[0]
          : kpi_data[`${val}_Unit`].value;
        const k = kpi_data[`${val}_Unit`].value.includes('|K') && 'K';
        headerValuesData.push(kpi_data[val].value + k);
        headerValuesUnitData.push(unit);
      });
      setHeaderValuesUint(headerValuesUnitData);
      setHeaderValues(headerValuesData);
    }
  }, [reportDetail]);

  return (
    <>
      <TableCell onClick={onCellClick}>
        <div className={classes.kpiNameSection}>
          <div className={classes.kpiName}>
            {' '}
            {dragKpi && <DragIndicatorIcon fontSize='large'></DragIndicatorIcon>}
            <span>{kpiName}</span>
          </div>
          <div className={classes.daily}>{refresh_date}</div>
        </div>
      </TableCell>
      {!isEmpty(headers) ? (
        headers.map((val, index) => {
          return (
            <TableCell align='center' key={`tabblock-${index}`} onClick={onCellClick}>
              <div className={classes.wtd}>
                <div> {val}</div>
                <span style={{ color: reportDetail.value_color }} className={`${classes.number}`}>
                  {headerValues[index] !== (null || undefined || '')
                    ? headerValues[index] !== 0.0
                      ? reportDetail.kpi_id === 'WAR012'
                        ? `${headerValuesUnit[`${index}`]}${headerValues[index]}`
                        : `${headerValues[index]}${headerValuesUnit[`${index}`]}`
                      : '0.00'
                    : '(Blank)'}
                </span>
              </div>
            </TableCell>
          );
        })
      ) : (
        <TableCell align='center' colSpan='3' key={`last-key-${kpiName}`} onClick={onCellClick}>
          <div className={classes.wtd}>
            <div>(Blank)</div>
            <span style={{ color: reportDetail.value_color }} className={`${classes.number}`}>
              (Blank)
            </span>
          </div>
        </TableCell>
      )}
      <TableCell onClick={onCellClick}></TableCell>
      <TableCell
        align='right'
        key={`div-${report.id}`}
        className={`${classes.overlayRatingTable}`}
        onClick={onToggleRowStarClick(
          'table',
          report.id,
          toggleRowStar['table'][report.reportDetail.kpi_id],
          report.reportDetail.kpi_id,
        )}
      >
        <RatingTag
          key={`rating-${report.id}`}
          ratingScale={1}
          ratingPoint={toggleRowStar['table'][report.reportDetail.kpi_id]}
          tableStar
        />
      </TableCell>
    </>
  );
}

export default TabBlock;
