import { makeStyles } from '@mui/styles';
const NationalViewDetailsStyle = makeStyles((theme) => ({
  layoutMainContainer: {
    paddingTop: 30,
  },
  layoutContainer: {
    paddingTop: 0,
    marginTop: '-18px',
    padding: '12px',
    paddingBottom: '0px',
    display: 'block',
  },
  tableHeading: {
    fontSize: '1.28rem',
    color: '#3D4043',
    opacity: 0.8,
    paddingTop: 1,
    paddingBottom: 1,
    textTransform: 'uppercase',
  },
  tableCell: {
    fontSize: '1.2rem',
    color: '#011F36',
    paddingTop: 1,
    paddingBottom: 1,
  },
  tableRow: {
    '&:last-child td, &:last-child th': {
      border: 0,
    },
  },
  backButton: {
    display: 'flex',
    justifyContent: 'flex-start',
    fontSize: '1.4rem',
    marginBottom: 20,
    fontWeight: 'bold',
    '& a': {
      display: 'flex',
      color: '#011F36',
      gap: 10,
      '& img': {
        width: 10,
      },
    },
  },

  widget: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    gap: 10,
  },
  widgetTypographyValue: {
    textAlign: 'center',
    fontSize: '1.6rem',
  },
  widgetTypographyName: {
    textAlign: 'center',
    fontSize: '1.32rem',
    color: '#0C2D83',
  },
  powerBiWidget: {
    height: 240,
    boxShadow: '0px 3px 6px #00000014',
    borderRadius: 2,
    '@media screen and (min-width:1800px)': {
      height: 268,
    },
  },
  noData: {
    padding: 12,
    fontSize: '1.6rem',
    color: '#545C63',
    display: 'flex',
    justifyContent: 'center',
    opacity: 0.5,
  },
  tableContainer: {
    alignContent: 'flex-start',
  },
  table: {
    /* width */
    '&::-webkit-scrollbar': {
      width: 10,
    },

    /* Track */
    '&::-webkit-scrollbar-track': {
      background: '#F4F4F4',
      borderRadius: 10,
    },

    /* Handle */
    '&::-webkit-scrollbar-thumb': {
      background: '#ccc',
      borderRadius: 10,
    },
    maxHeight: 560,
    '@media (min-width: 800px)': {
      maxHeight: 510,
    },
    '@media (min-width: 1024px)': {
      maxHeight: 767,
    },
    '@media (min-width: 1152px)': {
      maxHeight: 960,
    },
    '@media (min-width: 1280px)': {
      maxHeight: 1100,
    },
    '@media (min-width: 1360px)': {
      maxHeight: 560,
    },
    '@media (min-width: 1400px)': {
      maxHeight: 820,
    },
    '@media (min-width: 1440px)': {
      maxHeight: 600,
    },
    '@media (min-width: 1600px)': {
      maxHeight: 610,
    },
    '@media (min-width: 1680px)': {
      maxHeight: 760,
    },
    '@media (min-width: 1920px)': {
      maxHeight: 625,
    },
  },
  agChartWrapper: {
    width: '180px',
    height: '60px',
  },
  diffrenceSection: {
    display: 'flex',
    gap: 10,
  },
  diffrence: {
    fontWeight: 'bold',
    fontSize: '1.2rem',
  },
  fromDays: {
    fontSize: '1.2rem',
  },
  iconClick: {
    cursor: 'pointer',
  },
  widgetContainer: {
    border: '1px solid #D8D9DB',
    borderRadius: 4,
    backgroundColor: '#fff',
    boxShadow: '0px 3px 6px #00000014',
  },
  widgetContainerHeading: {
    padding: 16,
    paddingBottom: 0,
    borderBottom: '1px solid #D8D9DB',
  },
  widgetContainerTable: {
    // padding: 16,
    paddingTop: 0,
  },
  kpiDetails: {
    borderRadius: 4,
    backgroundColor: '#F3F8FE',
    padding: '2px 16px 5px 16px',
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'row',
    flexWrap: 'nowrap',
  },
  lwDetails: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
  },
  l4wDetails: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
  },
  ytdDetails: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
  },
  targetDetails: {
    display: 'flex',
    justifyContent: 'right',
  },
  target: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  lWRegion: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
    gap: 100,
    '@media (min-width: 700px)': {
      gap: 65,
    },
    '@media (min-width: 1024px)': {
      gap: 95,
    },
    '@media (min-width: 1280px)': {
      gap: 110,
    },
    '@media (min-width: 1360px)': {
      gap: 100,
    },
    '@media (min-width: 1366px)': {
      gap: 100,
    },
    '@media (min-width: 1920px)': {
      gap: 100,
      marginLeft: 170,
    },
  },
  lWSite: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    gap: 60,
    '@media (min-width: 700px)': {
      gap: 40,
    },
    '@media (min-width: 1024px)': {
      gap: 50,
    },
    '@media (min-width: 1280px)': {
      gap: 70,
    },
    '@media (min-width: 1360px)': {
      gap: 60,
    },
    '@media (min-width: 1920px)': {
      gap: 60,
      marginRight: 80,
    },
  },
  kpi: {
    gap: 20,
  },
  kpiName: {
    color: '#333333',
    fontWeight: 600,
    fontSize: '1.2rem',
    textTransform: 'uppercase',
  },
  l4W: {
    color: '#838383',
    fontSize: '1.2rem',
  },
  l4WValue: {
    fontSize: '1.2rem',
    fontWeight: 700,
  },
  ytd: {
    fontSize: '1.2rem',
  },
  kpiHeadingDetails: {
    marginTop: 20,
    padding: 16,
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'row',
    flexWrap: 'nowrap',
  },
  kpiRankingHeading: {
    color: '#333333',
    fontSize: '1.2rem',
    fontWeight: 700,
  },
  radioExport: {
    display: 'flex',
    justifyContent: 'right',
    alignItems: 'center',
  },
  radioButtonRegion: {
    '& .MuiFormControlLabel-label': {
      font: 'normal normal normal 1.2rem Segoe UI',
      letterSpacing: '0px',
      color: '#3D4043',
      opacity: 1,
      margin: '0px 20px 3px -2px',
    },
  },
  radioButtonSite: {
    '& .MuiFormControlLabel-label': {
      font: 'normal normal normal 1.2rem Segoe UI',
      letterSpacing: '0px',
      color: '#3D4043',
      opacity: 1,
      margin: '0px 0px 3px -2px',
    },
    marginRight: '0px',
  },
  descriptionIcon: {
    color: '#003668',
    fontSize: '1rem',
    display: 'flex',
    alignItems: 'center',
  },
  exportButton: {
    border: '1px solid #003668',
    padding: '4px 4px 4px 4px',
    display: 'flex',
    justifyContent: 'center',
    borderRadius: '5px',
    backgroundColor: 'white',
    cursor: 'pointer',
    margin: '0px 0px 0px 25px',
  },
  iconStyling: {
    height: '20px',
    width: '20px',
    paddingRight: '5px',
  },
}));

export default NationalViewDetailsStyle;
