/*
This component contains source code that defines element that will show the uploaded file name on the screen.
It takes file name as input.
*/
import React from 'react';

import CloseIcon from '@mui/icons-material/Close';
import { Typography } from '@mui/material';

import FormAttachmentStyles from '../styles/FormAttachmentStyles';

const FormAttachment = (props) => {
  const { name, onClick } = props;
  const classes = FormAttachmentStyles();

  return (
    <div className={classes.container}>
      <Typography>{name}</Typography>
      <CloseIcon onClick={onClick} />
    </div>
  );
};

export default FormAttachment;
