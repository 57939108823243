import React, { useState } from 'react';

import { Alert, Snackbar, StyledEngineProvider } from '@mui/material';
import ReactDOM from 'react-dom/client';
import './index.css';
import { BrowserRouter } from 'react-router-dom';

import App from './App';
import AuthState from './components/context/authStates';
import reportWebVitals from './reportWebVitals';

const root = ReactDOM.createRoot(document.getElementById('root'));

const Main = () => {
  const [alertStatus, setAlertStatus] = useState(false);
  const [alertMessage, setAlertMessage] = useState('');
  const [alertType, setAlertType] = useState('success');
  const [alertFontSize, setAlertFontSize] = useState('0.90rem');

  // Close the snackbar message
  const handleClose = () => {
    setAlertStatus(false);
  };

  // Display the snackbar message
  const showAlert = (options) => {
    setAlertStatus(true);
    setAlertMessage(options.alertMessage);
    setAlertType(options.alertType);
    if (options.fontSize) {
      setAlertFontSize(options.fontSize);
    }
  };
  return (
    <BrowserRouter>
      <Snackbar
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        open={alertStatus}
        autoHideDuration={6000}
        onClose={handleClose}
      >
        <Alert onClose={handleClose} severity={alertType} sx={{ width: '100%', fontSize: alertFontSize }}>
          {alertMessage}
        </Alert>
      </Snackbar>
      <AuthState showAlert={showAlert}>
        <App showAlert={showAlert} />
      </AuthState>
    </BrowserRouter>
  );
};

root.render(
  <StyledEngineProvider injectFirst>
    <Main />
  </StyledEngineProvider>,
);

reportWebVitals();
