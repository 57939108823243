import { makeStyles } from '@mui/styles';

const HelpItemsStyles = makeStyles(() => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
  },
  row: {
    paddingTop: 5,
    paddingBottom: 5,
  },
}));

export default HelpItemsStyles;
