import { makeStyles } from '@mui/styles';

const LayoutStyles = makeStyles(() => ({
  leftContainer: {
    width: '14.16%',
    maxWidth: '14.16%',
    flexBasis: '14.16%',
  },
  rightContainer: {
    width: '85.84%',
    maxWidth: '85.84%',
    flexBasis: '85.84%',
    padding: '20px 10px 10px 10px',
    marginTop: 83,
  },
  rsmRightContainer: {
    width: '85.84%',
    maxWidth: '85.84%',
    flexBasis: '85.84%',
    padding: '93px 14px 10px 10px',
  },
  historicalRightContainer: {
    padding: '20px 10px 0px 10px',
    paddingTop: '0%',
    '@media screen and (min-width: 1024px)': {
      paddingTop: '0%',
      marginTop: 56,
    },
    '@media screen and (min-width: 1152px)': {
      paddingTop: '0%',
      marginTop: 64,
    },
    '@media screen and (min-width: 1280px)': {
      paddingTop: '0%',
      marginTop: 64,
    },
    '@media screen and (min-width: 1360px)': {
      paddingTop: '0%',
      marginTop: 70,
    },
    '@media screen and (min-width: 1366px)': {
      paddingTop: '0%',
      marginTop: 70,
    },
    '@media screen and (min-width: 1920px)': {
      paddingTop: '1.0%',
    },
  },
  rsmHistoricalRightContainer: {
    padding: '66px 14px 0px 7px',
    paddingTop: '5%',
    '@media screen and (min-width: 1024px)': {
      paddingTop: '0%',
      marginTop: 56,
    },
    '@media screen and (min-width: 1152px)': {
      paddingTop: '0%',
      marginTop: 64,
    },
    '@media screen and (min-width: 1280px)': {
      paddingTop: '0%',
      marginTop: 64,
    },
    '@media screen and (min-width: 1360px)': {
      paddingTop: '0%',
      marginTop: 70,
    },
    '@media screen and (min-width: 1366px)': {
      paddingTop: '0%',
      marginTop: 70,
    },
    '@media screen and (min-width: 1920px)': {
      paddingTop: '1.0%',
    },
  },
  filterContainer: {
    background: '#ffffff',
    padding: '40px 0px 0px 10px',
    height: 'auto',
    position: 'fixed',
    bottom: 0,
    top: 83,
    width: '14.16%',
    maxWidth: '14.16%',
    flexBasis: '14.16%',
  },
  layoutRoot: {
    flex: 1,
  },
  rsmLayoutRoot: {
    flex: 1,
  },
  mainContainer: {
    marginTop: 83,
    padding: '40px 0px 10px 10px',
  },
  rsmMainContainer: {
    marginTop: 83,
    padding: '10px 0px 10px 10px',
  },
  favoriteDropdownContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  favoriteDropdownList: {
    width: 200,
    '& .MuiOutlinedInput-root': {
      color: '#525A63',
      backgroundColor: 'white',
      border: '1px solid rgb(0 77 138)',
      padding: '10px',
      borderRadius: '5px',
      '& .MuiAutocomplete-input': {
        fontSize: '1.2rem',
        padding: '2px',
        color: '#004D8A',
      },
      '& fieldset': {
        fontSize: '1rem',
        border: 'none',
        borderColor: 'rgba(0, 0, 0, 0.23)',
      },
    },
    '& .MuiAutocomplete-hasClearIcon': {
      fontSize: '20px',
    },
    '& .MuiInput-root:after': {
      borderBottom: '1px solid inherit',
    },
    '& .MuiInput-root::hover:not(.Mui-disabled):before': {
      borderBottom: '1px solid inherit',
    },
  },
}));

export default LayoutStyles;
