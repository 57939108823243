import { makeStyles } from '@mui/styles';

const SwitchUserStyles = makeStyles((theme) => ({
  rootContainer: {
    [theme.breakpoints.down('sm')]: {
      margin: 20,
      padding: 20,
    },
    margin: 80,
    padding: 54,
    backgroundColor: '#ffffff',
    borderRadius: 8,
  },
  logoContainer: {
    [theme.breakpoints.down('sm')]: {
      marginBottom: 60,
      gap: 20,
    },
    marginBottom: 100,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    gap: 20,
  },
  logImage: {
    [theme.breakpoints.down('sm')]: {
      height: '2.6rem',
    },
    height: '4rem',
    width: 'auto',
  },
  logName: {
    [theme.breakpoints.down('sm')]: {
      fontSize: '1.4rem',
    },
    fontSize: '2.2rem',
    color: '#0C2D83',
    fontWeight: 'bold',
  },
  designationContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  dropdown: {
    fontSize: 'larger',
    '& .MuiOutlinedInput-root .MuiAutocomplete-tag': {
      margin: 0,
      marginRight: 2,
      height: 29,
      display: 'inline-flex',
      alignItems: 'center',
    },
    '& .MuiInput-root:after': {
      borderBottom: '1px solid inherit',
    },
    '& .MuiInput-root::hover:not(.Mui-disabled):before': {
      borderBottom: '1px solid inherit',
    },
  },
  designation: {
    [theme.breakpoints.down('sm')]: {
      fontSize: '1.2rem',
    },
    color: '#000000',
    fontSize: '1.4rem',
  },
  footerContainer: {
    [theme.breakpoints.down('sm')]: {
      gap: 40,
    },
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    gap: 100,
    padding: '20px 0 0px 0',
    marginTop: 60,
  },
  saveButton: {
    [theme.breakpoints.down('sm')]: {
      paddingLeft: 10,
      paddingRight: 10,
      fontSize: '0.9rem',
    },
    backgroundColor: '#013177',
    textTransform: 'capitalize',
    color: '#fff',
    fontSize: '1.2rem',
    paddingLeft: 60,
    paddingRight: 60,
  },
  skipButton: {
    [theme.breakpoints.down('sm')]: {
      fontSize: '0.9rem',
    },
    color: '#007BFF',
    textTransform: 'none',
    '&:hover': {
      background: '#1976d226',
    },
    fontSize: '1.2rem',
  },
  filterLabel: {
    marginTop: 30,
  },

  dropdownSection: {
    marginTop: 10,
  },

  skipnow: {
    marginTop: 10,
  },
}));
export default SwitchUserStyles;
