import { makeStyles } from '@mui/styles';
const RSMDashboardDetailsStyle = makeStyles((theme) => ({
  layoutMainContainer: {
    paddingTop: 30,
  },
  layoutContainer: {
    paddingTop: 0,
    marginTop: '-18px',
    padding: '12px',
    paddingBottom: '0px',
    display: 'block',
  },
  tableHeading: {
    fontSize: '1.28rem',
    color: '#3D4043',
    opacity: 0.8,
    paddingTop: 1,
    paddingBottom: 1,
    textTransform: 'uppercase',
  },
  tableCell: {
    fontSize: '1.2rem',
    color: '#011F36',
    paddingTop: 1,
    paddingBottom: 1,
  },
  tableRow: {
    // '&:nth-of-type(odd)': {
    //   backgroundColor: '#F4F4F4',
    // },
    // hide last border
    '&:last-child td, &:last-child th': {
      border: 0,
    },
  },
  backButton: {
    display: 'flex',
    justifyContent: 'flex-start',
    fontSize: '1.4rem',
    marginBottom: 20,
    fontWeight: 'bold',
    '& a': {
      display: 'flex',
      color: '#011F36',
      gap: 10,
      '& img': {
        width: 10,
      },
    },
  },
  widgetLayout: {
    display: 'flex',
  },
  widgetContainer: {
    height: 350,
    display: 'flex',
    flexDirection: 'column',
    border: '1px solid #D8D9DB',
    borderRadius: 2,
    backgroundColor: '#fff',
    boxShadow: '0px 3px 6px #00000014',
  },
  widget: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    gap: 10,
  },
  widgetTypographyValue: {
    textAlign: 'center',
    fontSize: '1.6rem',
  },
  widgetTypographyName: {
    textAlign: 'center',
    fontSize: '1.32rem',
    color: '#0C2D83',
  },
  powerBiWidget: {
    height: 240,
    // border: '1px solid #D8D9DB',
    boxShadow: '0px 3px 6px #00000014',
    borderRadius: 2,
    '@media screen and (min-width:1800px)': {
      height: 268,
    },
  },
  exportContainer: {
    display: 'flex',
    justifyContent: 'right',
    cursor: 'pointer',
    height: '40px',
  },
  descriptionIcon: {
    color: 'rgb(25, 118, 210)',
    fontSize: '1rem',
    display: 'flex',
    alignItems: 'center',
  },
  exportButton: {
    border: '1px solid rgb(25, 118, 210)',
    padding: '4px 4px 4px 4px',
    display: 'flex',
    justifyContent: 'center',
    borderRadius: '5px',
    backgroundColor: 'white',
  },
  iconStyling: {
    height: '20px',
    width: '20px',
    paddingRight: '5px',
  },
  noData: {
    padding: 12,
    fontSize: '1.6rem',
    color: '#545C63',
    display: 'flex',
    justifyContent: 'center',
    opacity: 0.5,
  },
  tableContainer: {
    alignContent: 'flex-start',
  },
  table: {
    border: '1px solid #D8D9DB',
    boxShadow: '0px 3px 6px #00000014',
    borderRadius: 2,
    backgroundColor: '#ffffff',
    /* width */
    '&::-webkit-scrollbar': {
      width: 10,
    },

    /* Track */
    '&::-webkit-scrollbar-track': {
      background: '#F4F4F4',
      borderRadius: 10,
    },

    /* Handle */
    '&::-webkit-scrollbar-thumb': {
      background: '#ccc',
      borderRadius: 10,
    },
  },
  agChartWrapper: {
    width: '180px',
    height: '60px',
  },
  diffrenceSection: {
    display: 'flex',
    gap: 10,
  },
  diffrence: {
    fontWeight: 'bold',
    fontSize: '1.2rem',
  },
  fromDays: {
    fontSize: '1.2rem',
  },
  iconClick: {
    cursor: 'pointer',
  },
  widgetLayoutFirst: {
    [theme.breakpoints.up('xs')]: {
      maxWidth: '13.666667%',
      flexBasis: '13.666667%',
    },
  },
  widgetLayoutSecond: {
    [theme.breakpoints.up('xs')]: {
      flexBasis: '86.333333%',
      maxWidth: '86.333333%',
    },
  },
}));

export default RSMDashboardDetailsStyle;
