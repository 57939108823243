import { makeStyles } from '@mui/styles';
const TileStyle = makeStyles(() => ({
  tileContainer: {
    padding: '8px 5px 8px 10px',
  },
  firstRow: {
    cursor: 'default',
    fontSize: '1.2rem',
    fontWeight: 500,
    textTransform: 'uppercase',
    display: 'flex',
    justifyContent: 'space-between',
  },
  rating: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  secondRow: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  innerRow: {
    display: 'flex',
    alignItems: 'center',
    padding: 3,
  },
  last24Hrs: {
    fontWeight: 'bold',
    fontSize: '1.6rem',
    paddingTop: 18,
    paddingBottom: 18,
  },
  targetValue: {
    fontWeight: 'bold',
    marginLeft: 16,
  },
  thirdRow: {
    marginTop: 0,
  },
  fourthRow: {
    marginTop: 7,
  },
  diffrence: {
    fontWeight: 'bold',
    // marginRight: 16,
  },
  fromDays: {
    // marginLeft: 0,
    fontSize: '1.2rem',
  },
  daily: {
    position: 'absolute',
    right: 20,
    bottom: 10,
  },
  agChartWrapper: {
    position: 'absolute',
    display: 'block',
    width: '40%',
    height: '40%',
  },
  overlayRating: {
    // position: 'absolute',
    // top: 4,
    // right: 6,
    height: 35,
    cursor: 'pointer',
  },
  kpiAttr: {
    width: '160',
    fontSize: '1.1rem',
  },
  kpiAttrValue: {
    alignContent: 'end',
    fontWeight: 'bold',
    fontSize: '1.1rem',
  },
  thirdRowChild: {
    marginLeft: 0,
  },
  refreshDate: {
    position: 'absolute',
    right: 20,
  },
}));

export default TileStyle;
