export const pages = () => {
  return [
    { name: 'Daily View ', to: '/home' },
    { name: 'Historical View', to: '/historicalView' },
    { name: 'Alerts', to: '/alerts' },
  ];
};

export const HQpages = () => {
  return [
    { name: 'Historical View', to: '/nationalHistoricalView' },
    { name: 'Alerts', to: '/alerts' },
  ];
};
