import React, { useState, useEffect } from 'react';

import { Typography } from '@mui/material';
import { Button, Link } from '@mui/material';
import { Box } from '@mui/system';
import ToolLinksStyles from '../styles/ToolLinksStyles';
import '../styles/CustomTooltip.css';

const ToolLinks = (props) => {
  const { kpiToolLinks } = props;
  const [kpiToolLinksData, setKpiToolLinksData] = useState([]);
  const classes = ToolLinksStyles();
  //looping through toollinks Object and convert to array of key value pairs
  useEffect(() => {
    if (kpiToolLinks) {
      const toollinks = JSON.parse(kpiToolLinks);
      setKpiToolLinksData(toollinks);
    } else {
      setKpiToolLinksData([]);
    }
  }, [kpiToolLinks]);

  return (
    //display only when toollinks are not null for a KPI
    <>
      {kpiToolLinksData.length !== 0 && (
        <Box className={classes.toolLinkHeader} sx={{ flexGrow: 1 }}>
          <Box sx={{ color: 'white' }} className={classes.dashboardLinksContainer}>
            <Typography className={classes.dashboardLinkText}>DASHBOARD LINKS</Typography>
          </Box>
          <Box className={classes.dashboardLinksContainer}>
            {kpiToolLinksData.map((item, key) => (
              <Button
                key={item.name}
                variant='outlined'
                sx={{ borderColor: 'white' }}
                className={classes.toolLinkButton}
                size='small'
              >
                <Link
                  key={item.name}
                  href={item.link}
                  target='_blank'
                  rel='noreferrer'
                  underline='none'
                  sx={{ color: 'white' }}
                >
                  {item.vpn === 'yes' ? (
                    <div className='ToolLinks_tooltip'>
                      <span>{item.name}</span>
                      <span className='ToolLinks_tooltiptext'>VPN is Required</span>
                    </div>
                  ) : (
                    <div className='ToolLinks_tooltip'>
                      <span>{item.name}</span>
                    </div>
                  )}
                </Link>
              </Button>
            ))}
          </Box>
        </Box>
      )}
    </>
  );
};

export default ToolLinks;
