/*
This component contains source code that defines an pop up modal with some message to the user to prompt for confirmation.
It allows user to provide confirmation with the message provided in the modal.
*/
import React, { useState, useEffect } from 'react';
import { Button, Box } from '@mui/material';
import Modal from './Modal';
import NotificationArchiveModalStyles from '../styles/NotificationArchiveModalStyles';
const NotificationSettingEnableModal = (props) => {
  const { open, handleClose, handleDisableNotificationKpi } = props;
  const classes = NotificationArchiveModalStyles();

  const style = {
    pt: 2,
    px: 4,
    pb: 3,
  };

  return (
    <>
      <Modal hideBackdrop open={open} onClose={handleClose}>
        <Box className={classes.boxStyle} sx={{ ...style }}>
          <div>
            <p>You have subscribed notification for this KPI, by selecting this option you will disable it.</p>
            <p>Are you sure you want to do this?</p>
          </div>
          <div className={classes.modalAction}>
            <Button onClick={handleDisableNotificationKpi} variant='contained' className={classes.yesButton}>
              Yes
            </Button>
            <Button onClick={handleClose} variant='contained' className={classes.noButton}>
              No
            </Button>
          </div>
        </Box>
      </Modal>
    </>
  );
};

export default NotificationSettingEnableModal;
