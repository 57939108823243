/*
This component contains source code that defines a page to display or render power report along with embedded filters.
It allows user to view PowerBI report along with embedded filters.
*/
import React, { useContext } from 'react';

import { makeStyles } from '@mui/styles';
import { models } from 'powerbi-client';
import { PowerBIEmbed } from 'powerbi-client-react';
import PropTypes from 'prop-types';
import AuthContext from '../context/AuthContext';

// Function to apply css
const useStyles = makeStyles(() => ({
  powerBiRoot: {
    marginTop: 0,
    height: 'inherit',
    width: 'inherit',
    minWidth: 'inherit',
    minHeight: 'inherit',
  },
}));

const PowerBiReport = (props) => {
  const { id, KpiId, accessToken, embedUrl, pageName, country, region, multipleLocationType, location } = props;
  const { user } = useContext(AuthContext);
  const classes = useStyles();

  // country filter
  const countryFilter = {
    $schema: 'http://powerbi.com/product/schema#basic',
    target: {
      table: 'master_distinct_locations',
      column: 'country',
    },
    operator: 'In',
    values: country,
    filterType: models.FilterType.BasicFilter,
    requireSingleSelection: true,
  };
  // region filter
  const regionFilter = {
    $schema: 'http://powerbi.com/product/schema#basic',
    target: {
      table: 'master_distinct_locations',
      column: 'region',
    },
    operator: 'In',
    values: region,
    filterType: models.FilterType.BasicFilter,
    requireSingleSelection: true,
  };
  // location type filter
  const locationTypeFilter = {
    $schema: 'http://powerbi.com/product/schema#basic',
    target: {
      table: 'master_distinct_locations',
      column: 'location_type_description',
    },
    operator: 'In',
    values: multipleLocationType,
    filterType: models.FilterType.BasicFilter,
    requireSingleSelection: true,
  };
  // location filter
  const locationsFilter = {
    $schema: 'http://powerbi.com/product/schema#basic',
    target: {
      table: 'master_distinct_locations',
      column: 'location_descp',
    },
    operator: 'In',
    values: location,
    filterType: models.FilterType.BasicFilter,
    requireSingleSelection: true,
  };

  // FillRate Type filter
  const fillRateTypeFilter = {
    $schema: 'http://powerbi.com/product/schema#basic',
    target: {
      table: 'Fill Rate',
      column:
        user.persona.name === 'Site Service Leader - Copack/Repack' && (KpiId === 'SER005' || KpiId === 'SER001')
          ? 'LocTypeDesc'
          : 'fill_rate_type',
    },
    operator: 'In',
    values:
      user.persona.name === 'Regional Warehouse Manager' &&
      (KpiId === 'SER001' || KpiId === 'SER005' || KpiId === 'SER007')
        ? ['ALL_IN']
        : user.persona.name === 'Site Service Leader - Copack/Repack' && (KpiId === 'SER005' || KpiId === 'SER001')
        ? ['COPACK/SUPPLIER']
        : ['TOTAL_RETAIL'],
    filterType: models.FilterType.BasicFilter,
    requireSingleSelection: true,
  };

  // Set all the filter into the array of object
  const filterData = () => {
    let filterProps = [];
    if (location.length > 0) {
      filterProps.push(locationsFilter);
    }
    if (multipleLocationType.length > 0) {
      filterProps.push(locationTypeFilter);
    }
    if (region.length > 0) {
      filterProps.push(regionFilter);
    }
    if (country.length > 0) {
      filterProps.push(countryFilter);
    }
    filterProps.push(fillRateTypeFilter);
    return filterProps;
  };

  return (
    <PowerBIEmbed
      embedConfig={{
        type: 'report', // Supported types: report, dashboard, tile, visual and qna
        id: id,
        pageName: pageName,
        embedUrl: embedUrl,
        accessToken: accessToken,
        tokenType: models.TokenType.Aad,
        filters: filterData(),
        settings: {
          filterPaneEnabled: false,
          navContentPaneEnabled: false,
          background: 0,
        },
      }}
      eventHandlers={
        new Map([
          ['loaded', function () {}],
          ['rendered', function () {}],
          ['error', function (event) {}],
        ])
      }
      cssClassName={classes.powerBiRoot}
      getEmbeddedComponent={(embeddedReport) => {
        window.report = embeddedReport;
      }}
    />
  );
};

PowerBiReport.propTypes = {
  id: PropTypes.string,
  accessToken: PropTypes.string,
  embedUrl: PropTypes.string,
  pageName: PropTypes.string,
  country: PropTypes.array,
  region: PropTypes.array,
  multipleLocationType: PropTypes.array,
  location: PropTypes.array,
  KpiId: PropTypes.string,
};

PowerBiReport.defaultProps = {
  id: '',
  accessToken: '',
  embedUrl: '',
  pageName: '',
  country: [],
  region: [],
  multipleLocationType: [],
  location: [],
  KpiId: '',
};

export default PowerBiReport;
