/*
This component contains source code that defines page which will display Product details of alert in the alerts page.
*/
import React, { useState, useEffect, memo } from 'react';

import { Grid, Link, Divider } from '@mui/material';
import moment from 'moment';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';

import closeIcon from '../assets/close-icon.svg';
import errorIcon from '../assets/error-icon.svg';
import resolveIcon from '../assets/resolve-icon.svg';
import AlertsStyle from '../styles/AlertsStyle';

const AlertsRow = (props) => {
  const {
    alerts,
    persona_flag,
    id,
    createdAt,
    alert_details,
    resolved_at,
    resolved_by,
    resolved_status,
    todayAlert,
    yesterdayAlert,
    kpi_alert_message,
    updateStatus,
    doArchiveAlert,
    kpi_id,
    notificationSetting,
  } = props;
  const classes = AlertsStyle({ day: yesterdayAlert });
  const [message, setMessage] = useState();
  const [alertTimeNotification, setAlertTimeNotification] = useState();
  const navigate = useNavigate();

  // Callback function for alert configure
  useEffect(() => {
    if (alert_details) {
      let message_string = JSON.parse(alert_details);
      let message_data = kpi_alert_message?.message;
      let flag = kpi_alert_message?.flag;
      let obj = Object.values(message_string);
      Object.keys(message_string).map((data, index) => {
        if (data !== 'message') {
          message_data = message_data.replaceAll(`{${data}}`, obj[index]?.value ? obj[index].value : '');
        }
      });
      message_data = message_data.replaceAll(`{id}`, `${id}`);
      message_data = message_data.replaceAll(`{persona_flag}`, `${persona_flag}`);
      if (yesterdayAlert) {
        message_data = message_data.replaceAll(`"tooltip"`, `"tooltip yesterday"`);
      }
      setMessage(message_data);
      let secondMessage = '';

      if (resolved_status === 'ACTIVE' && (todayAlert || yesterdayAlert)) {
        let date = moment.utc(createdAt).format('YYYY-MM-DD HH:mm:ss');
        let stillUtc = moment.utc(date).toDate();
        let alertLocalTime = moment(stillUtc).local().format('YYYY-MM-DD h:mm:ss A'); // Convert into Local Time
        secondMessage = moment(alertLocalTime).fromNow();
      } else if (resolved_status === 'RESOLVED' && (todayAlert || yesterdayAlert)) {
        let date = moment.utc(resolved_at).format('YYYY-MM-DD HH:mm:ss');
        let stillUtc = moment.utc(date).toDate();
        if (todayAlert) {
          let alertLocalTime = moment(stillUtc).local().format('YYYY-MM-DD HH:mm:ss'); // Convert into Local Time
          secondMessage = `Alert Resolved ${moment(alertLocalTime).fromNow()} by ${resolved_by?.fullName}`;
        } else if (yesterdayAlert) {
          let alertLocalTime = moment(stillUtc).local().format('HH:mm'); // Convert into Local Time
          secondMessage = `Alert Resolved yesterday at ${alertLocalTime} by ${resolved_by?.fullName}`;
        }
      }
      setAlertTimeNotification(secondMessage);
    }
  }, [alert_details, resolved_status]);

  // Function for convert text to html
  const createMarkup = (text) => {
    return { __html: text };
  };
  // Function for navigate page to alert details
  window.handleOpenModal = function (alertId, persona_flag, personaMessageFlag) {
    if (persona_flag === 2 || persona_flag === 5) {
      navigate(`/rsmAlertDetails/${alertId}`);
    } else if (personaMessageFlag && persona_flag === 6) {
      navigate(`/rsmAlertDetails/${alertId}/${personaMessageFlag}`);
    } else if (personaMessageFlag) {
      navigate(`/rpsmAlertDetails/${alertId}/${personaMessageFlag}`);
    } else {
      navigate(`/alertDetails/${alertId}`);
    }
    return false;
  };

  return (
    <Grid className={`${classes.alertRowDisplay} ${classes.alertPage}`}>
      <Grid item xs={12} container className={classes.alertRow}>
        <Grid item xs={11} container className={classes.alertMessageGroup}>
          <>
            {resolved_status === 'ACTIVE' ? (
              <img className={classes.iconChange} src={errorIcon} alt='check-icon' />
            ) : (
              <img className={classes.iconChange} src={resolveIcon} alt='check-icon' />
            )}
          </>
          <Grid item xs={11} container className={classes.alertMessage}>
            <Grid
              item
              xs={12}
              className={`${classes.alertMessagePrimary} ${yesterdayAlert && classes.alertMessagePrimaryYesterday}`}
            >
              <div dangerouslySetInnerHTML={createMarkup(message)} />
            </Grid>
            <Grid item xs={12} className={classes.alertMessageSecondary}>
              {alertTimeNotification}
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={1} container className={classes.alertAction}>
          {alerts ? (
            <>
              {resolved_status === 'ACTIVE' && todayAlert ? (
                <Link
                  className={classes.action}
                  underline='none'
                  onClick={() => updateStatus({ id: id, message: message, action: 'resolved' })}
                >
                  Resolve
                </Link>
              ) : resolved_status === 'ACTIVE' && yesterdayAlert ? (
                <Link underline='none'>Resolve</Link>
              ) : (
                <span>Resolved</span>
              )}
            </>
          ) : (
            <Link
              className={classes.action}
              underline='none'
              onClick={() => doArchiveAlert({ id: id, kpi_id: kpi_id, message: message, action: 'unArchive' })}
            >
              Unarchive
            </Link>
          )}

          {alerts && (todayAlert || yesterdayAlert) && (
            <>
              <Divider orientation='vertical' variant='middle' flexItem className={classes.actionDevider} />
              <Link
                underline='none'
                onClick={() =>
                  doArchiveAlert({ id: id, kpi_id: kpi_id, message: message, action: 'archive', notificationSetting })
                }
              >
                <img src={closeIcon} alt='close-icon' className={classes.action} />
              </Link>
            </>
          )}
        </Grid>
      </Grid>
    </Grid>
  );
};

AlertsRow.propTypes = {
  day: PropTypes.string,
  createdAt: PropTypes.string,
  updatedAt: PropTypes.string,
  alert_details: PropTypes.string,
  id: PropTypes.number,
  kpi_id: PropTypes.string,
  location: PropTypes.string,
  resolved_at: PropTypes.string,
  resolved_by: PropTypes.object,
  resolved_status: PropTypes.string,
  pathName: PropTypes.string,
  kpi_alert_message: PropTypes.object,
  notificationSetting: PropTypes.object,
};

AlertsRow.defaultProps = {
  createdAt: '',
  updatedAt: '',
  alert_details: '',
  id: 0,
  kpi_id: '',
  location: '',
  resolved_at: '',
  resolved_by: null,
  resolved_status: '',
  kpi_alert_message: null,
  notificationSetting: null,
};

export default memo(AlertsRow);
