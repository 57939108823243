/*
This component contains source code that will display a list of L0/Executive summary page tiles and tabs in the Home page.
*/
import React, { useEffect, useState, useContext } from 'react';

import { useAppInsightsContext } from '@microsoft/applicationinsights-react-js';
import { useLocation } from 'react-router-dom';

import AuthContext from '../context/AuthContext';
import Dashboard from '../organisms/Dashboard';
import RSMDashboard from '../organisms/RSMDashboard';

const Home = (props) => {
  const { accessToken, user, reportData, tableReportData } = useContext(AuthContext);
  const appInsights = useAppInsightsContext();
  const [reports, setReports] = useState([]);
  const [tableReports, setTableReports] = useState([]);
  const location = useLocation();
  // Tile KPI's records
  useEffect(() => {
    setReports(reportData);
  }, [reportData]);

  // Tabular KPI's records
  useEffect(() => {
    setTableReports(tableReportData);
  }, [tableReportData]);

  // Callback function for tracking the page
  useEffect(() => {
    appInsights.trackPageView({
      uri: window.location.href,
      refUri: window.location.href,
      properties: {
        personaName: user.persona.name,
      },
    });
  }, [location]);

  return (
    <>
      {user.persona.layoutId === 1 ? (
        <Dashboard
          reports={reports}
          tableReports={tableReports}
          accessToken={accessToken}
          personaId={user.personaId}
          {...props}
        />
      ) : (
        <RSMDashboard
          reports={reports}
          tableReports={tableReports}
          accessToken={accessToken}
          personaId={user.personaId}
          {...props}
        />
      )}
    </>
  );
};

export default Home;
