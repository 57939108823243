/*
This component contains source code that defines an accordion element.
It takes content and title as input and returns an accordion element that can be displayed on the screen.
It also takes search text as input and hightlights the matching text available in accordion details.
*/
import React from 'react';
import { useState } from 'react';

import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import { Accordion, AccordionDetails, AccordionSummary } from '@mui/material';
import Highlighter from 'react-highlight-words';
import AccordionStyles from '../styles/AccordionStyles';
import { HelpItems } from './HelpCentre/HelpItems';

const AccordionAtom = (props) => {
  const { highlightedWord, id, searchedWordMatches, content, title } = props;
  const [expanded, setExpanded] = useState(false);
  const classes = AccordionStyles();

  return (
    <Accordion className={classes.container} expanded={searchedWordMatches || expanded}>
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls='panel1a-content'
        id={`panel1a-header-${id}`}
        onClick={() => setExpanded(!expanded)}
      >
        <FiberManualRecordIcon className={classes.icon} fontSize='15px' />
        <Highlighter
          highlightStyle={{
            borderRadius: 5,
          }}
          searchWords={[highlightedWord]}
          autoEscape={true}
          textToHighlight={title}
          className={classes.title}
        />
      </AccordionSummary>
      <AccordionDetails>
        <HelpItems highlightedWord={highlightedWord} content={content} />
      </AccordionDetails>
    </Accordion>
  );
};

export default AccordionAtom;
