import React, { useEffect, useState } from 'react';

import { Button, Grid, Typography, Box } from '@mui/material';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import { find } from 'lodash';

import { ReactComponent as CancelIcon } from '../assets/x.svg';
import Drawer from '../atom/Drawer';
import Dropdown from '../atom/Dropdown';
import MultiSelect from '../atom/MultipleSelect';
import ExportDrawerStyles from '../styles/ExportDrawerStyles';

const ExportDrawer = (props) => {
  const { visible, onClose, tableReports, onExportFile, favorite, toggleRowStar } = props;
  const [visualType, setVisualType] = useState('both');
  const [fileType, setFileType] = useState('image');
  const [functionalCategoryList, setFunctionalCategoryList] = useState([]);
  const [defaultFunctionalCategory, setDefaultFunctionalCategory] = useState({
    label: 'Choose one',
    activeIndex: null,
    value: null,
  });
  const [defaultKpi, setDefaultKpi] = useState([]);
  const [KpiList, setKpiList] = useState([]);
  const classes = ExportDrawerStyles();
  const handleFileTypeChange = (data) => {
    setFileType(data);
  };

  useEffect(() => {
    if (visible) {
      setVisualType('both');
      setFileType('image');
      let item = [];
      tableReports.map((elem, index) => {
        if (index === 0) {
          onSelectFunctionalCategory({ value: elem.id, activeIndex: index, label: elem.name });
        }
        item.push({ value: elem.id, activeIndex: index, label: elem.name });
      });
      setFunctionalCategoryList(item);
    }
  }, [tableReports, visible]);

  // Call Functional Category select function
  const onSelectFunctionalCategory = (data) => {
    if (data.value) {
      let item = [];
      let records = find(tableReports, { id: data.value });
      if (favorite === true && records.name === 'Summary') {
        //code for filtering favorites for export
        if (favorite) {
          tableReports.map((records) => {
            if (records.name !== 'Summary') {
              records.data
                .filter((report) => toggleRowStar['table'][report.reportDetail.kpi_id])
                .map((elem) => item.push({ value: elem.reportDetail.kpi_id, label: elem.kpiName }));
            }
          });
        }
      } else {
        //code for filtering favorites for export
        if (favorite) {
          records.data
            .filter((report) => toggleRowStar['table'][report.reportDetail.kpi_id])
            .map((elem) => {
              item.push({ value: elem.reportDetail.kpi_id, label: elem.kpiName });
            });
        } else {
          records.data.map((elem) => {
            item.push({ value: elem.reportDetail.kpi_id, label: elem.kpiName });
          });
        }
      }
      setKpiList(item);
      setDefaultFunctionalCategory({ value: data.value, activeIndex: data.activeIndex, label: data.label });
      setDefaultKpi([]);
    }
  };

  // Function for select multiple region dropdown list
  const onSelectMultipleKpi = (data) => {
    let joinData = [];
    //to set and filter out default kpi in favorites page
    if (favorite) {
      data
        .filter((report) => toggleRowStar['table'][report.value])
        .map((data) => {
          joinData.push({ value: data.value, label: data.label });
        });
    }
    // To set default kpi in historical view page
    else {
      data.map((data) => {
        joinData.push({ value: data.value, label: data.label });
      });
    }
    setDefaultKpi(joinData);
  };

  const onExport = () => {
    let data = {
      fileType: fileType,
      functionalCategory: defaultFunctionalCategory,
      kpiList: defaultKpi,
      visualType: visualType,
    };
    if (defaultFunctionalCategory.value !== null && defaultKpi.length > 0) {
      onExportFile(data);
    }
  };

  return (
    <Drawer
      open={visible}
      handleClose={onClose}
      PaperProps={{
        sx: { width: '31%' },
      }}
    >
      <Box>
        {
          <>
            <Grid item container spacing={0} xl={12} xs={12} md={12} className={classes.headerContainer}>
              <Grid item xl={6} xs={6} md={6}>
                <Typography className={classes.printFile}>Export Files</Typography>
              </Grid>
              <Grid item xl={6} xs={6} md={6} className={classes.rightIconGrid}>
                <div className={classes.closeButton} onClick={onClose}>
                  <CancelIcon style={{ margin: '5px', height: '15px', width: '15px' }} />
                </div>
              </Grid>
            </Grid>
          </>
        }
        <Grid item container spacing={0} xl={12} xs={12} md={12} className={classes.kpiSelectContainer}>
          <Grid item container spacing={0} xl={12} xs={12} md={12} className={classes.kpiSelectContainerDiv}>
            <Grid item xl={12} xs={12} md={12}>
              <Typography className={classes.secondHeading}>File Type</Typography>
            </Grid>
            <Grid item xl={12} xs={12} md={12}>
              <Grid item container spacing={0} xl={12} xs={12} md={12}>
                <Grid
                  item
                  xl={6}
                  xs={6}
                  md={6}
                  onClick={() => handleFileTypeChange('image')}
                  className={fileType === 'image' ? classes.activeButton : classes.nonactiveButton}
                  style={{ borderRadius: '5px 0px 0px 5px' }}
                >
                  <Typography className={fileType === 'image' ? classes.activeText : classes.nonactiveText}>
                    Image File
                  </Typography>
                </Grid>
                <Grid
                  item
                  xl={6}
                  xs={6}
                  md={6}
                  onClick={() => handleFileTypeChange('excel')}
                  className={fileType === 'image' ? classes.nonactiveButton : classes.activeButton}
                  style={{ borderRadius: '0px 5px 5px 0px' }}
                >
                  <Typography className={fileType === 'image' ? classes.nonactiveText : classes.activeText}>
                    Excel File
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xl={12} xs={12} md={12}>
              {fileType === 'image' ? (
                <Typography className={classes.thirdHeading}>Image download in .PNG format</Typography>
              ) : (
                <Typography className={classes.thirdHeading}>Raw Data Export in Excel format</Typography>
              )}
            </Grid>
          </Grid>
          <Grid item container spacing={0} xl={12} xs={12} md={12} className={classes.kpiSelectContainerDiv}>
            <Grid item xl={12} xs={12} md={12}>
              <Typography className={classes.secondHeading}>Functional Category</Typography>
            </Grid>
            <Grid item xl={12} xs={12} md={12}>
              <Dropdown
                options={functionalCategoryList}
                getOptionLabel={(option) => (option.label ? option.label : defaultFunctionalCategory.label)}
                isOptionEqualToValue={(option, value) => option.value === value}
                onChange={(_e, value) => onSelectFunctionalCategory(value)}
                value={defaultFunctionalCategory}
                className={classes.dropdown}
                popperbottom={53}
              />
            </Grid>
            <Grid item xl={12} xs={12} md={12}>
              <Typography className={classes.thirdHeading}>
                Indicates the different supply chain functional tabs
              </Typography>
            </Grid>
          </Grid>
          <Grid item container spacing={0} xl={12} xs={12} md={12} className={classes.kpiSelectContainerDiv}>
            <Grid item xl={12} xs={12} md={12}>
              <Typography className={classes.secondHeading}>KPIs</Typography>
            </Grid>
            <Grid item xl={12} xs={12} md={12}>
              <MultiSelect
                limitTags={2}
                size='medium'
                items={KpiList}
                selectAllLabel='ALL'
                onSelectChange={onSelectMultipleKpi}
                defaultData={defaultKpi}
                className={classes.dropdown}
                chipExportFile={true}
                defaultAllSelected={true}
              />
            </Grid>
          </Grid>
          {fileType === 'excel' && (
            <Grid item container spacing={2} xl={12} xs={12} md={12} className={classes.kpiSelectContainerDiv}>
              <Grid item xl={12} xs={12} md={12}>
                <Typography className={classes.secondHeading} style={{ marginBottom: 2 }}>
                  Visual Type
                </Typography>
                <FormControl>
                  <RadioGroup
                    aria-labelledby='demo-controlled-radio-buttons-group'
                    name='controlled-radio-buttons-group'
                    row
                    value={visualType}
                    onChange={(event, value) => {
                      setVisualType(value);
                    }}
                  >
                    <FormControlLabel
                      value='tile'
                      control={<Radio />}
                      label='Tile Only'
                      sx={{
                        '.MuiFormControlLabel-label': {
                          font: 'normal normal normal 19.5px Segoe UI',
                          letterSpacing: '0px',
                          color: '#3D4043',
                          opacity: 1,
                          margin: '0px 20px 3px -2px',
                        },
                      }}
                    />
                    <FormControlLabel
                      value='graph'
                      control={<Radio />}
                      label='Graph Only'
                      sx={{
                        '.MuiFormControlLabel-label': {
                          font: 'normal normal normal 19.5px Segoe UI',
                          letterSpacing: '0px',
                          color: '#3D4043',
                          opacity: 1,
                          margin: '0px 20px 3px -2px',
                        },
                      }}
                    />
                    <FormControlLabel
                      value='both'
                      control={<Radio />}
                      label='Both'
                      sx={{
                        '.MuiFormControlLabel-label': {
                          font: 'normal normal normal 19.5px Segoe UI',
                          letterSpacing: '0px',
                          color: '#3D4043',
                          opacity: 1,
                          margin: '0px 20px 3px -2px',
                        },
                      }}
                    />
                  </RadioGroup>
                </FormControl>
              </Grid>
            </Grid>
          )}
        </Grid>
        <Grid item container spacing={0} xl={12} xs={12} md={12} className={classes.buttonContainer}>
          <Button variant='contained' size='large' fullWidth onClick={onExport} className={classes.saveButton}>
            Export File
          </Button>
        </Grid>
      </Box>
    </Drawer>
  );
};

export default ExportDrawer;
