import React, { useState } from 'react';

import RSMTileContext from './RSMTileContext';

// Create a Tilestate
const RSMTileStates = ({ children }) => {
  const [activeTile, setActiveTileState] = useState(null);
  const [activeTab, setActiveTabState] = useState(0);

  const setActiveTile = (tile) => {
    setActiveTileState(tile);
  };

  const setActiveTab = (tab) => {
    setActiveTabState(tab);
  };

  const [activeTileHqDaily, setActiveTileHqDailyState] = useState(null);
  const [activeTabHqDaily, setActiveTabHqDailyState] = useState(0);

  const setActiveTileHqDaily = (tile) => {
    setActiveTileHqDailyState(tile);
  };

  const setActiveTabHqDaily = (tab) => {
    setActiveTabHqDailyState(tab);
  };

  return (
    <RSMTileContext.Provider
      value={{
        activeTile,
        setActiveTile,
        activeTab,
        setActiveTab,
        activeTileHqDaily,
        activeTabHqDaily,
        setActiveTileHqDaily,
        setActiveTabHqDaily,
      }}
    >
      {children}
    </RSMTileContext.Provider>
  );
};

export default RSMTileStates;
