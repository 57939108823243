/*
This component contains source code that will display a list of alerts for a persona based on location/region in the application.
*/
import React, { useContext, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { useAppInsightsContext } from '@microsoft/applicationinsights-react-js';

import AuthContext from '../context/AuthContext';
import AlertsSection from '../organisms/AlertsSection';

const Alerts = (props) => {
  const { archiveData, todayAlerts, yesterdayAlerts, user } = useContext(AuthContext);
  const reactLocation = useLocation();
  const appInsights = useAppInsightsContext();

  // Callback function for tracking the page
  useEffect(() => {
    appInsights.trackPageView({
      uri: window.location.href,
      refUri: window.location.href,
      properties: {
        personaName: user.persona.name,
      },
    });
  }, [reactLocation]);

  return (
    <>
      {reactLocation.pathname === '/alerts' ? (
        <AlertsSection todayAlerts={todayAlerts} yesterdayAlerts={yesterdayAlerts} alerts {...props} />
      ) : (
        <AlertsSection archiveData={archiveData} archiveAlerts {...props} />
      )}
    </>
  );
};

export default Alerts;
