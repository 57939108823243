/*
The following code contains source code that defines all the api functions for alerts feature.
It creates functions for handling alert scenarios.
fetch all alerts, fetch Alerts By Id, Update statuses for alerts like resolve, acrchive and some undo actions with respect to alerts
*/
import request from '../helper/apiRequest';

// Function to get all alerts based on logged in user. input  = user Id
export const getAlerts = async (userId) => {
  try {
    const { data } = await request.get(`/alerts?userId=${userId}`);
    return data;
  } catch (e) {
    return { error: true, message: e.message };
  }
};
// Function to update alerts as resolved/unresolved
export const updateAlerts = async (props) => {
  try {
    const res = await request.put(`/alerts/resolve`, {}, props);
    return res;
  } catch (e) {
    return { error: true, message: e.message };
  }
};
// Function to update alerts as archived/unarchived
export const archiveAlert = async (props) => {
  try {
    return await request.put(`/alerts/archive?action=${props.action}`, {}, props);
  } catch (e) {
    return { error: true, message: e.message };
  }
};
// Function to get alert id and persona flag
export const getAlertById = async (props) => {
  try {
    const { data } = await request.get(
      props.message
        ? `/alertById?id=${props.id}&persona_flag=${props.persona_flag}&message=${props.message}`
        : `/alertById?id=${props.id}&persona_flag=${props.persona_flag}`,
    );
    return data;
  } catch (e) {
    return { error: true, message: e.message };
  }
};
