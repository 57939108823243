/* 
This file creates a reducer function for Help feature in the application.
It will have initial state and Help types used in the Help feature.
*/
import React, { useReducer } from 'react';

import HelpItemContext from './HelpItemContext';
import { createHelpItem, getAllHelpItem } from '../../actions/helpItem';
// add the details for the Resource Library
export const CREATE_HELP_ITEM = 'CREATE_HELP_ITEM';
export const GET_ALL_HELP_ITEM = 'GET_ALL_HELP_ITEM';

// Reducer
const HelpItemReducer = (state, action) => {
  const { type, payload } = action;
  switch (type) {
    case CREATE_HELP_ITEM:
      return {
        ...state,
        helpItem: payload,
      };
    case GET_ALL_HELP_ITEM:
      return {
        ...state,
        helpItemList: payload,
      };
    default:
      return state;
  }
};
const HelpItemStates = ({ children }) => {
  // Initial state
  const initialState = {
    helpItem: {},
    helpItemList: [],
  };

  const [state, dispatch] = useReducer(HelpItemReducer, initialState);

  // Function to call api of Help
  const addHelpItem = async (helpItemData) => {
    const data = await createHelpItem(helpItemData);
    dispatch({
      type: CREATE_HELP_ITEM,
      payload: data,
    });
  };

  // Function to get and set all Help Items
  const getAllHelpItemList = async (helpItemData) => {
    const data = await getAllHelpItem(helpItemData);
    dispatch({
      type: GET_ALL_HELP_ITEM,
      payload: data,
    });
  };

  return (
    <HelpItemContext.Provider
      value={{
        helpItem: state.helpItem,
        helpItemList: state.helpItemList,
        addHelpItem,
        getAllHelpItemList,
      }}
    >
      {children}
    </HelpItemContext.Provider>
  );
};

export default HelpItemStates;
