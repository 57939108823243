/*
This component contains source code that defines an table with sorting functionality.
It takes headers, sorting input and returns returns a table that can be displayed on the screen.
*/
import * as React from 'react';
import PropTypes from 'prop-types';
import { TableCell, TableHead, TableRow, TableSortLabel } from '@mui/material';
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';
import EnhancedTableHeadStyle from '../styles/EnhancedTableHeadStyle';
import '../styles/CustomTooltip.css';

const EnhancedTableHead = (props) => {
  const { headCells, order, orderBy, onRequestSort, size, backgroundcolor, onHandleExport } = props;
  const classes = EnhancedTableHeadStyle({ backgroundcolor: backgroundcolor });

  // Function to sort table rows based on column
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell, index) => {
          return (
            <>
              <TableCell
                size={size}
                key={headCell.id}
                align={headCell.align}
                padding={headCell.disablePadding ? 'none' : 'normal'}
                sortDirection={orderBy === headCell.id ? order : false}
                className={classes.tableHeading}
              >
                {headCell.sortable ? (
                  <TableSortLabel
                    active
                    direction={orderBy === headCell.id ? order : 'asc'}
                    onClick={createSortHandler(headCell.id)}
                  >
                    {headCell.label}
                  </TableSortLabel>
                ) : (
                  headCell.label
                )}
              </TableCell>
              {headCells.length - 1 === index && (
                <TableCell
                  size={size}
                  key={`lastCell-${index}`}
                  align={headCell.align}
                  className={classes.tableHeading}
                >
                  <div className='Ranking_tooltip'>
                    <span>
                      <FileDownloadOutlinedIcon
                        fontSize='large'
                        style={{ cursor: 'pointer' }}
                        onClick={onHandleExport}
                      />
                    </span>
                    <span className='Ranking_tooltiptext'>Export</span>
                  </div>
                </TableCell>
              )}
            </>
          );
        })}
      </TableRow>
    </TableHead>
  );
};

EnhancedTableHead.propTypes = {
  onRequestSort: PropTypes.func.isRequired,
  order: PropTypes.oneOf(['asc', 'desc']).isRequired,
  orderBy: PropTypes.string.isRequired,
};
EnhancedTableHead.defaultProps = {
  backgroundcolor: '#F4F4F4',
};
export default EnhancedTableHead;
