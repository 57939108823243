/*
This component contains source code that will display usage statistics report in the application.
*/
import React, { useContext, useEffect } from 'react';
import { Grid, Box } from '@mui/material';
import AuthContext from '../context/AuthContext';
import PropTypes from 'prop-types';
import PowerBiReport from '../atom/PowerBiReport';
import StatisticsStyles from '../styles/UsageStatistics';
import { useAppInsightsContext } from '@microsoft/applicationinsights-react-js';
import LayoutStyles from '../styles/LayoutStyles';

const AppStatistics = () => {
  const { accessToken, user } = useContext(AuthContext);
  const appInsights = useAppInsightsContext();
  const tileId = 'b1feb888-6154-4dde-8de0-bb991862b89a';
  const embedUrl = 'https://app.powerbi.com/reportEmbed?reportId=b1feb888-6154-4dde-8de0-bb991862b89a';
  const pageName = 'ReportSection4beab60d87f449f54c2a';
  const classes = StatisticsStyles();
  const layoutClasses = LayoutStyles();

  // Callback function for tracking the page
  useEffect(() => {
    appInsights.trackPageView({
      uri: window.location.href,
      refUri: window.location.href,
      properties: {
        personaName: user.persona.name,
      },
    });
  }, []);

  return (
    <Grid
      xs={12}
      item
      spacing={0.5}
      container
      flexDirection='column'
      alignContent='space-around'
      className={`${user.persona.layoutId === 1 ? layoutClasses.mainContainer : layoutClasses.rsmMainContainer}`}
    >
      <Grid item xl={12} xs={12} md={12}>
        <Box xs={12} className={classes.boxStyle}>
          <PowerBiReport accessToken={accessToken} id={tileId} embedUrl={embedUrl} pageName={pageName} />
        </Box>
      </Grid>
    </Grid>
  );
};

AppStatistics.propTypes = {
  tileId: PropTypes.string,
  accessToken: PropTypes.string,
  embedUrl: PropTypes.string,
};

export default AppStatistics;
