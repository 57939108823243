import request from '../helper/apiRequest';

//Function to get historical tiles based on KPI
export const getHistoricalView = async (userId, pageView, region, locationType, location) => {
  try {
    let locationTypeQuery = '';
    let locationQuery = '';
    let regionQuery = '';

    if (locationType) {
      locationTypeQuery = `&locationType=` + encodeURIComponent(locationType);
    }
    if (region) {
      regionQuery = `&region=` + encodeURIComponent(region);
    }
    if (location) {
      locationQuery = `&location=` + encodeURIComponent(location);
    }
    let viewQuery = `&view=${pageView}`;
    const data = await request.get(
      `/historical/historicalTiles?userId=${userId}${regionQuery}${locationTypeQuery}${locationQuery}${viewQuery}`,
    );
    return data;
  } catch (e) {
    return { error: true, message: e.message };
  }
};

//Function to update tiles as favourite based on KPI
export const setHistoricalToggle = async (props) => {
  try {
    const data = await request.post('/historical/starToggle', {}, props);
    return data;
  } catch (e) {
    return {
      error: true,
      message: 'Favorites could not be saved, please try again.',
      data: null,
    };
  }
};

//Function to get tiles as favourite based on KPI
export const getHistoricalFavorites = async (userId, locationType, location, region) => {
  try {
    let locationTypeQuery = '';
    let locationQuery = '';
    let regionQuery = '';
    if (locationType) {
      locationTypeQuery = `&locationType=` + encodeURIComponent(locationType);
    }
    if (region) {
      regionQuery = `&region=` + encodeURIComponent(region);
    }
    if (location) {
      locationQuery = `&location=` + encodeURIComponent(location);
    }
    const data = await request.get(
      `/historical/historicalFavorites?userId=${userId}${locationTypeQuery}${locationQuery}${regionQuery}`,
    );
    return data;
  } catch (e) {
    return { error: true, message: e.message };
  }
};

//Function to get historical tiles details
export const getHistoricalViewDetails = async (userId, tileId, region, locationTypeAll, multipleLocationType, view) => {
  try {
    let locationTypeAllQuery = '';
    let multipleLocationTypeQuery = '';
    let regionQuery = '';
    if (locationTypeAll) {
      locationTypeAllQuery = `&locationTypeAll=${locationTypeAll}`;
    }
    if (multipleLocationType) {
      multipleLocationTypeQuery = `&multipleLocationType=${multipleLocationType}`;
    }
    if (region) {
      regionQuery = `&region=` + encodeURIComponent(region);
    }
    const data = await request.get(
      `/historical/historicalDetails?userId=${userId}&tileId=${tileId}&view=${view}${regionQuery}${locationTypeAllQuery}${multipleLocationTypeQuery}`,
    );
    return data;
  } catch (e) {
    return { error: true, message: e.message };
  }
};
