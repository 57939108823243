import { makeStyles } from '@mui/styles';

const FormAttachmentStyles = makeStyles(() => ({
  container: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',

    backgroundColor: '#EDEDED',
    borderRadius: 5,
    padding: 5,
    marginTop: 8,
    marginBottom: 8,
  },
}));

export default FormAttachmentStyles;
