import React, { useEffect, useState, useContext } from 'react';
import { useAppInsightsContext } from '@microsoft/applicationinsights-react-js';

import AuthContext from '../context/AuthContext';
import FavoritesContext from '../context/FavoritesContext';
import NationalViewFavoritesDashoard from '../organisms/NationalViewFavoritesDashboard';

const NationalViewFavorites = (props) => {
  const { accessToken, user } = useContext(AuthContext);
  const { historicalFavorites } = useContext(FavoritesContext);
  const [tableReports, setTableReports] = useState([]);
  // Tabular KPI's records
  useEffect(() => {
    setTableReports(historicalFavorites);
  }, [historicalFavorites]);

  return (
    <NationalViewFavoritesDashoard
      tableReports={tableReports}
      accessToken={accessToken}
      personaId={user.personaId}
      {...props}
    />
  );
};
export default NationalViewFavorites;
