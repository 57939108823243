/*
This component contains source code that defines an loader
It takes boolean value as input and returns loader that can be displayed on the screen.
*/
import React, { useState } from 'react';
import { Backdrop, CircularProgress } from '@mui/material';
import { useEffect } from 'react';

const Loader = (props) => {
  const { loadingStatus } = props;
  const [open, setOpen] = useState(false);

  // Callback function for loader hide/show
  useEffect(() => {
    if (loadingStatus) {
      setOpen(!open);
    } else {
      setOpen(false);
    }
  }, [loadingStatus]);

  return (
    <div>
      <Backdrop
        sx={{ backgroundColor: 'rgba(0,0,0,0)', color: '#004D8A', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={open}
      >
        <CircularProgress color='inherit' />
      </Backdrop>
    </div>
  );
};

export default Loader;
