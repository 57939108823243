import { makeStyles } from '@mui/styles';
const AppHelpStyles = makeStyles((theme) => ({
  richText: {
    display: 'flex',
    flexDirection: 'column',
    height: 550,
  },
  footerContainer: {
    display: 'flex',
    justifyContent: 'center',
  },

  saveButton: {
    [theme.breakpoints.down('sm')]: {
      paddingLeft: 10,
      paddingRight: 10,
      fontSize: '0.9rem',
    },
    backgroundColor: '#013177',
    textTransform: 'capitalize',
    color: '#fff',
    fontSize: '1.2rem',
    paddingLeft: 60,
    paddingRight: 60,
  },
  appHelpLayoutContainer: {
    marginTop: 24,
    gap: 20,
  },
}));

export default AppHelpStyles;
