/* 
  The following code contains configuration settings required for the application.
  reportUrl, redirectUri, centralId,clientId of the application, authority used for authentication for Blob Storage, connectionString for blob container
  tablenames to connect with for getting PowerBi reports are configured.
*/
const REDIRECT_URL = () => {
  // return localhost if it is running on localhost
  if (window.location.href.includes('localhost')) {
    return 'http://localhost:3000';
  }
  let redirectUri = '';
  let urlData = window.location.href.split('/');
  redirectUri = 'https://' + urlData[2];
  return redirectUri;
};

const config = {
  reportUrl: 'https://app.powerbi.com/reportEmbed?reportId=',
  redirectUri: REDIRECT_URL(),
  centralId: '3d14aa3d-d68b-45a3-9bfb-7af870d7b4e3',
  clientId: '476c7a0e-2e9c-43d3-883e-5abd8bad02b4',
  authority: 'https://login.microsoftonline.com/42cc3295-cd0e-449c-b98e-5ce5b560c1d3',
  connectionString: 'a8efd2a7-ac05-43e1-ad6d-6a73f55cbf31',
  API_URL: 'https://api.cshub.mypepsico.com/api/v1',
  newCountry: `master_distinct_locations/`,
  newLocation: `master_distinct_locations/`,
  newRegion: `master_distinct_locations/`,
  newLocationType: `master_distinct_locations/`,
};

export default config;
