/*
The following code contains source code that defines all the api functions for Help feature.
It creates functions for handling help scenarios.
createHelpItem, getAllHelpItem
*/
import request from '../helper/apiRequest';

// Function to create help
export const createHelpItem = async (helpItemData) => {
  try {
    const { data } = await request.post(`/help/create`, {}, helpItemData);
    return data;
  } catch (e) {
    return {
      error: true,
      message:
        'Something went wrong, please try reloading the page. If the problem persists, check your internet connection.',
      data: null,
    };
  }
};
// Function to get all help
export const getAllHelpItem = async () => {
  try {
    const { data } = await request.get(`/help/getAllHelpItem`);
    return data;
  } catch (e) {
    return { error: true, message: e.message };
  }
};
