import React, { useContext, useEffect } from 'react';

import { Grid } from '@mui/material';
import { Outlet } from 'react-router-dom';
import { useLocation } from 'react-router-dom';

import AuthContext from '../context/AuthContext';
import LayoutStyles from '../styles/LayoutStyles';

import Header from './Header';
import setBodyColor from '../atom/SetBodyColor';

const LayoutHistorical = (props) => {
  const classes = LayoutStyles();
  const { user } = useContext(AuthContext);
  const location = useLocation();

  // Callback function for set body color
  useEffect(() => {
    setBodyColor({ color: '#f3f8ff' });
  }, []);

  return location.pathname.includes('hqDetails') ? (
    <div class='main-container'>
      <Header />
      <Grid
        item
        container
        xl={12}
        xs={12}
        md={12}
        className={user.persona.layoutId === 1 ? classes.layoutRoot : classes.rsmLayoutRoot}
      >
        <Outlet
          context={{
            user: user,
          }}
        />
      </Grid>
    </div>
  ) : (
    <>
      <div class='main-container'>
        <Header />
      </div>
      <Grid
        item
        container
        xl={12}
        xs={12}
        md={12}
        className={user.persona.layoutId === 1 ? classes.layoutRoot : classes.rsmLayoutRoot}
      >
        <Outlet
          context={{
            user: user,
          }}
        />
      </Grid>
    </>
  );
};

export default LayoutHistorical;
