import React, { useState, useReducer } from 'react';

import FavoritesContext from './FavoritesContext';

import { getHistoricalFavorites } from '../../actions/historicalView';
import { getDailyViewFavorites } from '../../actions/home';
import config from '../../config';
// add the details for the Resource Library
const GET_HISTORICAL_FAVORITES = 'GET_HISTORICAL_FAVORITES';
const REFRESH_TIME = 'REFRESH_TIME';
const GET_DAILY_VIEW_FAVORITES = 'GET_DAILY_VIEW_FAVORITES';
const GET_TABLE_DAILY_VIEW_FAVORITES = 'GET_TABLE_DAILY_VIEW_FAVORITES';

const HistoricalFavoriteReducer = (state, action) => {
  const { type, payload } = action;
  switch (type) {
    case GET_HISTORICAL_FAVORITES:
      return {
        ...state,
        historicalFavorites: payload,
      };
    case REFRESH_TIME:
      return {
        ...state,
        refreshTime: payload,
      };
    case GET_DAILY_VIEW_FAVORITES:
      return {
        ...state,
        dailyviewFavorites: payload,
      };
    case GET_TABLE_DAILY_VIEW_FAVORITES:
      return {
        ...state,
        dailyviewtableFavorites: payload,
      };
    default:
      return state;
  }
};
const FavoritesStates = ({ children, showAlert }) => {
  const { reportUrl, centralId } = config;
  const embedUrl = `${reportUrl}${centralId}`;
  const initialState = {
    historicalFavorites: [],
    dailyviewFavorites: [],
    dailyviewtableFavorites: [],
  };

  const [activeTile, setActiveTileState] = useState(null);
  const [activeTab, setActiveTabState] = useState(0);
  const [activeTileHistoricalFav, setActiveTileHistoricalFavState] = useState(null);
  const [activeTabHistoricalFav, setActiveTabHistoricalFavState] = useState(0);

  const setActiveTile = (tile) => {
    setActiveTileState(tile);
  };

  const setActiveTab = (tab) => {
    setActiveTabState(tab);
  };

  const setActiveTileHistoricalFav = (tile) => {
    setActiveTileHistoricalFavState(tile);
  };

  const setActiveTabHistoricalFav = (tab) => {
    setActiveTabHistoricalFavState(tab);
  };

  const [state, dispatch] = useReducer(HistoricalFavoriteReducer, initialState);

  const getHistoricalFav = async (resp, region, locationType, location) => {
    const respData = await getHistoricalFavorites(resp.id, locationType, location, region);
    if (respData.error) {
      showAlert({
        alertMessage: respData.message,
        alertType: 'error',
      });
    }

    const newTableData = respData.data?.tabReports?.map((item) => {
      const newTableObj = item.data.map((value) => {
        return {
          id: value.id,
          kpiName: value.name,
          reportDetail: value?.historical_view_details[0],
          fav: value.fav,
          pgtData: value.persona_reports.pgtData,
          kpiToolLinks: value?.kpiToolLinks?.KPI_TOOL_LINKS,
        };
      });
      return {
        id: item.id,
        name: item.name,
        data: newTableObj,
      };
    });

    dispatch({
      type: GET_HISTORICAL_FAVORITES,
      payload: newTableData,
    });
    dispatch({
      type: REFRESH_TIME,
      payload: respData.data?.refreshTime,
    });
  };
  const getFavorites = async (resp, locationType, location) => {
    const respData = await getDailyViewFavorites(resp.id, locationType, location);
    if (respData.error) {
      showAlert({
        alertMessage: respData.message,
        alertType: 'error',
      });
    }

    const newTiles = respData.data?.tileReports?.map((item) => {
      return {
        id: item.id,
        kpiName: item.name,
        reportDetail: item?.report_details[0],
        linked: {
          _id: centralId,
          embedUrl: `${embedUrl}`,
          pageName: item.linkedReportId,
        },
        fav: item.fav,
        pgtData: item.persona_reports.pgtData,
      };
    });
    const newTableData = respData.data?.tabReports?.map((item) => {
      const newTableObj = item.data.map((value) => {
        return {
          id: value.id,
          kpiName: value.name,
          reportDetail: value?.report_details[0],
          linked: {
            _id: centralId,
            pageName: value.linkedReportId,
            embedUrl: `${embedUrl}`,
            graphPageName: value.reportId,
          },
          fav: value.fav,
          pgtData: value.persona_reports.pgtData,
        };
      });
      return {
        id: item.id,
        name: item.name,
        data: newTableObj,
      };
    });
    dispatch({
      type: GET_DAILY_VIEW_FAVORITES,
      payload: newTiles,
    });
    dispatch({
      type: GET_TABLE_DAILY_VIEW_FAVORITES,
      payload: newTableData,
    });
    dispatch({
      type: REFRESH_TIME,
      payload: respData.data?.refreshTime,
    });
  };

  return (
    <FavoritesContext.Provider
      value={{
        historicalFavorites: state.historicalFavorites,
        favoritesRefreshTime: state.refreshTime,
        getHistoricalFav,
        dailyviewFavorites: state.dailyviewFavorites,
        dailyviewtableFavorites: state.dailyviewtableFavorites,
        getFavorites,
        activeTile,
        setActiveTile,
        activeTab,
        setActiveTab,
        activeTileHistoricalFav,
        setActiveTileHistoricalFav,
        activeTabHistoricalFav,
        setActiveTabHistoricalFav,
      }}
    >
      {children}
    </FavoritesContext.Provider>
  );
};

export default FavoritesStates;
