/*
This component contains source code that defines an pop up modal with KPI names, Alert notifications, KPI screenshots.
It allows user to select/deselect KPI for alert notification and screenshot directly to their email inbox.
*/
import * as React from 'react';
import { Menu, MenuItem } from '@mui/material';
import { styled } from '@mui/material/styles';
import NotificationSettingMenuStyles from '../styles/NotificationSettingMenuStyles';

// css styles
const sxStyles = {
  menuItemsContainer: {
    backgroundColor: 'white',
    borderRadius: '5px',
    width: '11vw',
    padding: '10px',
    marginTop: '15px',
    boxShadow: '0px 0px 3px #ccc',
  },
  menuItem: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
  },
};

// Styled menu
const StyledMenu = styled((props) => (
  <Menu
    elevation={0}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'right',
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'right',
    }}
    {...props}
  />
))(({ theme }) => ({
  '& .MuiPaper-root': {
    borderRadius: 4,
    minWidth: 300,
    boxShadow: '0px 0px 0px #00000014',
    border: '1px solid #D8D9DB',
    '& .MuiMenu-list': {
      padding: '4px 8px',
    },
    '& .MuiMenuItem-root': {
      backgroundColor: 'transparent',
      '&:active': {
        backgroundColor: 'transparent',
      },
      '&:hover': {
        backgroundColor: 'transparent',
      },
    },
  },
}));

const NotificationSettingMenu = (props) => {
  const { anchorEl, open, onClose, onClick, menuList } = props;
  const classes = NotificationSettingMenuStyles();
  return (
    <StyledMenu anchorEl={anchorEl} open={open} onClose={onClose}>
      {menuList.map((menuName, key) => (
        <MenuItem onClick={(e) => onClick(e, menuName)} className={classes.menuItem} sx={sxStyles.menuItem} key={key}>
          {menuName}
        </MenuItem>
      ))}
    </StyledMenu>
  );
};

export default NotificationSettingMenu;
