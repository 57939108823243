/*
This component contains source code that defines an Modal popup.
It takes boolean value as input and returns Modal pop up that can be displayed on the screen.
*/
import React from 'react';

import { Drawer as MaterialDrawer } from '@mui/material';

const Drawer = (props) => {
  const { open, children, PaperProps, handleClose } = props;
  return (
    <MaterialDrawer anchor={'right'} open={open} onClose={handleClose} PaperProps={PaperProps}>
      {children}
    </MaterialDrawer>
  );
};

Drawer.propTypes = {};

export default Drawer;
