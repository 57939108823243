import axios from 'axios';

import { API_URL } from '../config/baseUrl';

let request = {};

// Axios api for send request to backend
['get', 'post', 'put', 'delete', 'patch'].every((method) => {
  request[method] = function (url, options = {}, data = {}) {
    return new Promise((resolve, reject) => {
      const flag = ['post', 'put'].indexOf(method) >= 0;
      axios[method](API_URL + url, flag ? data : options, !flag ? data : options)
        .then((response) => {
          resolve(response.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  };
  return true;
});

export default request;
