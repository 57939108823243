import { makeStyles } from '@mui/styles';

const AccordionStyles = makeStyles(() => ({
  container: {
    marginTop: 15,
  },
  title: {
    fontSize: '1.3rem',
  },
  icon: {
    alignSelf: 'center',
    paddingRight: 10,
  },
}));

export default AccordionStyles;
