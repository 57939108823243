/* 
This file creates a reducer function for Manage email settings page used in the application.
It will have initial state and manage email setting types used in the application.
*/
import React, { useReducer } from 'react';

import NotificationSettingContext from './NotificationSettingContext';
import {
  getNotificationSetting,
  addAlertEmailNotification,
  disableAlertEmailNotification,
} from '../../actions/notificationSetting';
// add the details for the Resource Library
export const SET_NOTIFICATION_SETTING = 'SET_NOTIFICATION_SETTING';
export const ADD_ALERT_EMAIL_NOTIFICATION = 'ADD_ALERT_EMAIL_NOTIFICATION';
export const DISABLE_ALERT_EMAIL_NOTIFICATION = 'DISABLE_ALERT_EMAIL_NOTIFICATION';

// Reducer
const NotificationSettingReducer = (state, action) => {
  const { type, payload } = action;
  switch (type) {
    case SET_NOTIFICATION_SETTING:
      return {
        ...state,
        notificationSettingList: payload,
      };
    case ADD_ALERT_EMAIL_NOTIFICATION:
      return {
        ...state,
        alertEmailNotificationData: payload,
      };
    case DISABLE_ALERT_EMAIL_NOTIFICATION:
      return {
        ...state,
        disableAlertEmailNotificationData: payload,
      };
    default:
      return state;
  }
};
const NotificationSettingStates = ({ children }) => {
  // Initial state
  const initialState = {
    notificationSettingList: {},
    alertEmailNotificationData: {},
    disableAlertEmailNotificationData: {},
  };

  const [state, dispatch] = useReducer(NotificationSettingReducer, initialState);

  // Function to get and set notification settings
  const getNotificationSettingData = async (personaId, userId) => {
    const data = await getNotificationSetting(personaId, userId);
    dispatch({
      type: SET_NOTIFICATION_SETTING,
      payload: data,
    });
  };

  // Function to add and set notification settings based on Alert Email and KPI Subscription
  const addAlertEmailNotificationData = async (props) => {
    const data = await addAlertEmailNotification(props);
    dispatch({
      type: ADD_ALERT_EMAIL_NOTIFICATION,
      payload: data,
    });
  };

  // Function to disabled and set notification settings based on Alert Email and KPI Subscription
  const disableNotificationKPI = async (props) => {
    const data = await disableAlertEmailNotification(props);
    dispatch({
      type: DISABLE_ALERT_EMAIL_NOTIFICATION,
      payload: data,
    });
  };

  return (
    <NotificationSettingContext.Provider
      value={{
        notificationSettingList: state.notificationSettingList,
        getNotificationSettingData,
        addAlertEmailNotificationData,
        alertEmailNotificationData: state.alertEmailNotificationData,
        disableNotificationKPI,
        disableAlertEmailNotificationData: state.disableAlertEmailNotificationData,
      }}
    >
      {children}
    </NotificationSettingContext.Provider>
  );
};

export default NotificationSettingStates;
