import { makeStyles } from '@mui/styles';

const ContactUsModalStyles = makeStyles(() => ({
  container: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100%',
  },
  closeButton: {
    alignSelf: 'flex-end',
    cursor: 'pointer',
  },
  content: {
    width: '33%',
    minHeight: '45%',
    backgroundColor: 'white',
    padding: 35,
    outline: 'none',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  fieldContainer: {
    width: '100%',
  },
  input: {
    width: '100%',
  },
  sendButton: {
    width: '50%',
    height: 40,
    marginTop: 30,
    padding: 5,
    backgroundColor: '#0C2D83',
    fontWeight: 'bold',
    fontSize: '1.1rem',
  },
  title: {
    alignSelf: 'flex-start',
    fontSize: '1.4rem',
    color: '#0C2D83',
    fontWeight: 'bold',
  },
  fieldTitle: {
    marginTop: 5,
    marginBottom: 5,
  },
  attachmentIcon: {
    color: '#0C2D83',
  },
  uploadFileButton: {
    backgroundColor: '#0C2D83',
  },
  success: {
    fontWeight: 'bold',
    color: '#34C759',
  },
  failure: {
    fontWeight: 'bold',
    color: '#FF3B30',
  },
}));

export default ContactUsModalStyles;
