/*
This component contains source code that will display a list of Help items and Contact us link in the Help feature page.
*/
import React, { useEffect, useState, useContext } from 'react';

import { Grid, Link } from '@mui/material';
import { useAppInsightsContext } from '@microsoft/applicationinsights-react-js';

import AccordionAtom from '../atom/Accordion';
import Search from '../atom/Search';
import ContactUsModal from '../molecules/ContactUsModal';
import HelpCentreStyles from '../styles/HelpCentreStyles';
import HelpItemContext from '../context/HelpItemContext';
import AuthContext from '../context/AuthContext';
import LayoutStyles from '../styles/LayoutStyles';

const HelpCentre = (props) => {
  const classes = HelpCentreStyles();
  const appInsights = useAppInsightsContext();
  const { user } = useContext(AuthContext);
  const layoutClasses = LayoutStyles();
  const { getAllHelpItemList, helpItemList } = useContext(HelpItemContext);
  const [searchValue, setSearchValue] = useState('');
  const [contactUsModalOpen, setContactUsModalOpen] = useState(false);
  const [QA, setQA] = useState([]);
  const [QAData, setQAData] = useState([]);

  // Callback function for tracking the page
  useEffect(() => {
    appInsights.trackPageView({
      uri: window.location.href,
      refUri: window.location.href,
      properties: {
        personaName: user.persona.name,
      },
    });
  }, []);

  // Callback function for get all help items
  useEffect(() => {
    getAllHelpItemList();
  }, []);

  // Set help items
  useEffect(() => {
    const newArr = helpItemList.map((v) => ({ ...v, searchedWordMatches: false }));
    setQA(newArr);
    setQAData(newArr);
  }, [helpItemList]);

  // Reset local state when empty search value
  useEffect(() => {
    if (searchValue === '') {
      setQA(QAData);
    }
  }, [searchValue]);

  //Function for serach the text into the help items
  const onSearchType = (event, type) => {
    if (event && event.target) {
      const newSearchValue = event.target.value;
      setSearchValue(newSearchValue);

      const filteredQA = QAData.filter(
        (item) =>
          item.title.toLowerCase().includes(String(newSearchValue).toLowerCase()) ||
          item.content.toLowerCase().includes(String(newSearchValue).toLowerCase()),
      ).map((item) => {
        return {
          ...item,
          searchedWordMatches: true,
        };
      });

      if (filteredQA) {
        setQA(filteredQA);
      }
    }
  };

  return (
    <Grid
      xs={12}
      item
      spacing={0.5}
      container
      flexDirection='column'
      alignContent='space-around'
      className={`${user.persona.layoutId === 1 ? layoutClasses.mainContainer : layoutClasses.rsmMainContainer}`}
    >
      <div className={classes.content}>
        <h3 className={classes.title}>Help Center</h3>
        <Search onChange={onSearchType}></Search>

        {QA?.length === 0 && <h3 className={classes.noEntriesFound}>No entries found</h3>}

        {QA?.map((item) => (
          <div key={item.id}>
            <AccordionAtom
              id={item.id}
              highlightedWord={searchValue}
              title={item.title}
              content={item.content}
              searchedWordMatches={item.searchedWordMatches}
            />
          </div>
        ))}

        <Link className={classes.contactUs} onClick={() => setContactUsModalOpen(true)}>
          Still need help? Contact us
        </Link>

        <ContactUsModal open={contactUsModalOpen} handleClose={() => setContactUsModalOpen(false)} />
      </div>
    </Grid>
  );
};

export default HelpCentre;
