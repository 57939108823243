import React, { useState } from 'react';

import { ArrowUpward, ArrowDownward } from '@mui/icons-material';
import { Grid } from '@mui/material';
import DragIndicatorIcon from '@mui/icons-material/DragIndicator';
import HistoricalViewTileStyle from '../styles/HistoricalViewTileStyle';

import RatingTag from './RatingTag';
const HistoricalViewTile = (props) => {
  const { kpiName, reportDetail, onTileClick, ratingPoint, onToggleRowStarClick, dragKpi } = props;
  const classes = HistoricalViewTileStyle();
  // Code to remove decimal point from  all values of  WAR004 kpi
  let reportDetailFiltered = { ...reportDetail };
  if (reportDetail.kpi_id === 'WAR004') {
    Object.keys(reportDetailFiltered).forEach((item) => {
      if (typeof reportDetailFiltered[item] == 'number') {
        reportDetailFiltered[item] = parseInt(reportDetailFiltered[item]);
      }
    });
  } else {
    Object.keys(reportDetailFiltered).forEach((item) => {
      if (typeof reportDetailFiltered[item] == 'number') {
        reportDetailFiltered[item] = reportDetailFiltered[item].toFixed(2);
      }
    });
  }

  return (
    <div class='mainTileContainer'>
      <Grid item container spacing={0} xl={12} xs={12} md={12}>
        <Grid item container spacing={0} xl={12} xs={12} md={12} onClick={onTileClick}>
          <Grid item container spacing={0} xl={12} xs={12} md={12} className={classes.tileContainer}>
            <Grid item container spacing={0} xl={12} xs={12} md={12} className={classes.firstRow}>
              <div style={{ display: 'flex', alignItems: 'center' }}>
                {dragKpi && <DragIndicatorIcon></DragIndicatorIcon>}
                <span>{kpiName}</span>
              </div>
              <div className={classes.overlayRating} onClick={onToggleRowStarClick}>
                <RatingTag ratingScale={1} ratingPoint={ratingPoint} />
              </div>
            </Grid>
            <div class='onHoverHideL4W'>
              <Grid item container spacing={0} xl={12} xs={12} md={12} className={classes.secondRow}>
                <Grid item xl={12} xs={12} md={12} className={classes.last24HrsCaption}>
                  {reportDetailFiltered.kpi_id === 'WAR001' ? (
                    <>
                      <span className={classes.opacityTitle}>LW</span>
                      <span className={classes.last24Hrs} style={{ color: reportDetailFiltered.last_week_color }}>
                        {reportDetailFiltered.last_week
                          ? `${reportDetailFiltered.last_week}${reportDetailFiltered.last_week_unit}`
                          : '(Blank)'}
                      </span>
                    </>
                  ) : (
                    <>
                      <span className={classes.opacityTitle}>L4W</span>
                      <span className={classes.last24Hrs} style={{ color: reportDetailFiltered.last_4_week_color }}>
                        {reportDetailFiltered.last_4_week
                          ? `${reportDetailFiltered.last_4_week}${reportDetailFiltered.last_4_week_unit}`
                          : '(Blank)'}
                      </span>
                    </>
                  )}
                </Grid>
              </Grid>
            </div>
            <div class='onHoverDisplayL4W'>
              <Grid item container spacing={0} xl={12} xs={12} md={12} className={classes.secondRow}>
                <Grid item xl={12} xs={12} md={12} className={classes.last4Weeks}>
                  <span className={classes.opacityTitle}>Last 4 Weeks</span>
                  <span className={classes.last24Hrs} style={{ color: reportDetailFiltered.last_4_week_color }}>
                    {reportDetailFiltered.last_4_week
                      ? `${reportDetailFiltered.last_4_week}${reportDetailFiltered.last_4_week_unit}`
                      : '(Blank)'}
                  </span>
                </Grid>
              </Grid>
            </div>
            <Grid item container spacing={0} xl={12} xs={12} md={12} className={classes.secondRow}>
              <span className={classes.diffrence} style={{ color: reportDetailFiltered.difference_color }}>
                {reportDetailFiltered.difference !== null ? (
                  reportDetailFiltered.arrow_direction ? (
                    reportDetailFiltered.arrow_direction === 'up' ? (
                      <ArrowUpward fontSize='small' sx={{ fontSize: '1.4rem' }} />
                    ) : (
                      <ArrowDownward fontSize='small' sx={{ fontSize: '1.4rem' }} />
                    )
                  ) : (
                    ''
                  )
                ) : (
                  ''
                )}
                {reportDetailFiltered.difference !== null
                  ? ` ${reportDetailFiltered.difference}${reportDetailFiltered.difference_unit}`
                  : 'N/A'}
              </span>
              <span className={classes.fromDays}>from national score</span>
            </Grid>
            <div class='onHoverHideL4W' style={{ width: '100%' }}>
              <Grid item container spacing={0} xl={12} xs={12} md={12} className={classes.thirdRow}>
                <Grid item xl={12} xs={12} md={12} className={classes.ytdSection}>
                  <span className={classes.ytd}>YTD :</span>
                  <span className={classes.targetValue}>{`${
                    reportDetailFiltered.year_to_date === null
                      ? 'N/A'
                      : `${reportDetailFiltered.year_to_date}${reportDetailFiltered.year_to_date_unit}`
                  }`}</span>
                </Grid>
              </Grid>
            </div>
          </Grid>
          <div class='onHoverDisplay'>
            <Grid item container spacing={0} xl={12} xs={12} md={12} className={classes.lastRow}>
              <Grid item xl={12} xs={12} md={12} className={classes.last4WeeksRow}>
                <span className={classes.opacityTitle}>Last Week</span>
                <span className={classes.last4Hrs} style={{ color: reportDetailFiltered.last_week_color }}>
                  {reportDetailFiltered.last_week
                    ? `${reportDetailFiltered.last_week}${reportDetailFiltered.last_week_unit}`
                    : '(Blank)'}
                </span>
              </Grid>
              <Grid item xl={12} xs={12} md={12} className={classes.last4WeeksRow}>
                <span className={classes.opacityTitle}>Year to Date</span>
                <span className={classes.last4Hrs} style={{ color: reportDetailFiltered.year_to_date_color }}>
                  {reportDetailFiltered.year_to_date
                    ? `${reportDetailFiltered.year_to_date}${reportDetailFiltered.year_to_date_unit}`
                    : '(Blank)'}
                </span>
              </Grid>
              <Grid item xl={12} xs={12} md={12} className={classes.last4WeeksRow}>
                <span className={classes.opacityTitle}>Target</span>
                <span className={classes.last4Hrs} style={{ color: reportDetailFiltered.value_color }}>
                  {`${
                    reportDetailFiltered.target === null
                      ? 'N/A'
                      : `${reportDetailFiltered.target}${reportDetailFiltered.target_unit}`
                  }`}
                </span>
              </Grid>
            </Grid>
          </div>
        </Grid>
      </Grid>
    </div>
  );
};
export default HistoricalViewTile;
