/*
This component contains source code that defines an text highligher.
It takes some search text as input and returns an highlighted that can be displayed on the screen.
*/
import React from 'react';

import HelpItemsStyles from '../../styles/HelpItemsStyles';
import HighlightXpath from '../HighlightXPath';

export const HelpItems = (props) => {
  const { content } = props;
  const classes = HelpItemsStyles();

  return (
    <div className={classes.container}>
      <HighlightXpath content={content} />
    </div>
  );
};
