import React, { useContext, useEffect, useState } from 'react';

import CloseIcon from '@mui/icons-material/Close';
import { Box, Link, Tab, Tabs, Typography, useTheme } from '@mui/material';

import Highlighter from 'react-highlight-words';
import SwipeableViews from 'react-swipeable-views';

import Modal from '../atom/Modal';
import Search from '../atom/Search';
import ResourceLibraryContext from '../context/ResourceLibraryContext';
import ResourceLibraryStyles from '../styles/ResourceLibraryStyles';
import '../styles/CustomTooltip.css';

// Tabs
const TabPanel = (props) => {
  const { children, value, index, className } = props;

  return (
    <div
      role='tabpanel'
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      className={className}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
};
// Function for dynamic props
function dynamicTabProps(index) {
  return {
    id: `full-width-tab-${index}`,
    'aria-controls': `full-width-tabpanel-${index}`,
  };
}

const ResourceLibrary = (props) => {
  const { visible, onClose } = props;
  const theme = useTheme();
  const classes = ResourceLibraryStyles();

  const { resourceLibraryList, getResourceLibraryData } = useContext(ResourceLibraryContext);

  const [tabValue, setTabValue] = useState(0);
  const [glossary, setGlossary] = useState([]);
  const [searchValue, setSearchValue] = useState('');
  const [dashboardList, setDashboardList] = useState([]);

  // Call the api
  useEffect(() => {
    getResourceLibraryData();
  }, []);

  // Set the resource library data
  useEffect(() => {
    setGlossary(resourceLibraryList?.glossaryData);
    setDashboardList(resourceLibraryList?.dashboardListData);
  }, [resourceLibraryList]);

  useEffect(() => {
    if (searchValue.length === 0) {
      // Reset glossary and dashboard list items
      setGlossary(resourceLibraryList?.glossaryData);
      setDashboardList(resourceLibraryList?.dashboardListData);
    }
  }, [searchValue]);

  // Function for tab change
  const handleTabChange = (event, newValue) => {
    setSearchValue('');
    setTabValue(newValue);
  };

  // Function for serach text into particular tabs
  const onSearchType = (event, type) => {
    if (event && event.target) {
      setSearchValue(event.target.value);
      switch (type) {
        case 'glossary':
          const filteredGlossary = resourceLibraryList?.glossaryData.filter(
            (kpi) =>
              kpi.title.toLowerCase().includes(String(searchValue).toLowerCase()) ||
              kpi.content.toLowerCase().includes(String(searchValue).toLowerCase()),
          );
          if (filteredGlossary) {
            setGlossary(filteredGlossary);
          }
          break;
        case 'dashboardList':
          const filteredDashboardList = resourceLibraryList?.dashboardListData.filter(
            (item) =>
              item.content.toLowerCase().includes(String(searchValue).toLowerCase()) ||
              item.title.toLowerCase().includes(String(searchValue).toLowerCase()),
          );
          if (filteredDashboardList) {
            setDashboardList(filteredDashboardList);
          }
          break;
        default:
          return;
      }
    }
  };

  // Function for dashboard list
  const DashboardList = (props) => {
    const { dashboardList, highlightedWord } = props;
    return (
      <div>
        {dashboardList.map((kpi) => (
          <div key={kpi.id} className={classes.dashboardList}>
            <Highlighter
              highlightStyle={{
                borderRadius: 5,
              }}
              className={classes.title}
              searchWords={[highlightedWord]}
              autoEscape={true}
              textToHighlight={kpi.title}
            />
            {JSON.parse(kpi.content).map((item, key) => (
              <ol className={classes.contentLinks} style={{ listStyleType: 'inherit', content: '•' }}>
                <li>
                  <Highlighter
                    className={classes.contentHeading}
                    highlightStyle={{
                      borderRadius: 5,
                    }}
                    searchWords={[highlightedWord]}
                    autoEscape={true}
                    textToHighlight={`${item.name} :`}
                  />
                  <Link href={item.link} target='_blank'>
                    {item.vpn === 'yes' ? (
                      <div className='Links_tooltip'>
                        <span>{item.link}</span>
                        <span className='Links_tooltiptext'>VPN is Required</span>
                      </div>
                    ) : (
                      <div className='Links_tooltip'>
                        <span>{item.link}</span>
                      </div>
                    )}
                  </Link>
                </li>
              </ol>
            ))}
          </div>
        ))}
      </div>
    );
  };
  // Function for glossary list
  const Glossary = (props) => {
    const { glossary, highlightedWord } = props;
    return glossary?.map((kpi) => (
      <div key={kpi.id}>
        <h3 className={classes.title}>
          <Highlighter
            highlightStyle={{
              borderRadius: 5,
            }}
            searchWords={[highlightedWord]}
            autoEscape={true}
            textToHighlight={kpi.title}
          />
        </h3>
        <Highlighter
          highlightStyle={{
            borderRadius: 5,
          }}
          searchWords={[highlightedWord]}
          autoEscape={true}
          textToHighlight={kpi.content}
        />
      </div>
    ));
  };

  return (
    <Modal className={classes.modal} open={visible} handleClose={onClose}>
      <div className={classes.container}>
        <Tabs
          value={tabValue}
          onChange={handleTabChange}
          indicatorColor='primary'
          textColor='inherit'
          variant='fullWidth'
          className={classes.tabs}
        >
          <Tab className={classes.tab} label='Glossary' {...dynamicTabProps(0)} />
          <Tab className={classes.tab} label='Dashboard List' {...dynamicTabProps(1)} />
          <div onClick={onClose}>
            <CloseIcon className={classes.closeIcon} />
          </div>
        </Tabs>
        <SwipeableViews
          axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
          index={tabValue}
          onChangeIndex={handleTabChange}
          className={classes.swipableViews}
        >
          <TabPanel className={classes.tabPanelContainer} value={tabValue} index={0}>
            <Search
              key='glossary-search'
              onChange={(event) => onSearchType(event, 'glossary')}
              onClose={() => setGlossary(resourceLibraryList?.glossaryData)}
            />
            <Glossary glossary={glossary} highlightedWord={searchValue} />
          </TabPanel>
          <TabPanel className={classes.tabPanelContainer} value={tabValue} index={1}>
            <Search
              key='dashboard-list-search'
              onChange={(event) => onSearchType(event, 'dashboardList')}
              onClose={() => setDashboardList(resourceLibraryList?.dashboardListData)}
            />
            <DashboardList dashboardList={dashboardList} highlightedWord={searchValue} />
          </TabPanel>
        </SwipeableViews>
      </div>
    </Modal>
  );
};

export default ResourceLibrary;
