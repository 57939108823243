/*
The following code contains source code that defines all the api functions for user profile/ edit user profile page.
Check if user already logged in or logging in for first time, user logged in or not, update user details, fetch user details and get user personas.....
*/
import request from '../helper/apiRequest';

// Function to get user data based on logged in user
export const pingUser = async (user) => {
  try {
    const { data } = await request.post('/user/pingUser', {}, user);
    return data;
  } catch (e) {
    return {
      error: true,
      message:
        'Something went wrong, please try reloading the page. If the problem persists, check your internet connection.',
      data: null,
    };
  }
};
// Function to create/update profile of user data
export const postUser = async (user) => {
  try {
    const data = await request.put('/user/updateProfile', {}, user);
    return data;
  } catch (e) {
    e.message = 'Profile could not be saved, please try again. Please check your internet connection.';
    return { error: true, message: e.message };
  }
};
// Function to get list of all persona
export const getAllPersonas = async () => {
  try {
    const { data } = await request.get('/user/getUserPersonas');
    return data;
  } catch (e) {
    return {
      error: true,
      message:
        'Something went wrong, please try reloading the page. If the problem persists, check your internet connection.',
      data: null,
    };
  }
};
