import React, { useState, useEffect, useContext } from 'react';

import { DescriptionOutlined } from '@mui/icons-material';
import {
  Grid,
  Link,
  Table,
  TableBody,
  TableContainer,
  FormControlLabel,
  Radio,
  FormControl,
  RadioGroup,
  Typography,
} from '@mui/material';
import { isEmpty } from 'lodash';
import { useNavigate } from 'react-router-dom';
import { utils, writeFile } from 'xlsx';

import leftOutlined from '../assets/leftOutlined.svg';
import EnhancedTableHead from '../atom/EnhancedTableHead';
import LineChart from '../atom/LineChart';
import Loader from '../atom/Loader';
import AuthContext from '../context/AuthContext';
import FilterContext from '../context/FilterContext';
import HistoricalViewContext from '../context/HistoricalViewContext';
import Filters from '../molecules/Filters';
import NationalViewTableRow from '../molecules/NationalViewTableRow';
import LayoutStyles from '../styles/LayoutStyles';
import NationalViewDetailsStyles from '../styles/NationalViewDetailsStyles';

const NationalViewDashboardSection = (props) => {
  const { tileId, byView, FavoritePageButton, innerKpiId } = props;
  const { user, unSetReportDetail } = useContext(AuthContext);
  const filterContext = useContext(FilterContext);
  const { getHistoricalDetails, historicalViewDetails } = useContext(HistoricalViewContext);
  const { locationTypeData, regionData } = filterContext;
  const classes = NationalViewDetailsStyles();
  const [rowData, setRowData] = useState([]);
  const [kpiName, setKpiName] = useState('');
  const [kpiId, setKpiId] = useState('');
  const [headCells, setHeadCells] = useState([]);
  const [newHeading, setNewHeadCells] = useState([]);
  const layoutClasses = LayoutStyles();

  const [region, setRegion] = useState([]);
  const [multipleLocationType, setMultipleLocationType] = useState([]);
  const [locationTypeAllSelected, setLocationTypeAllSelected] = useState(true);
  const [rankingFilter, setRankingFilter] = useState(byView);
  const [kpiDetails, setKpiDetails] = useState(null);
  const [loading, setLoading] = useState(false);
  const [exportStatus, setExportStatus] = useState(false);
  const navigate = useNavigate();

  // Open the modal of PBI graph
  const onRowClick = (site, region, kpiName) => (e) => {
    if (rankingFilter === 'region') {
      navigate(`/historicalView/region/${region}`, { state: { kpiName: kpiName } });
    } else if (rankingFilter === 'site') {
      navigate(`/historicalView/site/${site}`, { state: { kpiName: kpiName } });
    }
  };

  const [order, setOrder] = useState('asc');
  const [orderBy, setOrderBy] = useState(null);

  // Callback functions

  useEffect(() => {
    if (rankingFilter) {
      navigate({
        pathname: `/hqDetails/${tileId}/${innerKpiId}`,
        search: `?view=${rankingFilter}`,
      });
    } else {
      setRankingFilter('region');
    }
  }, [rankingFilter]);
  // Callback Function for reset the report list
  useEffect(() => {
    return () => {
      unSetReportDetail();
    };
  }, []);

  // Callback function for set PBI filters based on multiple location or location data
  useEffect(() => {
    if (user.id && region && !isEmpty(multipleLocationType)) {
      setLoading(true);
      if (rankingFilter === 'region') {
        let headers = [
          {
            id: 'ranking',
            numeric: true,
            disablePadding: false,
            label: 'Ranking',
            sortable: true,
            align: 'left',
          },
          {
            id: 'region',
            numeric: false,
            disablePadding: false,
            label: 'Region',
            sortable: true,
            align: 'left',
          },
          {
            id: 'last_week',
            numeric: true,
            disablePadding: false,
            label: 'LW',
            sortable: true,
            align: 'left',
          },
          {
            id: 'last_4_week',
            numeric: true,
            disablePadding: false,
            label: 'L4W',
            sortable: true,
            align: 'left',
          },
          {
            id: 'year_to_date',
            numeric: true,
            disablePadding: false,
            label: 'YTD',
            sortable: true,
            align: 'left',
          },
          {
            id: 'trendline',
            numeric: true,
            disablePadding: false,
            label: 'Trendline (Last 13 Weeks)',
            sortable: false,
            align: 'center',
          },
        ];
        setHeadCells(headers);
      } else if (rankingFilter === 'site') {
        let headers = [
          {
            id: 'ranking',
            numeric: true,
            disablePadding: false,
            label: 'Ranking',
            sortable: true,
            align: 'left',
          },
          {
            id: 'site',
            numeric: false,
            disablePadding: false,
            label: 'Site Name',
            sortable: true,
            align: 'left',
          },
          {
            id: 'region',
            numeric: false,
            disablePadding: false,
            label: 'Region',
            sortable: true,
            align: 'left',
          },
          {
            id: 'last_week',
            numeric: true,
            disablePadding: false,
            label: 'LW',
            sortable: true,
            align: 'left',
          },
          {
            id: 'last_4_week',
            numeric: true,
            disablePadding: false,
            label: 'L4W',
            sortable: true,
            align: 'left',
          },
          {
            id: 'year_to_date',
            numeric: true,
            disablePadding: false,
            label: 'YTD',
            sortable: true,
            align: 'left',
          },
          {
            id: 'trendline',
            disablePadding: false,
            label: 'Trendline (Last 13 Weeks)',
            align: 'center',
          },
        ];
        setHeadCells(headers);
      }
      setRowData([]);
      getHistoricalDetails(
        user,
        tileId,
        region,
        locationTypeAllSelected ? 'ALL' : '',
        multipleLocationType,
        rankingFilter,
      );
    }
  }, [multipleLocationType, rankingFilter]);

  // Callback function for set the data for the tables based on location

  useEffect(() => {
    if (!isEmpty(historicalViewDetails)) {
      let data = [];
      setKpiName(historicalViewDetails.kpiDetails?.name);
      setKpiDetails(historicalViewDetails.kpiDetails?.historical_view_details[0]);
      setKpiId(historicalViewDetails.kpiDetails.kpi_id);
      historicalViewDetails.historicalDetails?.historical_view_details.map((item, index) => {
        data.push({
          ranking: index + 1,
          location_type: item.location_type ? item.location_type.trim() : item.location_type,
          site: item.location_name,
          region: item.region,
          last_week: +item.last_week,
          last_week_unit: item.last_week_unit,
          last_week_color: item.last_week_color,
          last_4_week: +item.last_4_week,
          last_4_week_unit: item.last_4_week_unit,
          last_4_week_color: item.last_4_week_color,
          year_to_date: +item.year_to_date,
          year_to_date_unit: item.year_to_date_unit,
          year_to_date_color: item.year_to_date_color,
          trendline: item.chartData,
          id: item.id,
        });
      });
      setLoading(false);
      setRowData(data);
    } else {
      setRowData([]);
    }
  }, [historicalViewDetails]);

  // Functions

  // Function for sorting by asc of table data
  const descendingComparator = (a, b, orderBy) => {
    if (b[orderBy] < a[orderBy]) {
      return -1;
    }
    if (b[orderBy] > a[orderBy]) {
      return 1;
    }
    return 0;
  };

  // Function for sorting by desc of table data
  const getComparator = (order, orderBy) => {
    return order === 'desc'
      ? (a, b) => descendingComparator(a, b, orderBy)
      : (a, b) => -descendingComparator(a, b, orderBy);
  };

  // Function for sorting
  const stableSort = (array, comparator) => {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
      const order = comparator(a[0], b[0]);

      if (order !== 0) {
        return order;
      }
      return a[1] - b[1];
    });

    return stabilizedThis.map((el) => el[0]);
  };
  // Request for sorting
  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    let orderType = isAsc ? 'desc' : 'asc';
    setOrder(orderType);
    setOrderBy(property);
    let result = stableSort(rowData, getComparator(orderType, property));
    setRowData(result);
  };

  // Function for navigate to other page
  const navigateTo = (page) => (e) => {
    e.preventDefault();
    navigate(page);
    return false;
  };

  // Function for set region to local state
  const getRegion = (region) => {
    setRegion(region);
  };

  // Function for set multiple location type to local state
  const getMultipleLocationType = (locationType, locationTypeAll) => {
    setMultipleLocationType(locationType);
    setLocationTypeAllSelected(locationTypeAll);
  };

  const excelDownload = () => {
    const wb = utils.book_new();
    let ws_data = [
      ['Persona Name: ', user.persona.name],
      ['Country: ', user.country],
      ['Region', ...region],
      ['MultipleLocationType', ...multipleLocationType],
    ];
    ws_data.push([]);
    rankingFilter === 'site'
      ? ws_data.push(['Ranking', 'Site Name', 'Region', 'LW', 'L4W', 'YTD']) &&
        rowData.map((kpiDetails) => {
          ws_data.push([
            kpiDetails.ranking,
            `${kpiDetails.site === null ? 'N/A' : `${kpiDetails.site}`}`,
            `${kpiDetails.region === null ? 'N/A' : `${kpiDetails.region}`}`,
            `${kpiDetails.last_week >= 0.0 ? `${kpiDetails.last_week}${kpiDetails.last_week_unit}` : '(Blank)'}`,
            `${kpiDetails.last_4_week >= 0.0 ? `${kpiDetails.last_4_week}${kpiDetails.last_4_week_unit}` : '(Blank)'}`,
            `${
              kpiDetails.year_to_date >= 0.0 ? `${kpiDetails.year_to_date}${kpiDetails.year_to_date_unit}` : '(Blank)'
            }`,
          ]);
        })
      : ws_data.push(['Ranking', 'Region', 'LW', 'L4W', 'YTD']) &&
        rowData.map((kpiDetails) => {
          ws_data.push([
            kpiDetails.ranking,
            `${kpiDetails.region === null ? 'N/A' : `${kpiDetails.region}`}`,
            `${kpiDetails.last_week >= 0.0 ? `${kpiDetails.last_week}${kpiDetails.last_week_unit}` : '(Blank)'}`,
            `${kpiDetails.last_4_week >= 0.0 ? `${kpiDetails.last_4_week}${kpiDetails.last_4_week_unit}` : '(Blank)'}`,
            `${
              kpiDetails.year_to_date >= 0.0 ? `${kpiDetails.year_to_date}${kpiDetails.year_to_date_unit}` : '(Blank)'
            }`,
          ]);
        });
    let Name = rankingFilter === 'site' ? 'Sites' : 'Regions';
    const ws = utils.aoa_to_sheet(ws_data);
    utils.book_append_sheet(wb, ws, Name);
    let fileName = '';
    fileName = `Historical_view_${Name}_data`;
    writeFile(wb, `${fileName}.xlsx`);
  };

  const onHandleExport = () => {
    setExportStatus(true);
  };

  useEffect(() => {
    if (exportStatus) {
      excelDownload();
      setExportStatus(false);
    }
  }, [exportStatus]);

  return (
    <Grid container>
      <Grid item container className={layoutClasses.leftContainer}>
        <Filters
          user={user}
          regionMultipleDropDown={true}
          locationTypeMultipleDropDown={true}
          hqSectionLocationTypeMultipleDropDown={true}
          countryLabel={true}
          getRegion={getRegion}
          getMultipleLocationType={getMultipleLocationType}
          rsmDailyViewLocationTypeData={locationTypeData}
          nationalViewRegionData={regionData}
          innerKpiId={innerKpiId}
        />
      </Grid>
      <Grid item container className={layoutClasses.rsmRightContainer}>
        <Grid
          xs={12}
          item
          spacing={0.5}
          container
          flexDirection='column'
          alignContent='space-around'
          className={classes.layoutMainContainer}
        >
          <Grid xs={12} item container className={classes.layoutContainer}>
            <Grid item xs={6} md={6} xl={6} className={classes.backButton}>
              {FavoritePageButton ? (
                <Link
                  href='/favorites/nationalView'
                  key='home'
                  underline='none'
                  sx={{ mr: 2 }}
                  onClick={navigateTo('/favorites/nationalView')}
                >
                  <img src={leftOutlined} alt='close-icon' />
                  {kpiName}
                </Link>
              ) : (
                <Link
                  href='/nationalHistoricalView'
                  key='home'
                  underline='none'
                  sx={{ mr: 2 }}
                  onClick={navigateTo('/nationalHistoricalView')}
                >
                  <img src={leftOutlined} alt='close-icon' />
                  {kpiName}
                </Link>
              )}
            </Grid>
            <Grid item xl={12} md={12} xs={12} container className={classes.widgetContainer}>
              <Grid item xl={12} md={12} xs={12} container className={classes.widgetContainerHeading}>
                <Grid item xl={12} md={12} xs={12} container className={classes.kpiDetails}>
                  <Grid item xl={3} md={4} xs={4} container className={classes.kpi}>
                    <Grid item className={classes.kpiText}>
                      <span className={classes.kpiName}>NATIONAL {kpiName}</span>
                    </Grid>
                  </Grid>
                  <Grid
                    item
                    xl={6}
                    md={4.6}
                    xs={4.8}
                    container
                    className={rankingFilter === 'site' ? classes.lWSite : classes.lWRegion}
                  >
                    <Grid item className={classes.lwDetails}>
                      <span className={classes.l4W}>LW</span>
                      <span
                        className={classes.l4WValue}
                        style={{ color: kpiDetails?.value_color !== 'null' ? kpiDetails?.last_week_color : '' }}
                      >
                        {kpiDetails?.last_week ? `${kpiDetails?.last_week}${kpiDetails?.last_week_unit}` : '(Blank)'}
                      </span>
                    </Grid>
                    <Grid item className={classes.l4wDetails}>
                      <span className={classes.l4W}>L4W</span>
                      <span
                        className={classes.l4WValue}
                        style={{ color: kpiDetails?.value_color !== 'null' ? kpiDetails?.last_4_week_color : '' }}
                      >
                        {kpiDetails?.last_4_week
                          ? `${kpiDetails?.last_4_week}${kpiDetails?.last_4_week_unit}`
                          : '(Blank)'}
                      </span>
                    </Grid>
                    <Grid item className={classes.ytdDetails}>
                      <span className={classes.l4W}>YTD</span>
                      <span
                        className={classes.l4WValue}
                        style={{ color: kpiDetails?.value_color !== 'null' ? kpiDetails?.year_to_date_color : '' }}
                      >
                        {kpiDetails?.year_to_date
                          ? `${kpiDetails?.year_to_date}${kpiDetails?.year_to_date_unit}`
                          : '(Blank)'}
                      </span>
                    </Grid>
                  </Grid>
                  <Grid item container xl={3} md={3.4} xs={3.2} className={classes.targetDetails}>
                    <Grid item className={classes.target}>
                      <span className={classes.l4W}>TARGET</span>
                      <span className={classes.l4WValue}>
                        {kpiDetails?.target ? `${kpiDetails?.target}${kpiDetails?.target_unit}` : '(Blank)'}
                      </span>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xl={12} md={12} xs={12} container className={classes.kpiHeadingDetails}>
                  <Grid item xl={6} md={6} xs={6} className={classes.kpi}>
                    <span className={classes.kpiRankingHeading}>{kpiName} Rankings</span>
                  </Grid>
                  <Grid item xl={6} md={6} xs={6} className={classes.radioExport}>
                    <FormControl>
                      <RadioGroup
                        aria-labelledby='demo-controlled-radio-buttons-group'
                        name='controlled-radio-buttons-group'
                        row
                        value={rankingFilter}
                        onChange={(event, value) => {
                          setRankingFilter(value);
                        }}
                      >
                        <FormControlLabel
                          value='region'
                          control={<Radio />}
                          label='By Region'
                          className={classes.radioButtonRegion}
                        />
                        <FormControlLabel
                          value='site'
                          control={<Radio />}
                          label='By Site'
                          className={classes.radioButtonSite}
                        />
                      </RadioGroup>
                    </FormControl>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xl={12} md={12} xs={12} container className={classes.widgetContainerTable}>
                <Grid item xl={12} md={12} xs={12} container>
                  <Grid item xl={12} md={12} xs={12} className={classes.tableContainer}>
                    <TableContainer
                      className={classes.table}
                      sx={{
                        minHeight: 300,
                      }}
                    >
                      <Table stickyHeader>
                        <EnhancedTableHead
                          headCells={headCells}
                          order={order}
                          orderBy={orderBy}
                          onRequestSort={handleRequestSort}
                          onHandleExport={onHandleExport}
                          size='small'
                          backgroundcolor='#fff'
                        />
                        <TableBody>
                          {rowData.map((row, index) => (
                            <NationalViewTableRow
                              key={row.id}
                              size='small'
                              newHeading={newHeading}
                              rankingFilter={rankingFilter}
                              row={row}
                              onRowClick={onRowClick(row.site, row.region, kpiName)}
                            />
                          ))}
                          <Loader loadingStatus={loading} />
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default NationalViewDashboardSection;
