import { makeStyles } from '@mui/styles';
const RSMDashboardDetailsStyle = makeStyles(() => ({
  tableHeading: {
    fontSize: '1.28rem',
    padding: '20px 16px',
    backgroundColor: (props) => `${props.backgroundcolor}`,
    color: '#000',
  },
}));

export default RSMDashboardDetailsStyle;
