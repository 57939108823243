import React, { useState, useEffect, useContext } from 'react';

import CloseIcon from '@mui/icons-material/Close';
import {
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
  tooltipClasses,
} from '@mui/material';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { some, findIndex, find, isEmpty } from 'lodash';
import { styled } from '@mui/material/styles';
import Modal from '../atom/Modal';
import NotificationSettingStyles from '../styles/NotificationSettingStyles';
import NotificationSettingContext from '../context/NotificationSettingContext';
import AuthContext from '../context/AuthContext';
import CustomizedSwitches from '../atom/CustomizedSwitches';
import NotificationSettingMenu from '../atom/NotificationSettingMenu';
import NotificationSettingCustomMenu from '../atom/NotificationSettingCustomMenu';
import NotificationArchiveModal from '../atom/NotificationArchiveModal';
import Loader from '../atom/Loader';

const NotificationSetting = (props) => {
  const { visible, onClose } = props;
  const classes = NotificationSettingStyles();
  const {
    notificationSettingList,
    getNotificationSettingData,
    addAlertEmailNotificationData,
    alertEmailNotificationData,
  } = useContext(NotificationSettingContext);
  const { resolveAlert, user, alertsCount, resolveMsg, alertsCountUpdate } = useContext(AuthContext);
  const [rowData, setRowData] = useState([]);

  const [emailNotification, setEmailNotification] = useState([]);
  const [kpiNotification, setKpiNotification] = useState([]);

  const [emailSubscriptionEl, setEmailSubscriptionEl] = useState(null);
  const [emailSubscriptionOpen, setEmailSubscriptionOpen] = useState({});

  const [kpiSubscriptionEl, setKpiSubscriptionEl] = useState(null);
  const [kpiSubscriptionOpen, setKpiSubscriptionOpen] = useState({});

  const [customEl, setCustomEl] = useState(null);
  const [customOpen, setCustomOpen] = useState(false);
  const [customKpiId, setCustomKpiId] = useState('');
  const [customSubsciptionType, setCustomSubsciptionType] = useState('');
  const [customWeekDay, setCustomWeekDays] = useState([]);
  const [handleCloseArchiveModal, setHandleCloseArchiveModal] = useState(false);
  const alert_frequency_default_value = 'Does Not Repeat';
  const kpi_frequency_default_value = 'Daily';
  const [loading, setLoading] = useState(false);
  const [subscriptionData, setSubscriptionData] = useState(null);

  // Call API of Notification Setting
  useEffect(() => {
    if (visible) {
      getNotificationSettingData(user.personaId, user.id);
    } else {
      alertsCountUpdate(user);
    }
  }, [visible]);

  useEffect(() => {
    if (alertEmailNotificationData) {
      setLoading(false);
    }
  }, [alertEmailNotificationData]);

  // Fetch the data of Notification Setting and set the local state
  useEffect(() => {
    if (notificationSettingList?.kpiList) {
      let subscription = {};
      // persona_alerts
      let emailNotificationData = [];
      let kpiNotificationData = [];
      notificationSettingList?.kpiList?.map((row) => {
        Object.assign(subscription, { [row.kpi_id]: false });
        // Default Value
        let alert_email_status = false;
        let alert_frequency = alert_frequency_default_value;
        let alert_week_days = [];
        let kpi_week_days = [];
        let kpi_email_status = false;
        let kpi_frequency = kpi_frequency_default_value;
        let notificationKpiData = find(notificationSettingList.notificationSettings, (v) => v.kpi_id === row.kpi_id);

        row['notification_setting'] = notificationKpiData ? notificationKpiData : {};
        if (!isEmpty(row.notification_setting) && row.notification_setting.kpi_id !== null) {
          // Alert Notification
          alert_email_status =
            row.notification_setting.alert_email_subscription !== null
              ? row.notification_setting.alert_email_subscription
              : alert_email_status;
          alert_frequency =
            row.notification_setting.alert_frequency !== null && row.notification_setting.alert_frequency !== ''
              ? row.notification_setting.alert_frequency
              : alert_frequency;
          row.notification_setting.alert_notification_settings?.map((days) => {
            alert_week_days.push(days.alert_frequency_day);
          });
          // KPI Subscription
          kpi_email_status =
            row.notification_setting.kpi_email_subscription !== null
              ? row.notification_setting.kpi_email_subscription
              : kpi_email_status;
          kpi_frequency =
            row.notification_setting.kpi_frequency !== null && row.notification_setting.kpi_frequency !== ''
              ? row.notification_setting.kpi_frequency
              : kpi_frequency;
          row.notification_setting.kpi_notification_settings?.map((days) => {
            kpi_week_days.push(days.kpi_frequency_day);
          });
        }

        emailNotificationData.push({
          [row.kpi_id]: alert_email_status,
          value: alert_frequency,
          weekDays: alert_week_days,
        });
        kpiNotificationData.push({
          [row.kpi_id]: kpi_email_status,
          value: kpi_frequency,
          weekDays: kpi_week_days,
        });
      });
      setEmailNotification(emailNotificationData);
      setKpiNotification(kpiNotificationData);

      setEmailSubscriptionOpen(subscription);
      setKpiSubscriptionOpen(subscription);
      setRowData(notificationSettingList?.kpiList);
    }
  }, [notificationSettingList]);

  //  Function for UnArchive KPI and enable the Alert Email or KPI Subscription setting
  const handleUnArchiveKpi = () => {
    resolveAlert({ id: 0, kpi_id: customKpiId, action: 'unArchive' }, user, alertsCount);
    setHandleCloseArchiveModal(false);
    let notificationKpiData = find(notificationSettingList.kpiList, (v) => v.kpi_id === customKpiId);
    notificationKpiData.archive_alert.kpi_id = null;
    if (subscriptionData.type === 'email') {
      handleChangeEmailSubscription(
        subscriptionData.value,
        subscriptionData.kpi_id,
        subscriptionData.archive_alert_kpi_id,
      );
    } else if (subscriptionData.type === 'kpi') {
      handleChangeKpiSubscription(
        subscriptionData.value,
        subscriptionData.kpi_id,
        subscriptionData.archive_alert_kpi_id,
      );
    }
  };

  // Alert Email Toogle Button function
  const handleChangeEmailSubscription = (value, kpi_id, archive_alert_kpi_id) => {
    if (archive_alert_kpi_id !== null) {
      setHandleCloseArchiveModal(true);
      setCustomKpiId(kpi_id);
      setSubscriptionData({ type: 'email', value: value, kpi_id: kpi_id, archive_alert_kpi_id: null });
    } else {
      setSubscriptionData(null);
      let subscriptionValue = value; // true/false
      let newArr = [...emailNotification]; // copying the old datas array
      let index = findIndex(newArr, { [kpi_id]: !subscriptionValue });
      newArr.splice(index, 1, {
        [kpi_id]: subscriptionValue,
        value: alert_frequency_default_value,
        weekDays: [],
      });
      addAlertEmailNotificationData({
        userId: user.id,
        kpi_id: kpi_id,
        alert_email_subscription: subscriptionValue,
        alert_frequency: subscriptionValue ? alert_frequency_default_value : '',
        subscription_type: 'email',
      });
      setLoading(true);
      setEmailNotification(newArr);
    }
  };

  //  Open the popup of Alert Email
  const handleEmailSubscriptionClick = (event, kpi_id) => {
    if (some(emailNotification, (v) => v[kpi_id])) {
      setEmailSubscriptionEl(event.currentTarget);
      setEmailSubscriptionOpen({ ...emailSubscriptionOpen, [kpi_id]: true });
    }
  };

  // Save the Alert Email
  const handleEmailSubscriptionSelect = (event, kpi_id, value) => {
    if (value === 'Custom') {
      setCustomOpen(true);
      setCustomEl(emailSubscriptionEl);
      setCustomKpiId(kpi_id);
      setCustomSubsciptionType('email');
      // weekDays
      let newArr = [...emailNotification]; // copying the old datas array
      let data = find(newArr, { [kpi_id]: true });
      setCustomWeekDays(data.weekDays);
    } else {
      setEmailSubscriptionEl(null);
      setEmailSubscriptionOpen({ ...emailSubscriptionOpen, [kpi_id]: false });

      let newArr = [...emailNotification]; // copying the old datas array
      let index = findIndex(newArr, { [kpi_id]: true });
      newArr.splice(index, 1, {
        [kpi_id]: true,
        value: value,
        weekDays: [],
      });
      setEmailNotification(newArr);
      addAlertEmailNotificationData({
        userId: user.id,
        kpi_id: kpi_id,
        alert_email_subscription: true,
        alert_frequency: value,
        subscription_type: 'email',
      });
      setLoading(true);
    }
  };

  //  Close the popup of Alert Email
  const handleEmailSubscriptionClose = (kpi_id) => {
    setEmailSubscriptionEl(null);
    setCustomEl(null);
    setCustomWeekDays([]);
    setEmailSubscriptionOpen({ ...emailSubscriptionOpen, [kpi_id]: false });
  };

  // KPI Subscription Toogle Button function
  const handleChangeKpiSubscription = (value, kpi_id, archive_alert_kpi_id) => {
    if (archive_alert_kpi_id !== null) {
      setHandleCloseArchiveModal(true);
      setCustomKpiId(kpi_id);
      setSubscriptionData({ type: 'kpi', value: value, kpi_id: kpi_id, archive_alert_kpi_id: null });
    } else {
      let subscriptionValue = value;
      let newArr = [...kpiNotification]; // copying the old datas array
      let index = findIndex(newArr, { [kpi_id]: !subscriptionValue });
      newArr.splice(index, 1, {
        [kpi_id]: subscriptionValue,
        value: kpi_frequency_default_value,
        weekDays: [],
      });
      addAlertEmailNotificationData({
        userId: user.id,
        kpi_id: kpi_id,
        kpi_email_subscription: subscriptionValue,
        kpi_frequency: subscriptionValue ? kpi_frequency_default_value : '',
        subscription_type: 'kpi',
      });
      setLoading(true);
      setKpiNotification(newArr);
    }
  };

  //  Open the popup of KPI Subscription
  const handleKpiSubscriptionClick = (event, kpi_id) => {
    if (some(kpiNotification, (v) => v[kpi_id])) {
      setKpiSubscriptionEl(event.currentTarget);
      setKpiSubscriptionOpen({ ...kpiSubscriptionOpen, [kpi_id]: true });
    }
  };

  // Save the KPI Subscription
  const handleKpiSubscriptionSelect = (event, kpi_id, value) => {
    if (value === 'Custom') {
      setCustomOpen(true);
      setCustomEl(kpiSubscriptionEl);
      setCustomKpiId(kpi_id);
      setCustomSubsciptionType('kpi');
      // weekDays
      let newArr = [...kpiNotification]; // copying the old datas array
      let data = find(newArr, { [kpi_id]: true });
      setCustomWeekDays(data.weekDays);
    } else {
      setKpiSubscriptionEl(null);
      setKpiSubscriptionOpen({ ...kpiSubscriptionOpen, [kpi_id]: false });
      let newArr = [...kpiNotification]; // copying the old datas array
      let index = findIndex(newArr, { [kpi_id]: true });
      newArr.splice(index, 1, {
        [kpi_id]: true,
        value: value,
        weekDays: [],
      });
      setKpiNotification(newArr);
      addAlertEmailNotificationData({
        userId: user.id,
        kpi_id: kpi_id,
        kpi_email_subscription: true,
        kpi_frequency: value,
        subscription_type: 'kpi',
      });
      setLoading(true);
    }
  };

  // Close the popup of KPI Subscription
  const handleKpiSubscriptionClose = (kpi_id) => {
    setKpiSubscriptionEl(null);
    setKpiSubscriptionOpen({ ...kpiSubscriptionOpen, [kpi_id]: false });
  };

  // Store Week Days
  const handleWeekDays = (day) => {
    let data = customWeekDay;
    let findData = some(data, (v) => v === day);
    if (findData) {
      setCustomWeekDays(customWeekDay.filter((item) => item !== day));
    } else {
      setCustomWeekDays((customWeekDay) => [...customWeekDay, day]);
    }
  };

  // Close the popup of Custom Week Days
  const handleWeekDayClose = () => {
    setCustomOpen(false);
    setCustomEl(null);
    setCustomKpiId('');
    setCustomWeekDays([]);
  };

  // Store Custom Week Days
  const handleWeekDaySave = () => {
    if (customSubsciptionType === 'email') {
      let data = find(emailNotification, { [customKpiId]: true });
      let newArr = [...emailNotification]; // copying the old datas array
      let index = findIndex(newArr, { [customKpiId]: data[customKpiId] });
      newArr.splice(index, 1, {
        [customKpiId]: data[customKpiId],
        value: 'Custom',
        weekDays: customWeekDay,
      });

      setEmailNotification(newArr);
      addAlertEmailNotificationData({
        userId: user.id,
        kpi_id: customKpiId,
        alert_email_subscription: data[customKpiId],
        alert_frequency: 'Custom',
        subscription_type: 'email',
        week_days: customWeekDay,
      });
      setLoading(true);
      handleWeekDayClose();
      handleEmailSubscriptionClose(customKpiId);
    } else if (customSubsciptionType === 'kpi') {
      let data = find(kpiNotification, { [customKpiId]: true });
      let newArr = [...kpiNotification]; // copying the old datas array
      let index = findIndex(newArr, { [customKpiId]: data[customKpiId] });
      newArr.splice(index, 1, {
        [customKpiId]: data[customKpiId],
        value: 'Custom',
        weekDays: customWeekDay,
      });

      setKpiNotification(newArr);
      addAlertEmailNotificationData({
        userId: user.id,
        kpi_id: customKpiId,
        kpi_email_subscription: data[customKpiId],
        kpi_frequency: 'Custom',
        subscription_type: 'kpi',
        week_days: customWeekDay,
      });
      setLoading(true);
      handleWeekDayClose();
      handleKpiSubscriptionClose(customKpiId);
    }
  };

  // Custom tooltip
  const HtmlTooltip = styled(({ className, ...props }) => <Tooltip {...props} classes={{ popper: className }} />)(
    ({ theme }) => ({
      [`& .${tooltipClasses.tooltip}`]: {
        backgroundColor: '#FFFFFF',
        color: '#011F36',
        maxWidth: 460,
        padding: 12,
        fontSize: '1.3rem',
        border: '1px solid #D8D9DB',
        boxShadow: '0px 3px 6px #00000014',
        '& span': {
          lineHeight: 1.4,
        },
      },
    }),
  );

  return (
    <>
      <Modal className={classes.modal} open={visible} handleClose={onClose}>
        <div className={classes.container}>
          <Loader loadingStatus={loading} />
          <Grid item xl={12} md={12} xs={12} container>
            <Grid item xl={12} md={12} xs={12} className={classes.closeIconItem}>
              <CloseIcon className={classes.closeIcon} onClick={onClose} />
            </Grid>
          </Grid>

          {rowData.length > 0 && (
            <Grid item xl={12} md={12} xs={12} container>
              <Grid item xl={12} md={12} xs={12} className={classes.tableContainer}>
                <TableContainer className={classes.table}>
                  <Table aria-label='table' stickyHeader>
                    <TableHead>
                      <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                        <TableCell align='left' className={classes.tableHeading}>
                          KPIs
                        </TableCell>
                        <TableCell align='left' className={classes.tableHeading}>
                          <span className={classes.infoIcon}>
                            Alert Emails
                            <HtmlTooltip
                              title={
                                <span>
                                  Receive alert notifications on irregular or unique KPI behavior, sent straight to your
                                  inbox.
                                </span>
                              }
                            >
                              <InfoOutlinedIcon sx={{ color: '#8D8D8D' }} fontSize='large' />
                            </HtmlTooltip>
                          </span>
                        </TableCell>
                        <TableCell align='left' className={classes.tableHeading}>
                          <span className={classes.infoIcon}>
                            KPI Status Updates
                            <HtmlTooltip
                              title={
                                <span>Receive regular snapshots of a KPI’s value, sent straight to your inbox.</span>
                              }
                            >
                              <InfoOutlinedIcon sx={{ color: '#8D8D8D' }} fontSize='large' />
                            </HtmlTooltip>
                          </span>
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {rowData.map((row, index) => (
                        <TableRow key={row.kpi_id} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                          <TableCell key={`kpi-name-${row.kpi_id}`} align='left' className={classes.kpiName}>
                            {row.kpi_mapping.KPI_GEN_NAME}
                          </TableCell>
                          <TableCell key={`cell-${row.kpi_id}`} align='left'>
                            {row.persona_alerts.kpi_id === null ? (
                              <>
                                <span className={classes.notAvailable}>Not Available</span>
                              </>
                            ) : (
                              <div className={classes.subscriptionItem}>
                                <CustomizedSwitches
                                  key={`switch-${row.kpi_id}`}
                                  handleChange={(e) =>
                                    handleChangeEmailSubscription(
                                      e.target.checked,
                                      row.kpi_id,
                                      row.archive_alert.kpi_id,
                                    )
                                  }
                                  checked={some(emailNotification, (v) => v[row.kpi_id])}
                                />
                                <div
                                  className={classes.subscriptionItemLink}
                                  onClick={(e) => handleEmailSubscriptionClick(e, row.kpi_id)}
                                >
                                  {
                                    find(emailNotification, (v) => v[row.kpi_id] === true || v[row.kpi_id] === false)
                                      ?.value
                                  }

                                  {!emailSubscriptionOpen[row.kpi_id] ? (
                                    <ArrowDropDownIcon fontSize='large' />
                                  ) : (
                                    <ArrowDropUpIcon fontSize='large' />
                                  )}
                                </div>
                                <NotificationSettingMenu
                                  anchorEl={emailSubscriptionEl}
                                  open={emailSubscriptionOpen[row.kpi_id]}
                                  onClose={() => handleEmailSubscriptionClose(row.kpi_id)}
                                  onClick={(e, value) => handleEmailSubscriptionSelect(e, row.kpi_id, value)}
                                  menuList={['Does Not Repeat', 'Daily', 'Weekly On Monday', 'Every Weekday', 'Custom']}
                                />
                              </div>
                            )}
                          </TableCell>
                          <TableCell key={row.kpi_mapping.KPI_GEN_NAME} align='left'>
                            <div className={classes.subscriptionItem}>
                              <CustomizedSwitches
                                key={row.kpi_id}
                                handleChange={(e) =>
                                  handleChangeKpiSubscription(e.target.checked, row.kpi_id, row.archive_alert.kpi_id)
                                }
                                checked={some(kpiNotification, (v) => v[row.kpi_id])}
                              />

                              <div
                                className={classes.subscriptionItemLink}
                                onClick={(e) => handleKpiSubscriptionClick(e, row.kpi_id)}
                              >
                                {find(kpiNotification, (v) => v[row.kpi_id] === true || v[row.kpi_id] === false)?.value}
                                {!kpiSubscriptionOpen[row.kpi_id] ? (
                                  <ArrowDropDownIcon fontSize='large' />
                                ) : (
                                  <ArrowDropUpIcon fontSize='large' />
                                )}
                              </div>
                              <NotificationSettingMenu
                                anchorEl={kpiSubscriptionEl}
                                open={kpiSubscriptionOpen[row.kpi_id]}
                                onClose={() => handleKpiSubscriptionClose(row.kpi_id)}
                                onClick={(e, value) => handleKpiSubscriptionSelect(e, row.kpi_id, value)}
                                menuList={['Daily', 'Weekly On Monday', 'Every Weekday', 'Custom']}
                              />
                            </div>
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
                <NotificationSettingCustomMenu
                  anchorEl={customEl}
                  open={customOpen}
                  customWeekDay={customWeekDay}
                  handleWeekDays={handleWeekDays}
                  handleWeekDayClose={() => handleWeekDayClose()}
                  handleWeekDaySave={() => handleWeekDaySave()}
                />
              </Grid>
            </Grid>
          )}
          <NotificationArchiveModal
            open={handleCloseArchiveModal}
            handleClose={() => setHandleCloseArchiveModal(false)}
            handleUnArchiveKpi={() => handleUnArchiveKpi()}
          />
        </div>
      </Modal>
    </>
  );
};

export default NotificationSetting;
