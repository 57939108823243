/*
This component contains source code that defines an toggle button/switch element.
It takes boolean value as input and returns an toggle button/switch element with button enabled or disabled that can be displayed on the screen.
*/
import React, { useState, useEffect, useContext } from 'react';

import { Switch, styled } from '@mui/material';

// Function to apply css
const AntSwitch = styled(Switch)(({ theme }) => ({
  width: 50,
  height: 24,
  padding: 0,
  display: 'flex',
  '&:active': {
    '& .MuiSwitch-thumb': {
      width: 15,
    },
    '& .MuiSwitch-switchBase.Mui-checked': {
      transform: 'translateX(9px)',
    },
  },
  '& .MuiSwitch-switchBase': {
    padding: 3,
    '&.Mui-checked': {
      transform: 'translateX(24px)',
      color: '#fff',
      '& + .MuiSwitch-track': {
        opacity: 1,
        backgroundColor: '#0056C8',
      },
    },
  },
  '& .MuiSwitch-thumb': {
    boxShadow: '0 2px 4px 0 rgb(0 35 11 / 20%)',
    width: 18,
    height: 18,
    borderRadius: 10,
    transition: theme.transitions.create(['width'], {
      duration: 200,
    }),
  },
  '& .MuiSwitch-track': {
    borderRadius: 30 / 2,
    opacity: 1,
    backgroundColor: '#BBBCBC',
    boxSizing: 'border-box',
  },
}));

const CustomizedSwitches = (props) => {
  const { handleChange, checked } = props;

  return <AntSwitch checked={checked} onChange={handleChange} />;
};

export default CustomizedSwitches;
