import { makeStyles } from '@mui/styles';

const NotificationArchiveModalStyles = makeStyles(() => ({
  boxStyle: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 700,
    height: 200,
    backgroundColor: '#FFFFFF',
    boxShadow: '0px 3px 6px #00000014',
    borderRadius: 4,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-evenly',
    '& p': {
      color: '#000000',
      fontSize: '1.3rem',
      textAlign: 'center',
      lineHeight: 1.6,
      marginTop: 2,
      marginBottom: 2,
    },
    outline: 'none',
  },
  noButton: {
    backgroundColor: '#ED1A2D',
    padding: '6px 40px',
    textTransform: 'capitalize',
    fontSize: '1.1rem',
    '&:hover': {
      backgroundColor: '#ED1A2D',
    },
  },
  yesButton: {
    backgroundColor: '#02AB4F',
    padding: '6px 40px',
    textTransform: 'capitalize',
    fontSize: '1.1rem',
    '&:hover': {
      backgroundColor: '#02AB4F',
    },
  },
  modalAction: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    gap: 30,
  },
}));

export default NotificationArchiveModalStyles;
