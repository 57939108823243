/*
This component contains source code that defines an pop up box with Days of the week.
It allows user to select the days on which user wants to receive emails.
*/
import * as React from 'react';
import { Box, Card, CardActions, CardContent, Button, Typography, Menu } from '@mui/material';
import { some } from 'lodash';
import NotificationSettingCustomMenuStyles from '../styles/NotificationSettingCustomMenuStyles';

const NotificationSettingCustomMenu = (props) => {
  const { anchorEl, open, handleWeekDays, customWeekDay, handleWeekDayClose, handleWeekDaySave } = props;
  const classes = NotificationSettingCustomMenuStyles();
  // List of days name with short and full name
  const dayName = [
    {
      shortName: 'M',
      fullName: 'Monday',
    },
    {
      shortName: 'T',
      fullName: 'Tuesday',
    },
    {
      shortName: 'W',
      fullName: 'Wednesday',
    },
    {
      shortName: 'T',
      fullName: 'Thursday',
    },
    {
      shortName: 'F',
      fullName: 'Friday',
    },
    {
      shortName: 'S',
      fullName: 'Saturday',
    },
    {
      shortName: 'S',
      fullName: 'Sunday',
    },
  ];
  const card = (
    <>
      <CardContent>
        <Typography className={classes.title} gutterBottom>
          Repeat on
        </Typography>
        <div className={classes.weeklyContainer}>
          {dayName.map((data) => (
            <div
              key={data.fullName}
              className={`${classes.weekly} ${some(customWeekDay, (v) => v === data.fullName) && classes.daySelected}`}
              onClick={() => handleWeekDays(data.fullName)}
            >
              {data.shortName}
            </div>
          ))}
        </div>
      </CardContent>
      <CardActions className={classes.action}>
        <Button size='small' className={classes.cancelAction} onClick={handleWeekDayClose}>
          Cancel
        </Button>
        <Button
          size='small'
          className={classes.doneAction}
          onClick={customWeekDay.length > 0 ? handleWeekDaySave : undefined}
        >
          Done
        </Button>
      </CardActions>
    </>
  );

  return (
    <>
      <Menu
        open={open}
        anchorEl={anchorEl}
        elevation={20}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        className={classes.menu}
      >
        <Box>
          <Card variant='outlined'>{card}</Card>
        </Box>
      </Menu>
    </>
  );
};

export default NotificationSettingCustomMenu;
