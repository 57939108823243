import React, { useEffect, useState, useContext } from 'react';

import { useAppInsightsContext } from '@microsoft/applicationinsights-react-js';

import AuthContext from '../context/AuthContext';
import HistoricalViewContext from '../context/HistoricalViewContext';
import NationalViewHistoricalDashboard from '../organisms/NationalViewHistoricalDashboard';

const NationalHistoricalView = (props) => {
  const { accessToken, user } = useContext(AuthContext);
  const { historicalViewData } = useContext(HistoricalViewContext);
  const appInsights = useAppInsightsContext();
  const [tableReports, setTableReports] = useState([]);

  // Tabular KPI's records
  useEffect(() => {
    setTableReports(historicalViewData);
  }, [historicalViewData]);

  useEffect(() => {
    appInsights.trackPageView({
      uri: window.location.href,
      refUri: window.location.href,
      properties: {
        personaName: user.persona.name,
      },
    });
  }, []);

  return (
    <NationalViewHistoricalDashboard
      tableReports={tableReports}
      accessToken={accessToken}
      personaId={user.personaId}
      {...props}
    />
  );
};
export default NationalHistoricalView;
