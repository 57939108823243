import { makeStyles } from '@mui/styles';
const HistoricalHQTileStyle = makeStyles(() => ({
  tileContainer: {
    padding: '8px 5px 8px 10px',
  },
  firstRow: {
    cursor: 'default',
    fontSize: '1.2rem',
    fontWeight: 500,
    textTransform: 'uppercase',
    display: 'flex',
    justifyContent: 'space-between',
  },
  secondRow: {
    display: 'flex',
    alignItems: 'center',
  },
  lastRow: {
    display: 'flex',
    alignItems: 'center',
  },
  opacityTitle: {
    opacity: 0.7,
  },
  last4Weeks: {
    fontSize: '1.2rem',
    color: '#3D4043',
    display: 'flex',
    justifyContent: 'space-between',
    flexDirection: 'row',
    alignItems: 'baseline',
    gap: 10,
    paddingTop: 4,
    paddingBottom: 4,
  },
  last4WeeksRow: {
    fontSize: '1.2rem',
    color: '#3D4043',
    display: 'flex',
    justifyContent: 'space-between',
    flexDirection: 'row',
    alignItems: 'baseline',
    gap: 10,
    paddingTop: 4,
    paddingBottom: 4,
    borderTop: '1px solid #CBCCCE',
  },
  last4Hrs: {
    fontWeight: 'bold',
    fontSize: '1.4rem',
  },
  last24Hrs: {
    fontWeight: 'bold',
    fontSize: '1.4rem',
  },
  overlayRating: {
    // position: 'absolute',
    // top: 4,
    // right: 6,
    height: 35,
    cursor: 'pointer',
  },
}));

export default HistoricalHQTileStyle;
