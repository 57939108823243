import { makeStyles } from '@mui/styles';

const HighlightXpathStyles = makeStyles(() => ({
  highlighted: {
    backgroundColor: 'yellow',
    borderRadius: 5,
  },
}));

export default HighlightXpathStyles;
