import { makeStyles } from '@mui/styles';

const DashboardStyles = makeStyles((theme) => ({
  customerService: {
    padding: '0px 0px 0px 0px',
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    gap: 12,
  },
  dashboardContainer: {
    padding: '20px 0px 0px 0px',
  },
  RsmDashboardContainer: {
    padding: '0px 0px 0px 0px',
  },
  infoContainer: {
    display: 'flex',
    height: '35px',
    justifyContent: 'right',
    cursor: 'pointer',
    padding: '0px',
  },
  tilesStyle: {
    display: 'flex',
    flexWrap: 'wrap',
    gap: 15,
    height: 'fit-content',
  },
  dropdown: {
    display: 'flex',
    justifyContent: 'right',
  },
  rsmTilesStyle: {
    gap: 7,
  },
  iframeContainer: {
    width: '100%',
    minWidth: 310,
    maxWidth: 310,
    margin: '2px',
    position: 'relative',
    minHeight: 180,
    paddingRight: '12px',
    maxHeight: 180,
    border: '1px solid #e3e0d8',
    borderRadius: '10px',
    backgroundColor: '#FFFFFF',
    // boxShadow: '0px 2px 6px 0px rgb(0 0 0 / 15%)',
    '&::after': {
      content: '""',
      position: 'absolute',
      top: 0,
      right: 0,
      height: '100%',
      width: '9px', // Total width for the right "border"
      backgroundColor: '#FFFFFF', // Initial white color
      borderRadius: '0 10px 10px 0',
    },
    '&:hover': {
      cursor: 'pointer',
      border: '1px solid rgb(38, 105, 169)', // Changes all borders to blue
      boxShadow: '0px 2px 6px 0px rgb(0 0 0 / 50%)',
      '&::after': {
        background: 'linear-gradient(to right, #FFFFFF 94.67%, rgb(38, 105, 169) 5.33%)', // 11px white, 1px blue
      },
    },
  },
  pgt_iframeContainer: {
    width: '100%',
    minWidth: 310,
    maxWidth: 310,
    margin: '2px',
    position: 'relative',
    // height: '100%',
    minHeight: 180,
    maxHeight: 180,
    border: '1px solid #e3e0d8',
    borderRight: '12px solid blue',
    borderRadius: '10px',
    backgroundColor: '#FFFFFF',
    boxShadow: '0px 2px 6px 0px rgb(0 0 0 / 15%)',
    '&:hover': {
      cursor: 'pointer',
      border: '1px solid rgb(38, 105, 169)',
      boxShadow: '0px 2px 6px 0px rgb(0 0 0 / 50%)',
      borderRight: '12px solid blue',
    },
  },
  tileActive: {
    position: 'relative',
    boxShadow: '0px 2px 6px 0px rgb(0 0 0 / 50%)',
    top: 0,
    right: -1,
    height: '100%',
    width: '9px', // Creates a 12px border effect on the right
    border: '1px solid rgb(38, 105, 169)', // Blue color for the right side
    borderRadius: '10px', // Optional: adjust if rounding is needed
  },
  pgt_tileActive: {
    border: '1px solid rgb(38, 105, 169)',
    borderRight: '12px solid blue',
    boxShadow: '0px 2px 6px 0px rgb(0 0 0 / 50%)',
  },
  tableStyle: {
    padding: '0px 25px 0px 15px',
    background: '#ffffff',
    height: 'auto',
  },
  rsmTableStyle: {
    height: 'auto',
  },

  tabs: {
    '& .MuiTabs-flexContainer': {
      justifyContent: 'space-around',
    },
    '& .MuiTab-root.Mui-selected': {
      fontWeight: 700,
      color: '#054476',
    },
  },
  rsmTabs: {
    '& .MuiTabs-flexContainer': {
      justifyContent: 'flex-start',
    },
    '& .MuiTab-root': {
      paddingLeft: 0,
      alignItems: 'flex-start',
    },
    '& .MuiTab-root.Mui-selected': {
      fontWeight: 700,
      color: '#054476',
    },
  },

  tabLabel: {
    fontSize: '1.2rem',
    fontWeight: 'bolder',
    color: 'black',
    textTransform: 'initial',
  },

  tabContainer: {
    marginTop: 10,
    display: 'none',
    width: '100%',
    // paddingLeft: 50,
  },
  tableBodyRows: {
    height: 120,
    '& .MuiTableCell-root:first-child': {
      paddingLeft: 50,
    },
    '& td': {
      paddingTop: 40,
      paddingBottom: 10,
      height: 'inherit',
    },
    '&.MuiTableRow-hover': {
      '&:hover': {
        '& .MuiTableCell-root': {
          borderBottom: '1px solid rgb(38, 105, 169)',
        },
        backgroundColor: 'transparent',
        cursor: 'pointer',
      },
    },
  },
  pgt_tableBodyRows: {
    height: 120,
    borderRight: '12px solid blue',
    borderRadius: '10px',
    '& .MuiTableCell-root:first-child': {
      paddingLeft: 50,
    },
    '& td': {
      paddingTop: 40,
      paddingBottom: 10,
      height: 'inherit',
    },
    '&.MuiTableRow-hover': {
      '&:hover': {
        '& .MuiTableCell-root': {
          borderBottom: '1px solid rgb(38, 105, 169)',
          borderRadius: '10px',
        },
        backgroundColor: 'transparent',
        cursor: 'pointer',
      },
    },
  },
  tableRows: {
    '&:last-child td, &:last-child th': { border: 0 },
    '& .MuiTableCell-root:first-child': {
      paddingLeft: 32,
    },
    '& th': {
      paddingBottom: 0,
    },
  },
  displayTab: {
    display: 'block',
    zoom: '71%',
    '@media screen and (min-width:1200px)': {
      zoom: '80%',
    },
    '@media screen and (min-width:1300px)': {
      zoom: '75%',
    },
    '@media screen and (min-width:1400px)': {
      zoom: '73%',
    },
  },
  rsmIframeContainer: {
    display: 'none',
    minWidth: 310,
    maxWidth: 310,
  },
  rsmDisplayTab: {
    display: 'block',
  },

  tableHeader: {
    marginBottom: '1.2rem',
    color: '#3D4043',
    fontSize: '1rem',
    opacity: 0.8,
    fontWeight: '400',
    zoom: '159%',
    paddingTop: 0,
    paddingLeft: 0,
    paddingRight: 6,
  },
  firstHeading: {
    width: '272px',
  },
  secondHeading: {
    width: '154px',
  },
  thirdHeading: {
    width: '140px',
  },
  fourthHeading: {
    width: '246px',
  },
  TrendHeading: {
    width: '200px',
  },
  ratingHeading: {
    width: '60px',
  },
  favoritePage: {
    display: 'none !important',
  },
  iframeStyle: {
    height: 'calc(100vh - 100px)',
    width: 'calc(100vw)',
    border: 'none',
  },

  starIcon: {
    marginRight: 10,
    alignSelf: 'flex-end',
  },
  rowStarIcon: {
    alignSelf: 'center',
  },

  rowOverlay: {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    cursor: 'pointer',
    width: 'inherit',
    minWidth: 'inherit',
    maxWidth: 'inherit',
    minHeight: 'inherit',
    maxHeight: 'inherit',
  },
  overlayRatingTable: {
    position: 'absolute',
    marginTop: 64,
    right: 58,
    height: 60,
    cursor: 'pointer',
  },
  noFavData: {
    padding: 12,
    fontSize: '1.6rem',
    color: '#545C63',
    display: 'flex',
    justifyContent: 'center',
    opacity: 0.5,
  },
  boxStyle: {
    position: 'absolute',
    top: '51%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    display: 'flex',
    height: 'calc(100vh - 30px)',
    width: 'calc(100vw - 130px)',
    background: '#FAFAFA',
    border: '2px solid #000',
    '@media screen and (min-width:1200px)': {
      zoom: '144%',
    },
    '@media screen and (min-width:600px)': {
      zoom: '184%',
    },
    '@media screen and (min-width:700px)': {
      zoom: '184%',
    },
    '@media screen and (min-width:900px)': {
      zoom: '166%',
    },
    '@media screen and (min-width:1300px)': {
      zoom: '140%',
    },
    '@media screen and (min-width:1500px)': {
      zoom: '125%',
    },
    '@media screen and (min-width:1600px)': {
      zoom: '122%',
    },
    '@media screen and (min-width:1700px)': {
      zoom: '120%',
    },
    '@media screen and (min-width:1800px)': {
      zoom: '97%',
    },
    '@media screen and (min-width:2500px)': {
      zoom: '74%',
    },
    '@media screen and (min-width:2800px)': {
      zoom: '70%',
    },
    '@media screen and (min-width:3000px)': {
      zoom: '64%',
    },
    '@media screen and (min-width:3400px)': {
      width: 'calc(100vw - 20%)',
      height: 'calc(100vh - 10%)',
      zoom: '54%',
    },
    '@media screen and (min-width:3800px)': {
      width: 'calc(100vw - 14%)',
      height: 'calc(100vh - 20%)',
      zoom: '52%',
    },
    '@media screen and (min-width:5100px)': {
      width: 'calc(100vw - 10%)',
      height: 'calc(100vh - 20%)',
      zoom: '40%',
    },
    '@media screen and (min-width:7680px)': {
      width: 'calc(100vw - 5%)',
      height: 'calc(100vh - 15%)',
      zoom: '24%',
    },
  },
  modalFilter: {
    background: '#fafafa',
  },
  agrootwrapper: {
    border: 'none',
    position: 'absolute',
    display: 'block',
    width: '45%',
    height: '45%',
    top: '38px',
  },
  RsmPBIContainer: {
    padding: '10px 26px 0px 0px',
  },
  powerBIKPIName: {
    padding: '10px 0px 6px 0px',
    fontSize: '1.2rem',
    color: '#3D4043',
  },

  rankingsButton: {
    marginTop: '2px',
    border: '1px solid rgb(25, 118, 210)',
    padding: '4px 4px 4px 15px',
    display: 'flex',
    borderRadius: '5px',
    justifyContent: 'flex-start',
    backgroundColor: 'white',
    maxWidth: '200px',
    cursor: 'pointer',
  },

  rankText: {
    color: 'rgb(25, 118, 210)',
    fontSize: '1.3rem',
    display: 'flex',
    alignItems: 'center',
  },
  viewMore: {
    fontSize: '1.2rem',
    color: '#0056C8',
    fontWeight: 'bold',
    textAlign: 'right',
    textDecoration: 'underline',
    marginTop: 10,
    cursor: 'pointer',
  },
  powerBiWidget: {
    // border: '1px solid #D8D9DB',
    boxShadow: '0px 3px 6px #00000014',
    borderRadius: 2,
    '@media screen and (min-width:1800px)': {
      height: 275,
    },
  },
}));

export default DashboardStyles;
