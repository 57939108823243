/*
This component contains source code that defines an confirm box with message.
It takes boolean value as input and returns confirm box that can be displayed on the screen.
*/
import React, { useState, useEffect } from 'react';
import { Button, Box } from '@mui/material';
import Modal from '../atom/Modal';
import NotificationArchiveModalStyles from '../styles/NotificationArchiveModalStyles';
const NotificationArchiveModal = (props) => {
  const { open, handleClose, handleUnArchiveKpi } = props;
  const classes = NotificationArchiveModalStyles();

  const style = {
    pt: 2,
    px: 4,
    pb: 3,
  };

  return (
    <>
      <Modal hideBackdrop open={open} onClose={handleClose}>
        <Box className={classes.boxStyle} sx={{ ...style }}>
          <div>
            <p>You have archived this KPI, by selecting this option you will unarchive it.</p>
            <p>Are you sure you want to do this?</p>
          </div>
          <div className={classes.modalAction}>
            <Button onClick={handleUnArchiveKpi} variant='contained' className={classes.yesButton}>
              Yes
            </Button>
            <Button onClick={handleClose} variant='contained' className={classes.noButton}>
              No
            </Button>
          </div>
        </Box>
      </Modal>
    </>
  );
};

export default NotificationArchiveModal;
