/*
The following code contains source code that defines all the api functions for Resource library page.
getResourceLibrary function to get all the resource library data from DB.
*/
import request from '../helper/apiRequest';

// Function to get all list of resource library
export const getResourceLibrary = async () => {
  try {
    const { data } = await request.get(`/resourceLibrary/fetchResourceLibrary`);
    return data;
  } catch (e) {
    return { error: true, message: e.message };
  }
};
